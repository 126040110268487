export const NEW_TEST_WORDS: any = [
  { //
    "word": "gifts",
    "hint": "Luke 11",
    "verse": "Luke 11:13",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/11",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/11.13",
    "verseText": "If ye then, being evil, know how to give good GIFTS unto your children: how much more shall your heavenly Father give the Holy Spirit to them that ask him?",
    "submissionText": ""
  },
  { //
    "word": "begat",
    "hint": "Matthew 1",
    "verse": "Matthew 1:16",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/1.16",
    "verseText": "And Jacob BEGAT Joseph the husband of Mary, of whom was born Jesus, who is called Christ.",
    "submissionText": "Thanks to Kaleb Brinson for submitting this word!"
  },
  { //
    "word": "elias",
    "hint": "Luke 1",
    "verse": "Luke 1:17",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/1.17",
    "verseText": "And he shall go before him in the spirit and power of ELIAS, to turn the hearts of the fathers to the children, and the disobedient to the wisdom of the just; to make ready a people prepared for the Lord.",
    "submissionText": ""
  },
  { //
    "word": "dream",
    "hint": "Matthew 1",
    "verse": "Matthew 1:20",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/1.20",
    "verseText": "But while he thought on these things, behold, the angel of the Lord appeared unto him in a DREAM, saying, Joseph, thou son of David, fear not to take unto thee Mary thy wife: for that which is conceived in her is of the Holy Ghost.",
    "submissionText": ""
  },
  { //
    "word": "women",
    "hint": "Luke 1",
    "verse": "Luke 1:28",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/1.28",
    "verseText": "And the angel came in unto her, and said, Hail, thou that art highly favoured, the Lord is with thee: blessed art thou among WOMEN.",
    "submissionText": "Thanks to Jamie Cooper for submitting this word!"
  },
  { //
    "word": "sleep",
    "hint": "Matthew 1",
    "verse": "Matthew 1:24",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/1.24",
    "verseText": "Then Joseph being raised from SLEEP did as the angel of the Lord had bidden him, and took unto him his wife:",
    "submissionText": ""
  },
  { //
    "word": "faith",
    "hint": "this song",
    "verse": "He Sent His Son",
    "hintUrl": "https://www.churchofjesuschrist.org/study/manual/childrens-songbook/he-sent-his-son",
    "verseUrl": "https://www.churchofjesuschrist.org/study/manual/childrens-songbook/he-sent-his-son",
    "verseText": "Have FAITH, have hope, live like his Son, help others on their way.",
    "submissionText": ""
  },
  { //
    "word": "waxed",
    "hint": "Luke 1",
    "verse": "Luke 1:80",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/1.80",
    "verseText": "And the child grew, and WAXED strong in spirit, and was in the deserts till the day of his shewing unto Israel.",
    "submissionText": ""
  },
  { //
    "word": "flesh",
    "hint": "1 Nephi 11",
    "verse": "1 Nephi 11:18",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/11",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/11.18",
    "verseText": "And he said unto me: Behold, the virgin whom thou seest is the mother of the Son of God, after the manner of the FLESH.",
    "submissionText": ""
  },
  { //
    "word": "angel",
    "hint": "Luke 2",
    "verse": "Luke 2:10",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/2.10",
    "verseText": "And the ANGEL said unto them, Fear not: for, behold, I bring you good tidings of great joy, which shall be to all people.",
    "submissionText": "Thanks to Elin Brown for submitting this word!"
  },
  { //
    "word": "found",
    "hint": "Matthew 2",
    "verse": "Matthew 2:8",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/2.8",
    "verseText": "And he sent them to Bethlehem, and said, Go and search diligently for the young child; and when ye have FOUND him, bring me word again, that I may come and worship him also.",
    "submissionText": "Thanks to Josh Wilson for submitting this word!"
  },
  { //
    "word": "apart",
    "hint": "3 Nephi 1",
    "verse": "3 Nephi 1:9",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/1.9",
    "verseText": "Now it came to pass that there was a day set APART by the unbelievers, that all those who believed in those traditions should be put to death except the sign should come to pass, which had been given by Samuel the prophet.",
    "submissionText": ""
  },
  { //
    "word": "wroth",
    "hint": "Matthew 2",
    "verse": "Matthew 2:16",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/2.16",
    "verseText": "Then Herod, when he saw that he was mocked of the wise men, was exceeding WROTH, and sent forth, and slew all the children that were in Bethlehem, and in all the coasts thereof, from two years old and under, according to the time which he had diligently inquired of the wise men.",
    "submissionText": ""
  },
  { //
    "word": "named",
    "hint": "Luke 2",
    "verse": "Luke 2:21",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/2.21",
    "verseText": "And when eight days were accomplished for the circumcising of the child, his name was called JESUS, which was so NAMED of the angel before he was conceived in the womb.",
    "submissionText": ""
  },
  { //
    "word": "midst",
    "hint": "Luke 2",
    "verse": "Luke 2:46",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/2.46",
    "verseText": "And it came to pass, that after three days they found him in the temple, sitting in the MIDST of the doctors, both hearing them, and asking them questions.",
    "submissionText": "Thanks to Kendis for submitting this word!"
  },
  { //
    "word": "terms",
    "hint": "Bible Dictionary",
    "verse": "\"John, Gospel of\"",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bd/john-gospel-of",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bd/john-gospel-of",
    "verseText": "John teaches by contrast, using such TERMS as light and darkness, truth and error, good and evil, God and the devil.",
    "submissionText": ""
  },
  { //
    "word": "dwelt",
    "hint": "John 1",
    "verse": "John 1:14",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/1.14",
    "verseText": "And the Word was made flesh, and DWELT among us, (and we beheld his glory, the glory as of the only begotten of the Father,) full of grace and truth.",
    "submissionText": ""
  },
  { //
    "word": "rabbi",
    "hint": "John 1",
    "verse": "John 1:38",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/1.38",
    "verseText": "Then Jesus turned, and saw them following, and saith unto them, What seek ye? They said unto him, RABBI, (which is to say, being interpreted, Master,) where dwellest thou?",
    "submissionText": ""
  },
  { //
    "word": "guile",
    "hint": "John 1",
    "verse": "John 1:47",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/1.47",
    "verseText": "Jesus saw Nathanael coming to him, and saith of him, Behold an Israelite indeed, in whom is no GUILE!",
    "submissionText": "Thanks to Ryan Price for submitting this word!"
  },
  { //
    "word": "tarry",
    "hint": "Doctrine and Covenants 7",
    "verse": "Doctrine and Covenants 7:3",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/7",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/7.3",
    "verseText": "And the Lord said unto me: Verily, verily, I say unto thee, because thou desirest this thou shalt TARRY until I come in my glory, and shalt prophesy before nations, kindreds, tongues and people.",
    "submissionText": ""
  },
  { //
    "word": "under",
    "hint": "John 1",
    "verse": "John 1:50",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/1.50",
    "verseText": "Jesus answered and said unto him, Because I said unto thee, I saw thee UNDER the fig tree, believest thou? thou shalt see greater things than these.",
    "submissionText": "Thanks to Elin Brown for submitting this word!"
  },
  { //
    "word": "cried",
    "hint": "John 1",
    "verse": "John 1:15",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/1.15",
    "verseText": "John bare witness of him, and CRIED, saying, This was he of whom I spake, He that cometh after me is preferred before me: for he was before me.",
    "submissionText": ""
  },
  { //
    "word": "paths",
    "hint": "Mark 1",
    "verse": "Mark 1:3",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/1.3",
    "verseText": "The voice of one crying in the wilderness, Prepare ye the way of the Lord, make his PATHS straight.",
    "submissionText": ""
  },
  { //
    "word": "shoes",
    "hint": "Matthew 3",
    "verse": "Matthew 3:11",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/3.11",
    "verseText": "I indeed baptize you with water unto repentance: but he that cometh after me is mightier than I, whose SHOES I am not worthy to bear: he shall baptize you with the Holy Ghost, and with fire:",
    "submissionText": "Thanks to Bethany Platt for submitting this word!"
  },
  { //
    "word": "fruit",
    "hint": "Luke 3",
    "verse": "Luke 3:9",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/3.9",
    "verseText": "And now also the axe is laid unto the root of the trees: every tree therefore which bringeth not forth good FRUIT is hewn down, and cast into the fire.",
    "submissionText": ""
  },
  { //
    "word": "water",
    "hint": "Matthew 3",
    "verse": "Matthew 3:16",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/3.16",
    "verseText": "And Jesus, when he was baptized, went up straightway out of the WATER: and, lo, the heavens were opened unto him, and he saw the Spirit of God descending like a dove, and lighting upon him:",
    "submissionText": ""
  },
  { //
    "word": "fever",
    "hint": "Mark 1",
    "verse": "Mark 1:31",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/1.31",
    "verseText": "And he came and took her by the hand, and lifted her up; and immediately the FEVER left her, and she ministered unto them.",
    "submissionText": ""
  },
  { //
    "word": "coats",
    "hint": "Luke 3",
    "verse": "Luke 3:11",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/3.11",
    "verseText": "He answereth and saith unto them, He that hath two COATS, let him impart to him that hath none; and he that hath meat, let him do likewise.",
    "submissionText": ""
  },
  { //
    "word": "leper",
    "hint": "Mark 1",
    "verse": "Mark 1:40",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/1.40",
    "verseText": "And there came a LEPER to him, beseeching him, and kneeling down to him, and saying unto him, If thou wilt, thou canst make me clean.",
    "submissionText": "Thanks to Brielle Partridge and Kaleb Brinson for submitting this word!"
  },
  { //
    "word": "bread",
    "hint": "Luke 4",
    "verse": "Luke 4:4",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/4.4",
    "verseText": "And Jesus answered him, saying, It is written, That man shall not live by BREAD alone, but by every word of God.",
    "submissionText": "Thanks to Amy Murphy and Nola Simpson for submitting this word!"
  },
  { //
    "word": "hence",
    "hint": "Matthew 4",
    "verse": "Matthew 4:10",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/4.10",
    "verseText": "Then saith Jesus unto him, Get thee HENCE, Satan: for it is written, Thou shalt worship the Lord thy God, and him only shalt thou serve.",
    "submissionText": "Thanks to Charlotte Hunt for submitting this word!"
  },
  { //
    "word": "among",
    "hint": "Luke 4",
    "verse": "Luke 4:36",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/4.36",
    "verseText": "And they were all amazed, and spake AMONG themselves, saying, What a word is this! for with authority and power he commandeth the unclean spirits, and they come out.",
    "submissionText": "Thanks to Jack Wade for submitting this word!"
  },
  { //
    "word": "simon",
    "hint": "Matthew 4",
    "verse": "Matthew 4:18",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/4.18",
    "verseText": "And Jesus, walking by the sea of Galilee, saw two brethren, SIMON called Peter, and Andrew his brother, casting a net into the sea: for they were fishers.",
    "submissionText": ""
  },
  { //
    "word": "catch",
    "hint": "Luke 5",
    "verse": "Luke 5:10",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/5.10",
    "verseText": "And so was also James, and John, the sons of Zebedee, which were partners with Simon. And Jesus said unto Simon, Fear not; from henceforth thou shalt CATCH men.",
    "submissionText": "Thanks to Sabrina Cable for submitting this word!"
  },
  { //
    "word": "palsy",
    "hint": "Luke 5",
    "verse": "Luke 5:18",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/5.18",
    "verseText": "And, behold, men brought in a bed a man which was taken with a PALSY: and they sought means to bring him in, and to lay him before him.",
    "submissionText": "Thanks to Alex McKen for submitting this word!"
  },
  { //
    "word": "couch",
    "hint": "Luke 5",
    "verse": "Luke 5:24",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/5.24",
    "verseText": "But that ye may know that the Son of man hath power upon earth to forgive sins, (he said unto the sick of the palsy,) I say unto thee, Arise, and take up thy COUCH, and go into thine house.",
    "submissionText": ""
  },
  { //
    "word": "drunk",
    "hint": "John 2",
    "verse": "John 2:10",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/2.10",
    "verseText": "And saith unto him, Every man at the beginning doth set forth good wine; and when men have well DRUNK, then that which is worse: but thou hast kept the good wine until now.",
    "submissionText": ""
  },
  { //
    "word": "doves",
    "hint": "John 2",
    "verse": "John 2:16",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/2.16",
    "verseText": "And said unto them that sold DOVES, Take these things hence; make not my Father’s house an house of merchandise.",
    "submissionText": ""
  },
  { //
    "word": "again",
    "hint": "John 3",
    "verse": "John 3:3",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/3.3",
    "verseText": "Jesus answered and said unto him, Verily, verily, I say unto thee, Except a man be born AGAIN, he cannot see the kingdom of God.",
    "submissionText": "Thanks to Kelsie for submitting this word!"
  },
  { //
    "word": "moses",
    "hint": "John 3",
    "verse": "John 3:14",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/3.14",
    "verseText": "And as MOSES lifted up the serpent in the wilderness, even so must the Son of man be lifted up:",
    "submissionText": ""
  },
  { //
    "word": "above",
    "hint": "John 3",
    "verse": "John 3:31",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/3.31",
    "verseText": "He that cometh from ABOVE is above all: he that is of the earth is earthly, and speaketh of the earth: he that cometh from heaven is above all.",
    "submissionText": "Thanks to Adah Calloway for submitting this word!"
  },
  { //
    "word": "wages",
    "hint": "John 4",
    "verse": "John 4:36",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/4.36",
    "verseText": "And he that reapeth receiveth WAGES, and gathereth fruit unto life eternal: that both he that soweth and he that reapeth may rejoice together.",
    "submissionText": ""
  },
  { //
    "word": "never",
    "hint": "John 4",
    "verse": "John 4:14",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/4.14",
    "verseText": "But whosoever drinketh of the water that I shall give him shall NEVER thirst; but the water that I shall give him shall be in him a well of water springing up into everlasting life.",
    "submissionText": "Thanks to Ivy Kanahele for submitting this word!"
  },
  { //
    "word": "mourn",
    "hint": "Matthew 5",
    "verse": "Matthew 5:4",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/5.4",
    "verseText": "Blessed are they that MOURN: for they shall be comforted.",
    "submissionText": ""
  },
  { //
    "word": "shine",
    "hint": "Matthew 5",
    "verse": "Matthew 5:16",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/5.16",
    "verseText": "Let your light so SHINE before men, that they may see your good works, and glorify your Father which is in heaven.",
    "submissionText": "Thanks to Sydney Schultz for submitting this word!"
  },
  { //
    "word": "chose",
    "hint": "Luke 6",
    "verse": "Luke 6:13",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/6.13",
    "verseText": "And when it was day, he called unto him his disciples: and of them he CHOSE twelve, whom also he named apostles;",
    "submissionText": ""
  },
  { //
    "word": "vexed",
    "hint": "Luke 6",
    "verse": "Luke 6:18",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/6.18",
    "verseText": "And they that were VEXED with unclean spirits: and they were healed.",
    "submissionText": "Thanks to Lydia Asbridge for submitting this word!"
  },
  { //
    "word": "pluck",
    "hint": "Matthew 5",
    "verse": "Matthew 5:29",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/5.29",
    "verseText": "And if thy right eye offend thee, PLUCK it out, and cast it from thee: for it is profitable for thee that one of thy members should perish, and not that thy whole body should be cast into hell.",
    "submissionText": ""
  },
  { //
    "word": "blind",
    "hint": "Luke 6",
    "verse": "Luke 6:39",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/6.39",
    "verseText": "And he spake a parable unto them, Can the BLIND lead the BLIND? shall they not both fall into the ditch?",
    "submissionText": ""
  },
  { //
    "word": "cloak",
    "hint": "Matthew 5",
    "verse": "Matthew 5:40",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/5.40",
    "verseText": "And if any man will sue thee at the law, and take away thy coat, let him have thy CLOAK also.",
    "submissionText": "Thanks to Carter Lloyd for submitting this word!"
  },
  { //
    "word": "enter",
    "hint": "Matthew 6",
    "verse": "Matthew 6:6",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/6.6",
    "verseText": "But thou, when thou prayest, ENTER into thy closet, and when thou hast shut thy door, pray to thy Father which is in secret; and thy Father which seeth in secret shall reward thee openly.",
    "submissionText": "Thanks to Maurine Wheatley for submitting this word!"
  },
  { //
    "word": "serve",
    "hint": "Matthew 6",
    "verse": "Matthew 6:24",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/6.24",
    "verseText": "No man can SERVE two masters: for either he will hate the one, and love the other; or else he will hold to the one, and despise the other. Ye cannot SERVE God and mammon.",
    "submissionText": ""
  },
  { //
    "word": "field",
    "hint": "Matthew 6",
    "verse": "Matthew 6:28",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/6.28",
    "verseText": "And why take ye thought for raiment? Consider the lilies of the FIELD, how they grow; they toil not, neither do they spin:",
    "submissionText": ""
  },
  { //
    "word": "added",
    "hint": "Matthew 6",
    "verse": "Matthew 6:33",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/6.33",
    "verseText": "But seek ye first the kingdom of God, and his righteousness; and all these things shall be ADDED unto you.",
    "submissionText": "Thanks to Tephra Oman for submitting this word!"
  },
  { //
    "word": "swine",
    "hint": "Matthew 7",
    "verse": "Matthew 7:6",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/7",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/7.6",
    "verseText": "Give not that which is holy unto the dogs, neither cast ye your pearls before SWINE, lest they trample them under their feet, and turn again and rend you",
    "submissionText": ""
  },
  { //
    "word": "gifts",
    "hint": "Matthew 7",
    "verse": "Matthew 7:11",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/7",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/7.11",
    "verseText": "If ye then, being evil, know how to give good GIFTS unto your children, how much more shall your Father which is in heaven give good things to them that ask him?",
    "submissionText": ""
  },
  { //
    "word": "built",
    "hint": "Matthew 7",
    "verse": "Matthew 7:24",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/7",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/7.24",
    "verseText": "Therefore whosoever heareth these sayings of mine, and doeth them, I will liken him unto a wise man, which BUILT his house upon a rock:",
    "submissionText": "Thanks to Heather Coleman for submitting this word!"
  },
  { //
    "word": "foxes",
    "hint": "Matthew 8",
    "verse": "Matthew 8:20",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/8",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/8.20",
    "verseText": "And Jesus saith unto him, The FOXES have holes, and the birds of the air have nests; but the Son of man hath not where to lay his head.",
    "submissionText": "Thanks to Dan Wilcox for submitting this word!"
  },
  { //
    "word": "burst",
    "hint": "Mark 2",
    "verse": "Mark 2:22",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/2.22",
    "verseText": "And no man putteth new wine into old bottles: else the new wine doth BURST the bottles, and the wine is spilled, and the bottles will be marred: but new wine must be put into new bottles.",
    "submissionText": "Thanks to Benson Hepworth for submitting this word!"
  },
  { //
    "word": "might",
    "hint": "Mark 3",
    "verse": "Mark 3:14",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/3.14",
    "verseText": "And he ordained twelve, that they should be with him, and that he MIGHT send them forth to preach",
    "submissionText": ""
  },
  { //
    "word": "sight",
    "hint": "Luke 7",
    "verse": "Luke 7:21",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/7",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/7.21",
    "verseText": "And in that same hour he cured many of their infirmities and plagues, and of evil spirits; and unto many that were blind he gave SIGHT.",
    "submissionText": "Thanks to Krista Ashby for submitting this word!"
  },
  { //
    "word": "yield",
    "hint": "Mark 4",
    "verse": "Mark 4:8",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/4.8",
    "verseText": "And other fell on good ground, and did YIELD fruit that sprang up and increased; and brought forth, some thirty, and some sixty, and some an hundred.",
    "submissionText": "Thanks to Paige H for submitting this word!"
  },
  { //
    "word": "awake",
    "hint": "Mark 4",
    "verse": "Mark 4:38",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/4.38",
    "verseText": "And he was in the hinder part of the ship, asleep on a pillow: and they AWAKE him, and say unto him, Master, carest thou not that we perish?",
    "submissionText": ""
  },
  { //
    "word": "winds",
    "hint": "Matthew 8",
    "verse": "Matthew 8:27",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/8",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/8.27",
    "verseText": "But the men marvelled, saying, What manner of man is this, that even the WINDS and the sea obey him!",
    "submissionText": "Thanks to Darci Johnson for submitting this word!"
  },
  { //
    "word": "whole",
    "hint": "Matthew 9",
    "verse": "Matthew 9:12",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/9",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/9.12",
    "verseText": "But when Jesus heard that, he said unto them, They that be WHOLE need not a physician, but they that are sick.",
    "submissionText": ""
  },
  { //
    "word": "moved",
    "hint": "Matthew 9",
    "verse": "Matthew 9:36",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/9",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/9.36",
    "verseText": "But when he saw the multitudes, he was MOVED with compassion on them, because they fainted, and were scattered abroad, as sheep having no shepherd.",
    "submissionText": "Thanks to Kelsey Hendricks for submitting this word!"
  },
  { //
    "word": "hairs",
    "hint": "Matthew 10",
    "verse": "Matthew 10:30",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/10",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/10.30",
    "verseText": "But the very HAIRS of your head are all numbered.",
    "submissionText": ""
  },
  { //
    "word": "scrip",
    "hint": "Luke 9",
    "verse": "Luke 9:3",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/9",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/9.3",
    "verseText": "And he said unto them, Take nothing for your journey, neither staves, nor SCRIP, neither bread, neither money; neither have two coats apiece.",
    "submissionText": "Thanks to Deb Grant for submitting this word!"
  },
  { //
    "word": "shall",
    "hint": "Matthew 10",
    "verse": "Matthew 10:39",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/10",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/10.39",
    "verseText": "He that findeth his life SHALL lose it: and he that loseth his life for my sake SHALL find it.",
    "submissionText": ""
  },
  { //
    "word": "issue",
    "hint": "Matthew 9",
    "verse": "Matthew 9:20",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/9",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/9.20",
    "verseText": "And, behold, a woman, which was diseased with an ISSUE of blood twelve years, came behind him, and touched the hem of his garment:",
    "submissionText": ""
  },
  { //
    "word": "peace",
    "hint": "Mark 5",
    "verse": "Mark 5:34",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/5.34",
    "verseText": "And he said unto her, Daughter, thy faith hath made thee whole; go in PEACE, and be whole of thy plague.",
    "submissionText": ""
  },
  { //
    "word": "piped",
    "hint": "Matthew 11",
    "verse": "Matthew 11:17",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/11",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/11.17",
    "verseText": "And saying, We have PIPED unto you, and ye have not danced; we have mourned unto you, and ye have not lamented.",
    "submissionText": "Thanks to Dan Stout for submitting this word!"
  },
  { //
    "word": "laden",
    "hint": "Matthew 11",
    "verse": "Matthew 11:28",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/11",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/11.28",
    "verseText": "Come unto me, all ye that labour and are heavy LADEN, and I will give you rest.",
    "submissionText": "Thanks to Sheena Cram for submitting this word!"
  },
  { //
    "word": "spoil",
    "hint": "Matthew 12",
    "verse": "Matthew 12:29",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/12",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/12.29",
    "verseText": "Or else how can one enter into a strong man’s house, and SPOIL his goods, except he first bind the strong man? and then he will SPOIL his house.",
    "submissionText": ""
  },
  { //
    "word": "belly",
    "hint": "Matthew 12",
    "verse": "Matthew 12:40",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/12",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/12.40",
    "verseText": "For as Jonas was three days and three nights in the whale’s BELLY; so shall the Son of man be three days and three nights in the heart of the earth.",
    "submissionText": ""
  },
  { //
    "word": "offer",
    "hint": "Luke 11",
    "verse": "Luke 11:12",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/11",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/11.12",
    "verseText": "Or if he shall ask an egg, will he OFFER him a scorpion?",
    "submissionText": ""
  },
  { //
    "word": "catch",
    "hint": "Luke 11",
    "verse": "Luke 11:54",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/11",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/11.54",
    "verseText": "Laying wait for him, and seeking to CATCH something out of his mouth, that they might accuse him.",
    "submissionText": ""
  },
  { //
    "word": "lowly",
    "hint": "Matthew 11",
    "verse": "Matthew 11:29",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/11",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/11.29",
    "verseText": "Take my yoke upon you, and learn of me; for I am meek and LOWLY in heart: and ye shall find rest unto your souls.",
    "submissionText": "Thanks to Christy Burton for submitting this word!"
  },
  { //
    "word": "sower",
    "hint": "Matthew 13",
    "verse": "Matthew 13:3",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/13",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/13.3",
    "verseText": "And he spake many things unto them in parables, saying, Behold, a SOWER went forth to sow;",
    "submissionText": "Thanks to Grant Rodriguez for submitting this word!"
  },
  { //
    "word": "tares",
    "hint": "Matthew 13",
    "verse": "Matthew 13:30",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/13",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/13.30",
    "verseText": "Let both grow together until the harvest: and in the time of harvest I will say to the reapers, Gather ye together first the TARES, and bind them in bundles to burn them: but gather the wheat into my barn.",
    "submissionText": "Thanks to Lawson Gasaway for submitting this word!"
  },
  { //
    "word": "cares",
    "hint": "Luke 8",
    "verse": "Luke 8:14",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/8",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/8.14",
    "verseText": "And that which fell among thorns are they, which, when they have heard, go forth, and are choked with CARES and riches and pleasures of this life, and bring no fruit to perfection.",
    "submissionText": "Thanks to Adam Huskinson  for submitting this word!"
  },
  { //
    "word": "years",
    "hint": "Luke 8",
    "verse": "Luke 8:42",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/8",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/8.42",
    "verseText": "For he had one only daughter, about twelve YEARS of age, and she lay a dying. But as he went the people thronged him.",
    "submissionText": ""
  },
  { //
    "word": "grain",
    "hint": "Luke 13",
    "verse": "Luke 13:19",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/13",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/13.19",
    "verseText": "It is like a GRAIN of mustard seed, which a man took, and cast into his garden; and it grew, and waxed a great tree; and the fowls of the air lodged in the branches of it.",
    "submissionText": ""
  },
  { //
    "word": "enter",
    "hint": "Luke 13",
    "verse": "Luke 13:24",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/13",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/13.24",
    "verseText": "Strive to ENTER in at the strait gate: for many, I say unto you, will seek to ENTER in, and shall not be able.",
    "submissionText": ""
  },
  { //
    "word": "cures",
    "hint": "Luke 13",
    "verse": "Luke 13:32",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/13",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/13:32",
    "verseText": "And he said unto them, Go ye, and tell that fox, Behold, I cast out devils, and I do CURES to day and to morrow, and the third day I shall be perfected.",
    "submissionText": ""
  },
  { //
    "word": "brake",
    "hint": "Matthew 14",
    "verse": "Matthew 14:19",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/14",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/14.19",
    "verseText": "And he commanded the multitude to sit down on the grass, and took the five loaves, and the two fishes, and looking up to heaven, he blessed, and BRAKE, and gave the loaves to his disciples, and the disciples to the multitude.",
    "submissionText": ""
  },
  { //
    "word": "cheer",
    "hint": "Matthew 14",
    "verse": "Matthew 14:27",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/14",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/14.27",
    "verseText": "But straightway Jesus spake unto them, saying, Be of good CHEER; it is I; be not afraid.",
    "submissionText": "Thanks to J'Ana Smith for submitting this word!"
  },
  { //
    "word": "power",
    "hint": "Mark 6",
    "verse": "Mark 6:7",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/6.7",
    "verseText": "And he called unto him the twelve, and began to send them forth by two and two; and gave them POWER over unclean spirits;",
    "submissionText": "Thanks to Robin Fenn for submitting this word!"
  },
  { //
    "word": "haste",
    "hint": "Mark 6",
    "verse": "Mark 6:25",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/6.25",
    "verseText": "And she came in straightway with HASTE unto the king, and asked, saying, I will that thou give me by and by in a charger the head of John the Baptist.",
    "submissionText": ""
  },
  { //
    "word": "equal",
    "hint": "John 5",
    "verse": "John 5:18",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/5.18",
    "verseText": "Therefore the Jews sought the more to kill him, because he not only had broken the sabbath, but said also that God was his Father, making himself EQUAL with God.",
    "submissionText": "Thanks to Hannah R for submitting this word!"
  },
  { //
    "word": "manna",
    "hint": "John 6",
    "verse": "John 6:58",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/6.58",
    "verseText": "This is that bread which came down from heaven: not as your fathers did eat MANNA, and are dead: he that eateth of this bread shall live for ever.",
    "submissionText": ""
  },
  { //
    "word": "doubt",
    "hint": "Matthew 14",
    "verse": "Matthew 14:31",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/14",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/14.31",
    "verseText": "And immediately Jesus stretched forth his hand, and caught him, and said unto him, O thou of little faith, wherefore didst thou DOUBT?",
    "submissionText": "Thanks to Aria Becker for submitting this word!"
  },
  { //
    "word": "doves",
    "hint": "Matthew 21",
    "verse": "Matthew 21:12",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/21",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/21.12",
    "verseText": "And Jesus went into the temple of God, and cast out all them that sold and bought in the temple, and overthrew the tables of the moneychangers, and the seats of them that sold DOVES",
    "submissionText": ""
  },
  { //
    "word": "least",
    "hint": "Matthew 25",
    "verse": "Matthew 25:40",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/25",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/25.40",
    "verseText": "And the King shall answer and say unto them, Verily I say unto you, Inasmuch as ye have done it unto one of the LEAST of these my brethren, ye have done it unto me.",
    "submissionText": ""
  },
  { //
    "word": "bound",
    "hint": "John 11",
    "verse": "John 11:44",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/11",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/11.44",
    "verseText": "And he that was dead came forth, BOUND hand and foot with graveclothes: and his face was bound about with a napkin. Jesus saith unto them, Loose him, and let him go.",
    "submissionText": ""
  },
  { //
    "word": "agony",
    "hint": "Luke 22",
    "verse": "Luke 22:44",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/22",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/22.44",
    "verseText": "And being in an AGONY he prayed more earnestly: and his sweat was as it were great drops of blood falling down to the ground.",
    "submissionText": ""
  },
  { //
    "word": "crown",
    "hint": "Matthew 27",
    "verse": "Matthew 27:29",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/27",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/27.29",
    "verseText": "And when they had plaited a CROWN of thorns, they put it upon his head, and a reed in his right hand: and they bowed the knee before him, and mocked him, saying, Hail, King of the Jews!",
    "submissionText": ""
  },
  { //
    "word": "stone",
    "hint": "Matthew 27",
    "verse": "Matthew 27:60",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/27",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/27.60",
    "verseText": "And laid it in his own new tomb, which he had hewn out in the rock: and he rolled a great STONE to the door of the sepulchre, and departed.",
    "submissionText": ""
  },
  { //
    "word": "risen",
    "hint": "Luke 24",
    "verse": "Luke 24:6",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/24",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/24.6",
    "verseText": "He is not here, but is RISEN: remember how he spake unto you when he was yet in Galilee",
    "submissionText": ""
  },
  { //
    "word": "table",
    "hint": "Matthew 15",
    "verse": "Matthew 15:27",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/15",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/15.27",
    "verseText": "And she said, Truth, Lord: yet the dogs eat of the crumbs which fall from their masters’ TABLE.",
    "submissionText": ""
  },
  { //
    "word": "loose",
    "hint": "Matthew 16",
    "verse": "Matthew 16:19",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/16",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/16.19",
    "verseText": "And I will give unto thee the keys of the kingdom of heaven: and whatsoever thou shalt bind on earth shall be bound in heaven: and whatsoever thou shalt LOOSE on earth shall be loosed in heaven.",
    "submissionText": ""
  },
  { //
    "word": "money",
    "hint": "Matthew 17",
    "verse": "Matthew 17:27",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/17",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/17.27",
    "verseText": "Notwithstanding, lest we should offend them, go thou to the sea, and cast an hook, and take up the fish that first cometh up; and when thou hast opened his mouth, thou shalt find a piece of MONEY: that take, and give unto them for me and thee.",
    "submissionText": ""
  },
  { //
    "word": "fault",
    "hint": "Mark 7",
    "verse": "Mark 7:2",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/7",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/7.2",
    "verseText": "And when they saw some of his disciples eat bread with defiled, that is to say, with unwashen, hands, they found FAULT.",
    "submissionText": ""
  },
  { //
    "word": "ought",
    "hint": "Mark 8",
    "verse": "Mark 8:23",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/8",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/8.23",
    "verseText": "And he took the blind man by the hand, and led him out of the town; and when he had spit on his eyes, and put his hands upon him, he asked him if he saw OUGHT.",
    "submissionText": "Thanks to Kendis Brown for submitting this word!"
  },
  { //
    "word": "taste",
    "hint": "Matthew 16",
    "verse": "Matthew 16:28",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/16",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/16.28",
    "verseText": "Verily I say unto you, There be some standing here, which shall not TASTE of death, till they see the Son of man coming in his kingdom.",
    "submissionText": ""
  },
  { //
    "word": "tears",
    "hint": "Mark 9",
    "verse": "Mark 9:24",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/9",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/9.24",
    "verseText": "And straightway the father of the child cried out, and said with TEARS, Lord, I believe; help thou mine unbelief.",
    "submissionText": "Thanks to Jeff Case for submitting this word!"
  },
  {
    "word": "midst",
    "hint": "Matthew 18",
    "verse": "Matthew 18:20",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/18",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/18.20",
    "verseText": "For where two or three are gathered together in my name, there am I in the MIDST of them.",
    "submissionText": "Thanks to Suzette Stokes for submitting this word!"
  },
  {
    "word": "wroth",
    "hint": "Matthew 18",
    "verse": "Matthew 18:34",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/18",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/18.34",
    "verseText": "And his lord was WROTH, and delivered him to the tormentors, till he should pay all that was due unto him.",
    "submissionText": "Thanks to the Lundell family  for submitting this word!"
  },
  {
    "word": "treat",
    "hint": "this song",
    "verse": "Jesus Said Love Everyone",
    "hintUrl": "https://www.churchofjesuschrist.org/study/manual/childrens-songbook/jesus-said-love-everyone",
    "verseUrl": "https://www.churchofjesuschrist.org/study/manual/childrens-songbook/jesus-said-love-everyone",
    "verseText": "Jesus said love ev’ryone; TREAT them kindly too. When your heart is filled with love, Others will love you."
  },
  {
    "word": "purse",
    "hint": "Luke 10",
    "verse": "Luke 10:4",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/10",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/10.4",
    "verseText": "Carry neither PURSE, nor scrip, nor shoes: and salute no man by the way.",
    "submissionText": "Thanks to Amanda Davis for submitting this word!"
  },
  {
    "word": "until",
    "hint": "Matthew 18",
    "verse": "Matthew 18:22",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/18",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/18.22",
    "verseText": "Jesus saith unto him, I say not unto thee, UNTIL seven times: but, UNTIL seventy times seven."
  },
  {
    "word": "tread",
    "hint": "Luke 10",
    "verse": "Luke 10:19",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/10",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/10.19",
    "verseText": "Behold, I give unto you power to TREAD on serpents and scorpions, and over all the power of the enemy: and nothing shall by any means hurt you."
  },
  {
    "word": "taken",
    "hint": "Luke 10",
    "verse": "Luke 10:42",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/10",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/10.42",
    "verseText": "But one thing is needful: and Mary hath chosen that good part, which shall not be TAKEN away from her."
  },
  {
    "word": "ready",
    "hint": "John 7",
    "verse": "John 7:6",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/7",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/7.6",
    "verseText": "Then Jesus said unto them, My time is not yet come: but your time is alway READY."
  },
  {
    "word": "spake",
    "hint": "John 7",
    "verse": "John 7:46",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/7",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/7.46",
    "verseText": "The officers answered, Never man SPAKE like this man."
  },
  {
    "word": "wrote",
    "hint": "John 8",
    "verse": "John 8:6",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/8",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/8.6",
    "verseText": "This they said, tempting him, that they might have to accuse him. But Jesus stooped down, and with his finger WROTE on the ground, as though he heard them not."
  },
  {
    "word": "judge",
    "hint": "John 8",
    "verse": "John 8:15",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/8",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/8.15",
    "verseText": "And yet if I JUDGE, my judgment is true: for I am not alone, but I and the Father that sent me.",
    "submissionText": "Thanks to Manhattan MT seminary for submitting this word!"
  },
  {
    "word": "works",
    "hint": "John 9",
    "verse": "John 9:4",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/9",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/9.4",
    "verseText": "I must work the WORKS of him that sent me, while it is day: the night cometh, when no man can work."
  },
  {
    "word": "sheep",
    "hint": "John 10",
    "verse": "John 10:16",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/10",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/10.16",
    "verseText": "And other SHEEP I have, which are not of this fold: them also I must bring, and they shall hear my voice; and there shall be one fold, and one shepherd."
  },
  {
    "word": "again",
    "hint": "John 10",
    "verse": "John 10:18",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/10",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/10.18",
    "verseText": "No man taketh it from me, but I lay it down of myself. I have power to lay it down, and I have power to take it AGAIN. This commandment have I received of my Father."
  },
  {
    "word": "value",
    "hint": "Luke 12",
    "verse": "Luke 12:7",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/12",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/12.7",
    "verseText": "But even the very hairs of your head are all numbered. Fear not therefore: ye are of more VALUE than many sparrows."
  },
  {
    "word": "teeth",
    "hint": "Luke 13",
    "verse": "Luke 13:28",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/13",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/13.28",
    "verseText": "There shall be weeping and gnashing of TEETH, when ye shall see Abraham, and Isaac, and Jacob, and all the prophets, in the kingdom of God, and you yourselves thrust out."
  },
  {
    "word": "tower",
    "hint": "Luke 14",
    "verse": "Luke 14:28",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/14",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/14.28",
    "verseText": "For which of you, intending to build a TOWER, sitteth not down first, and counteth the cost, whether he have sufficient to finish it?"
  },
  {
    "word": "sweep",
    "hint": "Luke 15",
    "verse": "Luke 15:8",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/15",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/15.8",
    "verseText": "Either what woman having ten pieces of silver, if she lose one piece, doth not light a candle, and SWEEP the house, and seek diligently till she find it?"
  },
  {
    "word": "merry",
    "hint": "Luke 15",
    "verse": "Luke 15:32",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/15",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/15.32",
    "verseText": "It was meet that we should make MERRY, and be glad: for this thy brother was dead, and is alive again; and was lost, and is found."
  },
  {
    "word": "voice",
    "hint": "Luke 17",
    "verse": "Luke 17:15",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/17",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/17.15",
    "verseText": "And one of them, when he saw that he was healed, turned back, and with a loud VOICE glorified God,"
  },
  {
    "word": "place",
    "hint": "John 11",
    "verse": "John 11:41",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/11",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/11.41",
    "verseText": "Then they took away the stone from the PLACE where the dead was laid. And Jesus lifted up his eyes, and said, Father, I thank thee that thou hast heard me."
  },
  {
    "word": "twain",
    "hint": "Matthew 19",
    "verse": "Matthew 19:6",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/19",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/19.6",
    "verseText": "Wherefore they are no more TWAIN, but one flesh. What therefore God hath joined together, let not man put asunder."
  },
  {
    "word": "youth",
    "hint": "Matthew 19",
    "verse": "Matthew 19:20",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/19",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/19.20",
    "verseText": "The young man saith unto him, All these things have I kept from my YOUTH up: what lack I yet?"
  },
  {
    "word": "penny",
    "hint": "Matthew 20",
    "verse": "Matthew 20:13",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/20",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/20.13",
    "verseText": "But he answered one of them, and said, Friend, I do thee no wrong: didst not thou agree with me for a PENNY?"
  },
  {
    "word": "chief",
    "hint": "Matthew 20",
    "verse": "Matthew 20:27",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/20",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/20.27",
    "verseText": "And whosoever will be chief among you, let him be your servant:"
  },
  {
    "word": "great",
    "hint": "Mark 10",
    "verse": "Mark 10:22",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/10",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/10.22",
    "verseText": "And he was sad at that saying, and went away grieved: for he had GREAT possessions."
  },
  {
    "word": "elect",
    "hint": "Luke 18",
    "verse": "Luke 18:7",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/18",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/18.7",
    "verseText": "And shall not God avenge his own ELECT, which cry day and night unto him, though he bear long with them?"
  },
  {
    "word": "camel",
    "hint": "Luke 18",
    "verse": "Luke 18:25",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/18",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/18.25",
    "verseText": "For it is easier for a CAMEL to go through a needle’s eye, than for a rich man to enter into the kingdom of God."
  },
  {
    "word": "seats",
    "hint": "Matthew 21",
    "verse": "Matthew 21:12",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/21",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/21.12",
    "verseText": "And Jesus went into the temple of God, and cast out all them that sold and bought in the temple, and overthrew the tables of the moneychangers, and the SEATS of them that sold doves,"
  },
  {
    "word": "durst",
    "hint": "Matthew 22",
    "verse": "Matthew 22:46",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/22",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/22.46",
    "verseText": "And no man was able to answer him a word, neither DURST any man from that day forth ask him any more questions."
  },
  {
    "word": "trees",
    "hint": "Mark 11",
    "verse": "Mark 11:8",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/11",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/11.8",
    "verseText": "And many spread their garments in the way: and others cut down branches off the TREES, and strawed them in the way."
  },
  {
    "word": "broad",
    "hint": "Matthew 23",
    "verse": "Matthew 23:5",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/23",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/23.5",
    "verseText": "But all their works they do for to be seen of men: they make BROAD their phylacteries, and enlarge the borders of their garments,"
  },
  {
    "word": "stood",
    "hint": "Luke 19",
    "verse": "Luke 19:8",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/19",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/19.8",
    "verseText": "And Zacchæus STOOD, and said unto the Lord; Behold, Lord, the half of my goods I give to the poor; and if I have taken any thing from any man by false accusation, I restore him fourfold."
  },
  {
    "word": "robes",
    "hint": "Luke 20",
    "verse": "Luke 20:46",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/20",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/20.46",
    "verseText": "Beware of the scribes, which desire to walk in long ROBES, and love greetings in the markets, and the highest seats in the synagogues, and the chief rooms at feasts;"
  },
  {
    "word": "goeth",
    "hint": "John 12",
    "verse": "John 12:35",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/12",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/12.35",
    "verseText": "Then Jesus said unto them, Yet a little while is the light with you. Walk while ye have the light, lest darkness come upon you: for he that walketh in darkness knoweth not whither he GOETH."
  },
  {
    "word": "false",
    "hint": "Matthew 24",
    "verse": "Matthew 24:24",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/24",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/24.24",
    "verseText": "For there shall arise FALSE Christs, and false prophets, and shall shew great signs and wonders; insomuch that, if it were possible, they shall deceive the very elect."
  },
  {
    "word": "watch",
    "hint": "Matthew 24",
    "verse": "Matthew 24:42",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/24",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/24.42",
    "verseText": "WATCH therefore: for ye know not what hour your Lord doth come.",
    "submissionText": "Thanks to Heather Dickson for submitting this word!"
  },
  {
    "word": "widow",
    "hint": "Luke 21",
    "verse": "Luke 21:3",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/21",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/21.3",
    "verseText": "And he said, Of a truth I say unto you, that this poor WIDOW hath cast in more than they all:"
  },
  {
    "word": "image",
    "hint": "Mark 12",
    "verse": "Mark 12:16",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/12",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/12.16",
    "verseText": "And they brought it. And he saith unto them, Whose is this IMAGE and superscription? And they said unto him, Cæsar’s."
  },
  {
    "word": "hated",
    "hint": "Mark 13",
    "verse": "Mark 13:13",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/13",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/13.13",
    "verseText": "And ye shall be HATED of all men for my name’s sake: but he that shall endure unto the end, the same shall be saved."
  },
  {
    "word": "snare",
    "hint": "Luke 21",
    "verse": "Luke 21:35",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/21",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/21.35",
    "verseText": "For as a SNARE shall it come on all them that dwell on the face of the whole earth."
  },
  {
    "word": "lamps",
    "hint": "Matthew 25",
    "verse": "Matthew 25:4",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/25",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/25.4",
    "verseText": "But the wise took oil in their vessels with their LAMPS."
  },
  {
    "word": "towel",
    "hint": "John 13",
    "verse": "John 13:5",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/13",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/13.5",
    "verseText": "After that he poureth water into a basin, and began to wash the disciples’ feet, and to wipe them with the TOWEL wherewith he was girded."
  },
  {
    "word": "drink",
    "hint": "Matthew 26",
    "verse": "Matthew 26:27",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/26",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/26.27",
    "verseText": "And he took the cup, and gave thanks, and gave it to them, saying, DRINK ye all of it;"
  },
  {
    "word": "heavy",
    "hint": "Mark 14",
    "verse": "Mark 14:33",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/14",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/14.33",
    "verseText": "And he taketh with him Peter and James and John, and began to be sore amazed, and to be very HEAVY;"
  },
  {
    "word": "tarry",
    "hint": "Matthew 26",
    "verse": "Matthew 26:38",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/26",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/26.38",
    "verseText": "Then saith he unto them, My soul is exceeding sorrowful, even unto death: TARRY ye here, and watch with me."
  },
  {
    "word": "token",
    "hint": "Mark 14",
    "verse": "Mark 14:44",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/14",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/14.44",
    "verseText": "And he that betrayed him had given them a TOKEN, saying, Whomsoever I shall kiss, that same is he; take him, and lead him away safely."
  },
  {
    "word": "happy",
    "hint": "John 13",
    "verse": "John 13:17",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/13",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/13.17",
    "verseText": "If ye know these things, HAPPY are ye if ye do them.",
    "submissionText": "Thanks to Patrice Thompson for submitting this word!"
  },
  {
    "word": "curse",
    "hint": "Matthew 26",
    "verse": "Matthew 26:74",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/26",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/26.74",
    "verseText": "Then began he to CURSE and to swear, saying, I know not the man. And immediately the cock crew."
  },
  {
    "word": "truth",
    "hint": "John 14",
    "verse": "John 14:6",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/14",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/14.6",
    "verseText": "Jesus saith unto him, I am the way, the TRUTH, and the life: no man cometh unto the Father, but by me.",
    "submissionText": "Thanks to the Evans Family for submitting this word!"
  },
  {
    "word": "abide",
    "hint": "John 14",
    "verse": "John 14:16",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/14",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/14.16",
    "verseText": "And I will pray the Father, and he shall give you another Comforter, that he may ABIDE with you for ever;",
    "submissionText": "Thanks to Adam West for submitting this word!"
  },
  {
    "word": "loved",
    "hint": "John 15",
    "verse": "John 15:12",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/15",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/15.12",
    "verseText": "This is my commandment, That ye love one another, as I have LOVED you."
  },
  {
    "word": "cheer",
    "hint": "John 16",
    "verse": "John 16:33",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/16",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/16.33",
    "verseText": "These things I have spoken unto you, that in me ye might have peace. In the world ye shall have tribulation: but be of good CHEER; I have overcome the world.",
    "submissionText": "Thanks to Amanda Davis for submitting this word!"
  },
  {
    "word": "might",
    "hint": "John 17",
    "verse": "John 17:3",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/17",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/17.3",
    "verseText": "And this is life eternal, that they MIGHT know thee the only true God, and Jesus Christ, whom thou hast sent.",
    "submissionText": "Thanks to the Brooke Family for submitting this word!"
  },
  {
    "word": "sakes",
    "hint": "John 17",
    "verse": "John 17:19",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/17",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/17.19",
    "verseText": "And for their SAKES I sanctify myself, that they also might be sanctified through the truth."
  },
  {
    "word": "leave",
    "hint": "John 14",
    "verse": "John 14:27",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/14",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/14.27",
    "verseText": "Peace I LEAVE with you, my peace I give unto you: not as the world giveth, give I unto you. Let not your heart be troubled, neither let it be afraid.",
    "submissionText": "Thanks to Stacey Hall for submitting this word!"
  },
  {
    "word": "sweat",
    "hint": "Luke 22",
    "verse": "Luke 22:44",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/22",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/22.44",
    "verseText": "And being in an agony he prayed more earnestly: and his SWEAT was as it were great drops of blood falling down to the ground."
  },
  {
    "word": "sleep",
    "hint": "Luke 22",
    "verse": "Luke 22:46",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/22",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/22.46",
    "verseText": "And said unto them, Why SLEEP ye? rise and pray, lest ye enter into temptation."
  },
  {
    "word": "sword",
    "hint": "John 18",
    "verse": "John 18:11",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/18",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/18.11",
    "verseText": "Then said Jesus unto Peter, Put up thy SWORD into the sheath: the cup which my Father hath given me, shall I not drink it?"
  },
  {
    "word": "pains",
    "hint": "Alma 7",
    "verse": "Alma 7:11",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/7",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/7.11",
    "verseText": "And he shall go forth, suffering pains and afflictions and temptations of every kind; and this that the word might be fulfilled which saith he will take upon him the PAINS and the sicknesses of his people."
  },
  {
    "word": "beast",
    "hint": "Alma 34",
    "verse": "Alma 34:10",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/34",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/34.10",
    "verseText": "For it is expedient that there should be a great and last sacrifice; yea, not a sacrifice of man, neither of BEAST, neither of any manner of fowl; for it shall not be a human sacrifice; but it must be an infinite and eternal sacrifice."
  },
  {
    "word": "world",
    "hint": "John 18",
    "verse": "John 18:36",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/18",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/18.36",
    "verseText": "Jesus answered, My kingdom is not of this WORLD: if my kingdom were of this WORLD, then would my servants fight, that I should not be delivered to the Jews: but now is my kingdom not from hence."
  },
  {
    "word": "thine",
    "hint": "Luke 22",
    "verse": "Luke 22:42",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/22",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/22.42",
    "verseText": "Saying, Father, if thou be willing, remove this cup from me: nevertheless not my will, but THINE, be done."
  },
  {
    "word": "hands",
    "hint": "Matthew 27",
    "verse": "Matthew 27:24",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/27",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/27.24",
    "verseText": "When Pilate saw that he could prevail nothing, but that rather a tumult was made, he took water, and washed his HANDS before the multitude, saying, I am innocent of the blood of this just person: see ye to it."
  },
  {
    "word": "green",
    "hint": "Luke 23",
    "verse": "Luke 23:31",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/23",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/23.31",
    "verseText": "For if they do these things in a GREEN tree, what shall be done in the dry?"
  },
  {
    "word": "skull",
    "hint": "Matthew 27",
    "verse": "Matthew 27:33",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/27",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/27.33",
    "verseText": "And when they were come unto a place called Golgotha, that is to say, a place of a SKULL,",
    "submissionText": "Thanks to Amanda Davis for submitting this word!"
  },
  {
    "word": "title",
    "hint": "John 19",
    "verse": "John 19:19",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/19",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/19.19",
    "verseText": "And Pilate wrote a TITLE, and put it on the cross. And the writing was, JESUS OF NAZARETH THE KING OF THE JEWS."
  },
  {
    "word": "ghost",
    "hint": "Luke 23",
    "verse": "Luke 23:46",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/23",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/23.46",
    "verseText": "And when Jesus had cried with a loud voice, he said, Father, into thy hands I commend my spirit: and having said thus, he gave up the GHOST."
  },
  {
    "word": "spear",
    "hint": "John 19",
    "verse": "John 19:34",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/19",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/19.34",
    "verseText": "But one of the soldiers with a SPEAR pierced his side, and forthwith came there out blood and water."
  },
  {
    "word": "linen",
    "hint": "Mark 15",
    "verse": "Mark 15:46",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/15",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/15.46",
    "verseText": "And he bought fine LINEN, and took him down, and wrapped him in the LINEN, and laid him in a sepulchre which was hewn out of a rock, and rolled a stone unto the door of the sepulchre.",
    "submissionText": "Thanks to Kelv Cutler for submitting this word!"
  },
  {
    "word": "sweet",
    "hint": "Mark 16",
    "verse": "Mark 16:1",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/16",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/mark/16.1",
    "verseText": "And when the sabbath was past, Mary Magdalene, and Mary the mother of James, and Salome, had bought SWEET spices, that they might come and anoint him."
  },
  {
    "word": "found",
    "hint": "Luke 24",
    "verse": "Luke 24:3",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/24",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/24.3",
    "verseText": "And they entered in, and FOUND not the body of the Lord Jesus."
  },
  {
    "word": "touch",
    "hint": "John 20",
    "verse": "John 20:17",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/20",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/20.17",
    "verseText": "Jesus saith unto her, TOUCH me not; for I am not yet ascended to my Father: but go to my brethren, and say unto them, I ascend unto my Father, and your Father; and to my God, and your God."
  },
  {
    "word": "third",
    "hint": "Luke 24",
    "verse": "Luke 24:7",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/24",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/24.7",
    "verseText": "Saying, The Son of man must be delivered into the hands of sinful men, and be crucified, and the THIRD day rise again."
  },
  {
    "word": "dined",
    "hint": "John 21",
    "verse": "John 21:15",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/21",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/21.15",
    "verseText": "So when they had DINED, Jesus saith to Simon Peter, Simon, son of Jonas, lovest thou me more than these? He saith unto him, Yea, Lord; thou knowest that I love thee. He saith unto him, Feed my lambs."
  },
  {
    "word": "alway",
    "hint": "Matthew 28",
    "verse": "Matthew 28:20",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/28",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/28.20",
    "verseText": "Teaching them to observe all things whatsoever I have commanded you: and, lo, I am with you ALWAY, even unto the end of the world. Amen."
  },
  {
    "word": "books",
    "hint": "John 21",
    "verse": "John 21:25",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/21",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/john/21.25",
    "verseText": "And there are also many other things which Jesus did, the which, if they should be written every one, I suppose that even the world itself could not contain the BOOKS that should be written. Amen."
  },
  {
    "word": "cloud",
    "hint": "Acts 1",
    "verse": "Acts 1:9",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/1.9",
    "verseText": "And when he had spoken these things, while they beheld, he was taken up; and a CLOUD received him out of their sight."
  },
  {
    "word": "dream",
    "hint": "Acts 2",
    "verse": "Acts 2:17",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/2.17",
    "verseText": "And it shall come to pass in the last days, saith God, I will pour out of my Spirit upon all flesh: and your sons and your daughters shall prophesy, and your young men shall see visions, and your old men shall DREAM dreams:"
  },
  {
    "word": "bones",
    "hint": "Acts 3",
    "verse": "Acts 3:7",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/3.7",
    "verseText": "And he took him by the right hand, and lifted him up: and immediately his feet and ankle BONES received strength."
  },
  {
    "word": "raise",
    "hint": "Acts 3",
    "verse": "Acts 3:22",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/3.22",
    "verseText": "For Moses truly said unto the fathers, A prophet shall the Lord your God RAISE up unto you of your brethren, like unto me; him shall ye hear in all things whatsoever he shall say unto you."
  },
  {
    "word": "other",
    "hint": "Acts 4",
    "verse": "Acts 4:12",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/4.12",
    "verseText": "Neither is there salvation in any OTHER: for there is none OTHER name under heaven given among men, whereby we must be saved."
  },
  {
    "word": "grace",
    "hint": "Acts 4",
    "verse": "Acts 4:33",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/4.33",
    "verseText": "And with great power gave the apostles witness of the resurrection of the Lord Jesus: and great GRACE was upon them all."
  },
  {
    "word": "given",
    "hint": "Acts 5",
    "verse": "Acts 5:32",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/5.32",
    "verseText": "And we are his witnesses of these things; and so is also the Holy Ghost, whom God hath GIVEN to them that obey him."
  },
  {
    "word": "seven",
    "hint": "Acts 6",
    "verse": "Acts 6:3",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/6.3",
    "verseText": "Wherefore, brethren, look ye out among you SEVEN men of honest report, full of the Holy Ghost and wisdom, whom we may appoint over this business."
  },
  {
    "word": "chose",
    "hint": "Acts 6",
    "verse": "Acts 6:5",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/6.5",
    "verseText": "And the saying pleased the whole multitude: and they CHOSE Stephen, a man full of faith and of the Holy Ghost, and Philip, and Prochorus, and Nicanor, and Timon, and Parmenas, and Nicolas a proselyte of Antioch:"
  },
  {
    "word": "after",
    "hint": "Acts 7",
    "verse": "Acts 7:36",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/7",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/7.36",
    "verseText": "He brought them out, AFTER that he had shewed wonders and signs in the land of Egypt, and in the Red sea, and in the wilderness forty years."
  },
  {
    "word": "right",
    "hint": "Acts 7",
    "verse": "Acts 7:55",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/7",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/7.55",
    "verseText": "But he, being full of the Holy Ghost, looked up steadfastly into heaven, and saw the glory of God, and Jesus standing on the RIGHT hand of God,"
  },
  {
    "word": "havoc",
    "hint": "Acts 8",
    "verse": "Acts 8:3",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/8",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/8.3",
    "verseText": "As for Saul, he made HAVOC of the church, entering into every house, and haling men and women committed them to prison."
  },
  {
    "word": "kings",
    "hint": "Acts 9",
    "verse": "Acts 9:15",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/9",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/9.15",
    "verseText": "But the Lord said unto him, Go thy way: for he is a chosen vessel unto me, to bear my cname before the Gentiles, and KINGS, and the children of Israel:"
  },
  {
    "word": "dwelt",
    "hint": "Acts 9",
    "verse": "Acts 9:22",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/9",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/9.22",
    "verseText": "But Saul increased the more in strength, and confounded the Jews which DWELT at Damascus, proving that this is very Christ."
  },
  {
    "word": "eaten",
    "hint": "Acts 10",
    "verse": "Acts 10:14",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/10",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/10.14",
    "verseText": "But Peter said, Not so, Lord; for I have never EATEN any thing that is common or unclean."
  },
  {
    "word": "first",
    "hint": "Acts 11",
    "verse": "Acts 11:26",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/11",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/11.26",
    "verseText": "And when he had found him, he brought him unto Antioch. And it came to pass, that a whole year they assembled themselves with the church, and taught much people. And the disciples were called Christians FIRST in Antioch."
  },
  {
    "word": "worms",
    "hint": "Acts 12",
    "verse": "Acts 12:23",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/12",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/12.23",
    "verseText": "And immediately the angel of the Lord smote him, because he gave not God the glory: and he was eaten of WORMS, and gave up the ghost."
  },
  {
    "word": "enemy",
    "hint": "Acts 13",
    "verse": "Acts 13:10",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/13",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/13.10",
    "verseText": "And said, O full of all subtilty and all mischief, thou child of the devil, thou ENEMY of all righteousness, wilt thou not cease to pervert the right ways of the Lord?",
    "submissionText": "Thanks to Temani Evans for submitting this word!"
  },
  {
    "word": "every",
    "hint": "Acts 14",
    "verse": "Acts 14:23",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/14",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/14.23",
    "verseText": "And when they had ordained them elders in EVERY church, and had prayed with fasting, they commended them to the Lord, on whom they believed."
  },
  {
    "word": "saved",
    "hint": "Acts 15",
    "verse": "Acts 15:11",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/15",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/15.11",
    "verseText": "But we believe that through the grace of the Lord Jesus Christ we shall be SAVED, even as they."
  },
  {
    "word": "visit",
    "hint": "Acts 15",
    "verse": "Acts 15:36",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/15",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/15.36",
    "verseText": "And some days after Paul said unto Barnabas, Let us go again and VISIT our brethren in every city where we have preached the word of the Lord, and see how they do."
  },
  {
    "word": "doors",
    "hint": "Acts 16",
    "verse": "Acts 16:26",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/16",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/16.26",
    "verseText": "And suddenly there was a great earthquake, so that the foundations of the prison were shaken: and immediately all the DOORS were opened, and every one’s bands were loosed."
  },
  {
    "word": "poets",
    "hint": "Acts 17",
    "verse": "Acts 17:28",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/17",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/17.28",
    "verseText": "For in him we live, and move, and have our being; as certain also of your own POETS have said, For we are also his offspring."
  },
  {
    "word": "speak",
    "hint": "Acts 18",
    "verse": "Acts 18:9",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/18",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/18.9",
    "verseText": "Then spake the Lord to Paul in the night by a vision, Be not afraid, but SPEAK, and hold not thy peace:"
  },
  {
    "word": "price",
    "hint": "Acts 19",
    "verse": "Acts 19:19",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/19",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/19.19",
    "verseText": "Many of them also which used curious arts brought their books together, and burned them before all men: and they counted the PRICE of them, and found it fifty thousand pieces of silver."
  },
  {
    "word": "flock",
    "hint": "Acts 20",
    "verse": "Acts 20:28",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/20",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/20.28",
    "verseText": "Take heed therefore unto yourselves, and to all the FLOCK, over the which the Holy Ghost hath made you overseers, to feed the church of God, which he hath purchased with his own blood."
  },
  {
    "word": "shave",
    "hint": "Acts 21",
    "verse": "Acts 21:24",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/21",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/21.24",
    "verseText": "Them take, and purify thyself with them, and be at charges with them, that they may SHAVE their heads: and all may know that those things, whereof they were informed concerning thee, are nothing; but that thou thyself also walkest orderly, and keepest the law."
  },
  {
    "word": "daily",
    "hint": "Acts 16",
    "verse": "Acts 16:5",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/16",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/16.5",
    "verseText": "And so were the churches established in the faith, and increased in number DAILY."
  },
  {
    "word": "shone",
    "hint": "Acts 22",
    "verse": "Acts 22:6",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/22",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/22.6",
    "verseText": "And it came to pass, that, as I made my journey, and was come nigh unto Damascus about noon, suddenly there SHONE from heaven a great light round about me."
  },
  {
    "word": "night",
    "hint": "Acts 23",
    "verse": "Acts 23:11",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/23",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/23.11",
    "verseText": "And the NIGHT following the Lord stood by him, and said, Be of good cheer, Paul: for as thou hast testified of me in Jerusalem, so must thou bear witness also at Rome."
  },
  {
    "word": "noble",
    "hint": "Acts 24",
    "verse": "Acts 24:3",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/24",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/24.3",
    "verseText": "We accept it always, and in all places, most NOBLE Felix, with all thankfulness."
  },
  {
    "word": "asked",
    "hint": "Acts 25",
    "verse": "Acts 25:20",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/25",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/25.20",
    "verseText": "And because I doubted of such manner of questions, I ASKED him whether he would go to Jerusalem, and there be judged of these matters.",
    "submissionText": "Thanks to Brynlee McMullin for submitting this word!"
  },
  {
    "word": "aside",
    "hint": "Acts 26",
    "verse": "Acts 26:31",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/26",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/26.31",
    "verseText": "And when they were gone ASIDE, they talked between themselves, saying, This man doeth nothing worthy of death or of bonds."
  },
  {
    "word": "serve",
    "hint": "Acts 27",
    "verse": "Acts 27:23",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/27",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/27.23",
    "verseText": "For there stood by me this night the angel of God, whose I am, and whom I SERVE,"
  },
  {
    "word": "hired",
    "hint": "Acts 28",
    "verse": "Acts 28:30",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/28",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/28.30",
    "verseText": "And Paul dwelt two whole years in his own HIRED house, and received all that came in unto him,"
  },
  {
    "word": "power",
    "hint": "Romans 1",
    "verse": "Romans 1:16",
    "hintUrl": "https://site.churchofjesuschrist.org/study/scriptures/nt/rom/1",
    "verseUrl": "https://site.churchofjesuschrist.org/study/scriptures/nt/rom/1.16",
    "verseText": "For I am not ashamed of the gospel of Christ: for it is the POWER of God unto salvation to every one that believeth; to the Jew first, and also to the Greek.",
    "submissionText": "Thanks to Evan Bateman for submitting this word!"
  },
  {
    "word": "wrath",
    "hint": "Romans 2",
    "verse": "Romans 2:8",
    "hintUrl": "https://site.churchofjesuschrist.org/study/scriptures/nt/rom/2",
    "verseUrl": "https://site.churchofjesuschrist.org/study/scriptures/nt/rom/2.8",
    "verseText": "But unto them that are contentious, and do not obey the truth, but obey unrighteousness, indignation and WRATH,",
    "submissionText": "Thanks to Kiley Thomas for submitting this word! Also, Happy Birthday!"
  },
  {
    "word": "short",
    "hint": "Romans 3",
    "verse": "Romans 3:23",
    "hintUrl": "https://site.churchofjesuschrist.org/study/scriptures/nt/rom/3",
    "verseUrl": "https://site.churchofjesuschrist.org/study/scriptures/nt/rom/3.23",
    "verseText": "For all have sinned, and come SHORT of the glory of God;"
  },
  {
    "word": "fully",
    "hint": "Romans 4",
    "verse": "Romans 4:21",
    "hintUrl": "https://site.churchofjesuschrist.org/study/scriptures/nt/rom/4",
    "verseUrl": "https://site.churchofjesuschrist.org/study/scriptures/nt/rom/4.21",
    "verseText": "And being FULLY persuaded that, what he had promised, he was able also to perform."
  },
  {
    "word": "reign",
    "hint": "Romans 5",
    "verse": "Romans 5:21",
    "hintUrl": "https://site.churchofjesuschrist.org/study/scriptures/nt/rom/5",
    "verseUrl": "https://site.churchofjesuschrist.org/study/scriptures/nt/rom/5.21",
    "verseText": "That as sin hath reigned unto death, even so might grace REIGN through righteousness unto eternal life by Jesus Christ our Lord."
  },
  {
    "word": "alive",
    "hint": "Romans 6",
    "verse": "Romans 6:11",
    "hintUrl": "https://site.churchofjesuschrist.org/study/scriptures/nt/rom/6",
    "verseUrl": "https://site.churchofjesuschrist.org/study/scriptures/nt/rom/6.11",
    "verseText": "Likewise reckon ye also yourselves to be dead indeed unto sin, but ALIVE unto God through Jesus Christ our Lord."
  },
  {
    "word": "fruit",
    "hint": "Romans 6",
    "verse": "Romans 6:22",
    "hintUrl": "https://site.churchofjesuschrist.org/study/scriptures/nt/rom/6",
    "verseUrl": "https://site.churchofjesuschrist.org/study/scriptures/nt/rom/6.22",
    "verseText": "But now being made free from sin, and become servants to God, ye have your FRUIT unto holiness, and the end everlasting life.",
    "submissionText": "Thanks to Noelle Gosnell for submitting this word!"
  },
  {
    "word": "bring",
    "hint": "Romans 10",
    "verse": "Romans 10.15",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rom/10",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rom/10.15",
    "verseText": "And how shall they preach, except they be sent? as it is written, How beautiful are the feet of them that preach the gospel of peace, and BRING glad tidings of good things!"
  },
  {
    "word": "depth",
    "hint": "Romans 11",
    "verse": "Romans 11:33",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rom/11",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rom/11.33",
    "verseText": "O the DEPTH of the riches both of the wisdom and knowledge of God! how unsearchable are his judgments, and his ways past finding out!"
  },
  {
    "word": "being",
    "hint": "Romans 12",
    "verse": "Romans 12:5",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rom/12",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rom/12.5",
    "verseText": "So we, BEING many, are one body in Christ, and every one members one of another."
  },
  {
    "word": "lusts",
    "hint": "Romans 13",
    "verse": "Romans 13.14",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rom/13",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rom/13.14",
    "verseText": "But put ye on the Lord Jesus Christ, and make not provision for the flesh, to fulfil the LUSTS thereof."
  },
  {
    "word": "edify",
    "hint": "Romans 14",
    "verse": "Romans 14:19",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rom/14",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rom/14.19",
    "verseText": "Let us therefore follow after the things which make for peace, and things wherewith one may EDIFY another."
  },
  {
    "word": "avoid",
    "hint": "Romans 16",
    "verse": "Romans 16:17",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rom/16",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rom/16.17",
    "verseText": "Now I beseech you, brethren, mark them which cause divisions and offences contrary to the doctrine which ye have learned; and AVOID them."
  },
  {
    "word": "heirs",
    "hint": "Romans 8",
    "verse": "Romans 8:23",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rom/8",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rom/8.23",
    "verseText": "And if children, then HEIRS; HEIRS of God, and joint-HEIRS with Christ; if so be that we suffer with him, that we may be also glorified together.",
    "submissionText": "Thanks to Hawley Brooks for submitting this word!"
  },
  {
    "word": "among",
    "hint": "1 Corinthians 1",
    "verse": "1 Corinthians 1:10",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/1.10",
    "verseText": "Now I beseech you, brethren, by the name of our Lord Jesus Christ, that ye all speak the same thing, and that there be no divisions AMONG you; but that ye be perfectly joined together in the same mind and in the same judgment."
  },
  {
    "word": "heard",
    "hint": "1 Corinthians 2",
    "verse": "1 Corinthians 2:9",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/2.9",
    "verseText": "But as it is written, Eye hath not seen, nor ear HEARD, neither have entered into the heart of man, the things which God hath prepared for them that love him."
  },
  {
    "word": "babes",
    "hint": "1 Corinthians 3",
    "verse": "1 Corinthians 3:1",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/3.1",
    "verseText": "And I, brethren, could not speak unto you as unto spiritual, but as unto carnal, even as unto BABES in Christ."
  },
  {
    "word": "shame",
    "hint": "1 Corinthians 4",
    "verse": "1 Corinthians 4:14",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/4.14",
    "verseText": "I write not these things to SHAME you, but as my beloved sons I warn you."
  },
  {
    "word": "purge",
    "hint": "1 Corinthians 5",
    "verse": "1 Corinthians 5:7",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/5.7",
    "verseText": "PURGE out therefore the old leaven, that ye may be a new lump, as ye are unleavened. For even Christ our passover is sacrificed for us:"
  },
  {
    "word": "meats",
    "hint": "1 Corinthians 6",
    "verse": "1 Corinthians 6:13",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/6.13",
    "verseText": "MEATS for the belly, and the belly for MEATS: but God shall destroy both it and them. Now the body is not for fornication, but for the Lord; and the Lord for the body."
  },
  {
    "word": "wiser",
    "hint": "1 Corinthians 1",
    "verse": "1 Corinthians 1:25",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/1.25",
    "verseText": "Because the foolishness of God is WISER than men; and the weakness of God is stronger than men.",
    "submissionText": "Thanks to Dan Wilcox for submitting this word!"
  },
  {
    "word": "wound",
    "hint": "1 Corinthians 8",
    "verse": "1 Corinthians 8:12",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/8",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/8.12",
    "verseText": "But when ye sin so against the brethren, and WOUND their weak conscience, ye sin against Christ.",
    "submissionText": "Thanks to Ashley Searls for submitting this word!"
  },
  {
    "word": "glory",
    "hint": "1 Corinthians 9",
    "verse": "1 Corinthians 9:16",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/9",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/9.16",
    "verseText": "For though I preach the gospel, I have nothing to GLORY of: for necessity is laid upon me; yea, woe is unto me, if I preach not the gospel!"
  },
  {
    "word": "above",
    "hint": "1 Corinthians 10",
    "verse": "1 Corinthians 10:13",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/10",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/10.13",
    "verseText": "There hath no temptation taken you but such as is common to man: but God is faithful, who will not suffer you to be tempted ABOVE that ye are able; but will with the temptation also make a way to escape, that ye may be able to bear it."
  },
  {
    "word": "woman",
    "hint": "1 Corinthians 11",
    "verse": "1 Corinthians 11:11",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/11",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/11.11",
    "verseText": "Nevertheless neither is the man without the WOMAN, neither the WOMAN without the man, in the Lord."
  },
  {
    "word": "kinds",
    "hint": "1 Corinthians 12",
    "verse": "1 Corinthians 12:31",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/12",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/12.10",
    "verseText": "To another the working of miracles; to another prophecy; to another discerning of spirits; to another divers KINDS of tongues; to another the interpretation of tongues:"
  },
  {
    "word": "covet",
    "hint": "1 Corinthians 12",
    "verse": "1 Corinthians 12:31",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/12",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/12.31",
    "verseText": "But COVET earnestly the best gifts: and yet shew I unto you a more excellent way."
  },
  {
    "word": "cease",
    "hint": "1 Corinthians 13",
    "verse": "1 Corinthians 13:8",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/13",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/13.8",
    "verseText": "Charity never faileth: but whether there be prophecies, they shall fail; whether there be tongues, they shall CEASE; whether there be knowledge, it shall vanish away."
  },
  {
    "word": "excel",
    "hint": "1 Corinthians 14",
    "verse": "1 Corinthians 14:12",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/14",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/14.12",
    "verseText": "Even so ye, forasmuch as ye are zealous of spiritual gifts, seek that ye may EXCEL to the edifying of the church.",
    "submissionText": "Thanks to Katelyn S for submitting this word!"
  },
  {
    "word": "order",
    "hint": "1 Corinthians 14",
    "verse": "1 Corinthians 14:40",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/14",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/14.40",
    "verseText": "Let all things be done decently and in ORDER."
  },
  {
    "word": "slept",
    "hint": "1 Corinthians 15",
    "verse": "1 Corinthians 15:20",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/15",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/15.20",
    "verseText": "But now is Christ risen from the dead, and become the firstfruits of them that SLEPT."
  },
  {
    "word": "borne",
    "hint": "1 Corinthians 15",
    "verse": "1 Corinthians 15:49",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/15",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/15.49",
    "verseText": "And as we have BORNE the image of the earthy, we shall also bear the image of the heavenly."
  },
  {
    "word": "stand",
    "hint": "1 Corinthians 16",
    "verse": "1 Corinthians 16:13",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/16",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/16.13",
    "verseText": "Watch ye, STAND fast in the faith, quit you like men, be strong."
  },
  {
    "word": "greet",
    "hint": "1 Corinthians 16",
    "verse": "1 Corinthians 16:20",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/16",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/16.20",
    "verseText": "All the brethren GREET you. GREET ye one another with an holy kiss."
  },
  {
    "word": "which",
    "hint": "1 Corinthians 15",
    "verse": "1 Corinthians 15:29",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/15",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/15.29",
    "verseText": "Else what shall they do WHICH are baptized for the dead, if the dead rise not at all? why are they then baptized for the dead?"
  },
  {
    "word": "godly",
    "hint": "2 Corinthians 1",
    "verse": "2 Corinthians 1:10",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/1.10",
    "verseText": "For our rejoicing is this, the testimony of our conscience, that in simplicity and GODLY sincerity, not with fleshly wisdom, but by the grace of God, we have had our conversation in the world, and more abundantly to you-ward."
  },
  {
    "word": "tears",
    "hint": "2 Corinthians 2",
    "verse": "2 Corinthians 2:4",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/2.4",
    "verseText": "For out of much affliction and anguish of heart I wrote unto you with many TEARS; not that ye should be grieved, but that ye might know the love which I have more abundantly unto you."
  },
  {
    "word": "glass",
    "hint": "2 Corinthians 3",
    "verse": "2 Corinthians 3:18",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/3.18",
    "verseText": "But we all, with open face beholding as in a GLASS the glory of the Lord, are changed into the same image from glory to glory, even as by the Spirit of the Lord."
  },
  {
    "word": "faint",
    "hint": "2 Corinthians 4",
    "verse": "2 Corinthians 4:16",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/4.16",
    "verseText": "For which cause we FAINT not; but though our outward man perish, yet the inward man is renewed day by day."
  },
  {
    "word": "proof",
    "hint": "2 Corinthians 2",
    "verse": "2 Corinthians 2:9",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/2.9",
    "verseText": "For to this end also did I write, that I might know the PROOF of you, whether ye be obedient in all things."
  },
  {
    "word": "yoked",
    "hint": "2 Corinthians 6",
    "verse": "2 Corinthians 6:14",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/6.14",
    "verseText": "Be ye not unequally YOKED together with unbelievers: for what fellowship hath righteousness with unrighteousness? and what communion hath light with darkness?"
  },
  {
    "word": "sorry",
    "hint": "2 Corinthians 7",
    "verse": "2 Corinthians 7:9",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/7",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/7.9",
    "verseText": "Now I rejoice, not that ye were made SORRY, but that ye sorrowed to repentance: for ye were made SORRY after a godly manner, that ye might receive damage by us in nothing."
  },
  {
    "word": "eased",
    "hint": "2 Corinthians 8",
    "verse": "2 Corinthians 8:13",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/8",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/8.13",
    "verseText": "For I mean not that other men be EASED, and ye burdened:"
  },
  {
    "word": "giver",
    "hint": "2 Corinthians 9",
    "verse": "2 Corinthians 9:7",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/9",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/9.7",
    "verseText": "Every man according as he purposeth in his heart, so let him give; not grudgingly, or of necessity: for God loveth a cheerful GIVER.",
    "submissionText": "Thanks to Tyler Rohrer for submitting this word!"
  },
  {
    "word": "boast",
    "hint": "2 Corinthians 10",
    "verse": "2 Corinthians 10:13",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/10",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/10.13",
    "verseText": "But we will not BOAST of things without our measure, but according to the measure of the rule which God hath distributed to us, a measure to reach even unto you.",
    "submissionText": "Thanks to Emma S for submitting this word!"
  },
  {
    "word": "folly",
    "hint": "2 Corinthians 11",
    "verse": "2 Corinthians 11:1",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/11",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/11.1",
    "verseText": "Would to God ye could bear with me a little in my FOLLY: and indeed bear with me."
  },
  {
    "word": "third",
    "hint": "2 Corinthians 12",
    "verse": "2 Corinthians 12:2",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/12",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/12.2",
    "verseText": "I knew a man in Christ above fourteen years ago, (whether in the body, I cannot tell; or whether out of the body, I cannot tell: God knoweth;) such an one caught up to the THIRD heaven."
  },
  {
    "word": "thorn",
    "hint": "2 Corinthians 12",
    "verse": "2 Corinthians 12:7",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/12",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/12.7",
    "verseText": "And lest I should be exalted above measure through the abundance of the revelations, there was given to me a THORN in the flesh, the messenger of Satan to buffet me, lest I should be exalted above measure.",
    "submissionText": "Thanks to Rebecca Randall for submitting this word!"
  },
  {
    "word": "mouth",
    "hint": "2 Corinthians 13",
    "verse": "2 Corinthians 13:1",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/13",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-cor/13.1",
    "verseText": "This is the third time I am coming to you. In the MOUTH of two or three witnesses shall every word be established."
  },
  {
    "word": "write",
    "hint": "Galatians 1",
    "verse": "Galatians 1:20",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/gal/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/gal/1.20",
    "verseText": "Now the things which I WRITE unto you, behold, before God, I lie not."
  },
  {
    "word": "build",
    "hint": "Galatians 2",
    "verse": "Galatians 2:18",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/gal/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/gal/2.18",
    "verseText": "For if I BUILD again the things which I destroyed, I make myself a transgressor."
  },
   {
    "word": "seeds",
    "hint": "Galatians 3",
    "verse": "Galatians 3:16",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/gal/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/gal/3.16",
    "verseText": "Now to Abraham and his seed were the promises made. He saith not, And to SEEDS, as of many; but as of one, And to thy seed, which is Christ."
  },
  {
    "word": "break",
    "hint": "Galatians 4",
    "verse": "Galatians 4:27",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/gal/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/gal/4.27",
    "verseText": "For it is written, Rejoice, thou barren that bearest not; BREAK forth and cry, thou that travailest not: for the desolate hath many more children than she which hath an husband."
  },
  {
    "word": "shalt",
    "hint": "Galatians 5",
    "verse": "Galatians 5:14",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/gal/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/gal/5.14",
    "verseText": "For all the law is fulfilled in one word, even in this; Thou SHALT love thy neighbour as thyself."
  },
  {
    "word": "weary",
    "hint": "Galatians 6",
    "verse": "Galatians 6:9",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/gal/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/gal/6.9",
    "verseText": "And let us not be WEARY in well doing: for in due season we shall reap, if we faint not.",
    "submissionText": "Thanks to Megan Decker for submitting this word!"
  },
  {
    "word": "known",
    "hint": "Galatians 4",
    "verse": "Galatians 4:9",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/gal/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/gal/4.9",
    "verseText": "But now, after that ye have KNOWN God, or rather are KNOWN of God, how turn ye again to the weak and beggarly elements, whereunto ye desire again to be in bondage?"
  },
  {
    "word": "times",
    "hint": "Ephesians 1",
    "verse": "Ephesians 1:10",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/eph/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/eph/1.10",
    "verseText": "That in the dispensation of the fulness of TIMES he might gather together in one all things in Christ, both which are in heaven, and which are on earth; even in him:"
  },
  {
    "word": "fitly",
    "hint": "Ephesians 2",
    "verse": "Ephesians 2:21",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/eph/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/eph/2.21",
    "verseText": "In whom all the building FITLY framed together groweth unto an holy temple in the Lord:"
  },
  {
    "word": "knees",
    "hint": "Ephesians 3",
    "verse": "Ephesians 3:14",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/eph/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/eph/3.14",
    "verseText": "For this cause I bow my KNEES unto the Father of our Lord Jesus Christ,"
  },
  {
    "word": "unity",
    "hint": "Ephesians 4",
    "verse": "Ephesians 4:13",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/eph/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/eph/4.13",
    "verseText": "Till we all come in the UNITY of the faith, and of the knowledge of the Son of God, unto a perfect man, unto the measure of the stature of the fulness of Christ:"
  },
  {
    "word": "wives",
    "hint": "Ephesians 5",
    "verse": "Ephesians 5:25",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/eph/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/eph/5.25",
    "verseText": "Husbands, love your WIVES, even as Christ also loved the church, and gave himself for it;"
  },
  {
    "word": "wiles",
    "hint": "Ephesians 6",
    "verse": "Ephesians 6:11",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/eph/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/eph/6.11",
    "verseText": "Put on the whole armour of God, that ye may be able to stand against the WILES of the devil.",
    "submissionText": "Thanks to Amelia Hellstrom for submitting this word!"
  },
  {
    "word": "darts",
    "hint": "Ephesians 6",
    "verse": "Ephesians 6:16",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/eph/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/eph/6.16",
    "verseText": "Above all, taking the shield of faith, wherewith ye shall be able to quench all the fiery DARTS of the wicked."
  },
  {
    "word": "flesh",
    "hint": "Philippians 1",
    "verse": "Philippians 1:22",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/philip/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/philip/1.22",
    "verseText": "But if I live in the FLESH, this is the fruit of my labour: yet what I shall choose I wot not.",
    "submissionText": "Thanks to Sis. Roberts & Gillespie's Super Seminary Students!"
  },
  {
    "word": "cross",
    "hint": "Philippians 2",
    "verse": "Philippians 2:8",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/philip/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/philip/2.8",
    "verseText": "And being found in fashion as a man, he humbled himself, and became obedient unto death, even the death of the CROSS."
  },
  {
    "word": "prize",
    "hint": "Philippians 3",
    "verse": "Philippians 3:14",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/philip/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/philip/3.14",
    "verseText": "I press toward the mark for the PRIZE of the high calling of God in Christ Jesus."
  },
  {
    "word": "think",
    "hint": "Philippians 4",
    "verse": "Philippians 4:8",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/philip/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/philip/4.8",
    "verseText": "Finally, brethren, whatsoever things are true, whatsoever things arebhonest, whatsoever things are just, whatsoever things are pure, whatsoever things are lovely, whatsoever things are of good report; if there be any virtue, and if there be any praise, THINK on these things."
  },
  {
    "word": "moved",
    "hint": "Colossians 1",
    "verse": "Colossians 1:23",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/col/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/col/1.23",
    "verseText": "If ye continue in the faith grounded and settled, and be not MOVED away from the hope of the gospel, which ye have heard, and which was preached to every creature which is under heaven; whereof I Paul am made a minister;"
  },
  {
    "word": "built",
    "hint": "Colossians 2",
    "verse": "Colossians 2:7",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/col/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/col/2.7",
    "verseText": "Rooted and BUILT up in him, and stablished in the faith, as ye have been taught, abounding therein with thanksgiving."
  },
  {
    "word": "bonds",
    "hint": "Colossians 4",
    "verse": "Colossians 4:3",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/col/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/col/4.3",
    "verseText": "Withal praying also for us, that God would open unto us a door of utterance, to speak the mystery of Christ, for which I am also in BONDS:"
  },
  {
    "word": "idols",
    "hint": "1 Thessalonians 1",
    "verse": "1 Thessalonians 1:9",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-thes/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-thes/1.9",
    "verseText": "For they themselves shew of us what manner of entering in we had unto you, and how ye turned to God from IDOLS to serve the living and true God;"
  },
  {
    "word": "nurse",
    "hint": "1 Thessalonians 2",
    "verse": "1 Thessalonians 2:7",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-thes/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-thes/2.7",
    "verseText": "But we were gentle among you, even as a NURSE cherisheth her children:"
  },
  {
    "word": "study",
    "hint": "1 Thessalonians 4",
    "verse": "1 Thessalonians 4:11",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-thes/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-thes/4.11",
    "verseText": "And that ye STUDY to be quiet, and to do your own business, and to work with your own hands, as we commanded you;"
  },
  {
    "word": "prove",
    "hint": "1 Thessalonians 5",
    "verse": "1 Thessalonians 5:21",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-thes/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-thes/5.21",
    "verseText": "PROVE all things; hold fast that which is good."
  },
  {
    "word": "count",
    "hint": "2 Thessalonians 1",
    "verse": "2 Thessalonians 1:11",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-thes/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-thes/1.11",
    "verseText": "Wherefore also we pray always for you, that our God would COUNT you worthy of this calling, and fulfil all the good pleasure of his goodness, and the work of faith with power:"
  },
  {
    "word": "means",
    "hint": "2 Thessalonians 2",
    "verse": "2 Thessalonians 2:3",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-thes/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-thes/2.3",
    "verseText": "Let no man deceive you by any MEANS: afor that day shall not come, except there come a falling away first, and that man of sin be revealed, the son of perdition;",
    "submissionText": "Thanks to Daniela Wiberg for submitting this word!"
  },
  {
    "word": "ought",
    "hint": "2 Thessalonians 3",
    "verse": "2 Thessalonians 3:7",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-thes/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-thes/3.7",
    "verseText": "For yourselves know how ye OUGHT to follow us: for we behaved not ourselves disorderly among you;"
  },
  {
    "word": "quiet",
    "hint": "1 Timothy 2",
    "verse": "1 Timothy 2:2",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-tim/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-tim/2.2",
    "verseText": "For kings, and for all that are in authority; that we may lead a QUIET and peaceable clife in all godliness and dhonesty."
  },
  {
    "word": "money",
    "hint": "1 Timothy 6",
    "verse": "1 Timothy 6:10",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-tim/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-tim/6.10",
    "verseText": "For the love of MONEY is the root of all evil: which while some coveted after, they have erred from the faith, and pierced themselves through with many sorrows.",
    "submissionText": "Thanks to Sis. Roberts & Gillespie's Super Seminary Students for this submission!"
  },
  {
    "word": "silly",
    "hint": "2 Timothy 3",
    "verse": "2 Timothy 3:6",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-tim/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-tim/3.6",
    "verseText": "For of this sort are they which creep into houses, and lead captive SILLY women laden with sins, led away with divers lusts",
    "submissionText": "Thanks Rose for submitting this word!"
  },
  {
    "word": "fight",
    "hint": "2 Timothy 4",
    "verse": "2 Timothy 4:7",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-tim/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-tim/4.7",
    "verseText": "I have fought a good FIGHT, I have finished my course, I have kept the faith:"
  },
  {
    "word": "sober",
    "hint": "Titus 2",
    "verse": "Titus 2:6",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/titus/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/titus/2.6",
    "verseText": "Young men likewise exhort to be SOBER minded."
  },
  {
    "word": "repay",
    "hint": "Philemon 1",
    "verse": "Philemon 1:19",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/philem/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/philem/1.19",
    "verseText": "I Paul have written it with mine own hand, I will REPAY it: albeit I do not say to thee how thou owest unto me even thine own self besides."
  },
  {
    "word": "youth",
    "hint": "1 Timothy 4",
    "verse": "1 Timothy 4:12",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-tim/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-tim/4.12",
    "verseText": "Let no man despise thy YOUTH; but be thou an example of the believers, in word, in conversation, in charity, in spirit, in faith, in purity."
  },
    {
    "word": "earth",
    "hint": "Hebrews 1",
    "verse": "Hebrews 1:10",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/1.10",
    "verseText": "And, Thou, Lord, in the beginning hast laid the foundation of the EARTH; and the heavens are the works of thine hands:"
  },
  {
    "word": "cause",
    "hint": "Hebrews 2",
    "verse": "Hebrews 2:11",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/2.11",
    "verseText": "For both he that sanctifieth and they who are sanctified are all of one: for which CAUSE he is not ashamed to call them brethren,"
  },
  {
    "word": "house",
    "hint": "Hebrews 3",
    "verse": "Hebrews 3:6",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/3.6",
    "verseText": "But Christ as a son over his own HOUSE; whose HOUSE are we, if we hold fast the confidence and the rejoicing of the hope firm unto the end."
  },
  {
    "word": "quick",
    "hint": "Hebrews 4",
    "verse": "Hebrews 4:12",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/4.12",
    "verseText": "For the word of God is QUICK, and powerful, and sharper than any twoedged sword, piercing even to the dividing asunder of soul and spirit, and of the joints and marrow, and is a discerner of the thoughts and intents of the heart."
  },
  {
    "word": "useth",
    "hint": "Hebrews 5",
    "verse": "Hebrews 5:13",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/5.13",
    "verseText": "For every one that USETH milk is unskilful in the word of righteousness: for he is a babe."
  },
  {
    "word": "renew",
    "hint": "Hebrews 6",
    "verse": "Hebrews 6:6",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/6.6",
    "verseText": "If they shall fall away, to RENEW them again unto repentance; seeing they crucify to themselves the Son of God afresh, and put him to an open shame."
  },
  {
    "word": "swear",
    "hint": "Hebrews 6",
    "verse": "Hebrews 6:13",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/6",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/6.13",
    "verseText": "For when God made promise to Abraham, because he could SWEAR by no greater, he sware by himself,"
  },
  {
    "word": "tenth",
    "hint": "Hebrews 7",
    "verse": "Hebrews 7:2",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/7",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/7.2",
    "verseText": "To whom also Abraham gave a TENTH part of all; first being by interpretation King of righteousness, and after that also King of Salem, which is, King of peace;"
  },
  {
    "word": "fault",
    "hint": "Hebrews 8",
    "verse": "Hebrews 8:8",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/8",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/8.8",
    "verseText": "For finding FAULT with them, he saith, Behold, the days come, saith the Lord, when I will make a new covenant with the house of Israel and with the house of Judah:"
  },
  {
    "word": "goats",
    "hint": "Hebrews 9",
    "verse": "Hebrews 9:12",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/9",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/9.12",
    "verseText": "Neither by the blood of GOATS and calves, but by his own blood he entered in once into the holy place, having obtained eternal redemption for us."
  },
  {
    "word": "minds",
    "hint": "Hebrews 10",
    "verse": "Hebrews 10:16",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/10",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/10.16",
    "verseText": "This is the covenant that I will make with them after those days, saith the Lord, I will put my laws into their hearts, and in their MINDS will I write them;"
  },
  {
    "word": "beset",
    "hint": "Hebrews 12",
    "verse": "Hebrews 12:1",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/12",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/12.1",
    "verseText": "Wherefore seeing we also are compassed about with so great a cloud of witnesses, let us lay aside every weight, and the sin which doth so easily BESET us, and let us run with patience the race that is set before us,"
  },
  {
    "word": "leave",
    "hint": "Hebrews 13",
    "verse": "Hebrews 13:5",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/13",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/13.5",
    "verseText": "Let your conversation be without covetousness; and be content with such things as ye have: for he hath said, I will never LEAVE thee, nor forsake thee."
  },
  {
    "word": "hoped",
    "hint": "Hebrews 11",
    "verse": "Hebrews 11:1",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/11",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/heb/11.1",
    "verseText": "Now faith is the substance of things HOPED for, the evidence of things not seen."
  },
    {
    "word": "doers",
    "hint": "James 1",
    "verse": "James 1:22",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/james/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/james/1.22",
    "verseText": "But be ye DOERS of the word, and not hearers only, deceiving your own selves.",
    "submissionText": "Thanks to Jecia Hutchinson for submitting this word!"
  },
  {
    "word": "royal",
    "hint": "James 2",
    "verse": "James 2:8",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/james/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/james/2.8",
    "verseText": "If ye fulfil the ROYAL law according to the scripture, Thou shalt love thy neighbour as thyself, ye do well:",
    "submissionText": "Thanks to Helen Vernon for submitting this word!"
  },
  {
    "word": "olive",
    "hint": "James 3",
    "verse": "James 3:12",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/james/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/james/3.12",
    "verseText": "Can the fig tree, my brethren, bear OLIVE berries? either a vine, figs? so can no fountain both yield salt water and fresh."
  },
  {
    "word": "doeth",
    "hint": "James 4",
    "verse": "James 4:17",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/james/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/james/4.17",
    "verseText": "Therefore to him that knoweth to do good, and DOETH it not, to him it is sin."
  },
  {
    "word": "merry",
    "hint": "James 5",
    "verse": "James 5:13",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/james/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/james/5.13",
    "verseText": "Is any among you afflicted? let him pray. Is any MERRY? let him sing psalms."
  },
  {
    "word": "error",
    "hint": "James 5",
    "verse": "James 5:20",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/james/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/james/5.20",
    "verseText": "Let him know, that he which converteth the sinner from the ERROR of his way shall save a soul from death, and shall hide a multitude of sins.",
    "submissionText": "Thanks to Jecia Hutchinson for submitting this word!"
  },
  {
    "word": "given",
    "hint": "James 1",
    "verse": "James 1:5",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/james/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/james/1.5",
    "verseText": "If any of you lack wisdom, let him ask of God, that giveth to all men liberally, and upbraideth not; and it shall be GIVEN him."
  },
  {
    "word": "trial",
    "hint": "1 Peter 1",
    "verse": "1 Peter 1:7",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-pet/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-pet/1.7",
    "verseText": "That the TRIAL of your faith, being much more precious than of gold that perisheth, though it be tried with fire, might be found unto praise and honour and glory at the appearing of Jesus Christ:"
  },
  {
    "word": "forth",
    "hint": "1 Peter 2",
    "verse": "1 Peter 2:9",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-pet/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-pet/2.9",
    "verseText": "But ye are a chosen generation, a royal priesthood, an holy nation, a peculiar people; that ye should shew FORTH the praises of him who hath called you out of darkness into his marvellous light:"
  },
  {
    "word": "ready",
    "hint": "1 Peter 3",
    "verse": "1 Peter 3:15",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-pet/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-pet/3.15",
    "verseText": "But sanctify the Lord God in your hearts: and be READY always to give an answer to every man that asketh you a reason of the hope that is in you with meekness and fear:"
  },
  {
    "word": "might",
    "hint": "1 Peter 4",
    "verse": "1 Peter 4:6",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-pet/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-pet/4.6",
    "verseText": "For for this cause was the gospel preached also to them that are dead, that they MIGHT be judged according to men in the flesh, but live according to God in the spirit."
  },
  {
    "word": "proud",
    "hint": "1 Peter 5",
    "verse": "1 Peter 5:5",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-pet/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-pet/5.5",
    "verseText": "Likewise, ye younger, submit yourselves unto the elder. Yea, all of you be subject one to another, and be clothed with humility: for God resisteth the PROUD, and giveth grace to the humble."
  },
  {
    "word": "mount",
    "hint": "2 Peter 1",
    "verse": "2 Peter 1:18",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-pet/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-pet/1.18",
    "verseText": "And this voice which came from heaven we heard, when we were with him in the holy MOUNT."
  },
  {
    "word": "thief",
    "hint": "2 Peter 3",
    "verse": "2 Peter 3:10",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-pet/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-pet/3.10",
    "verseText": "But the day of the Lord will come as a THIEF in the night; in the which the heavens shall pass away with a great noise, and the elements shall melt with fervent heat, the earth also and the works that are therein shall be burned up."
  },
  {
    "word": "light",
    "hint": "1 John 1",
    "verse": "1 John 1:5",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-jn/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-jn/1.5",
    "verseText": "This then is the message which we have heard of him, and declare unto you, that God is LIGHT, and in him is no darkness at all."
  },
  {
    "word": "until",
    "hint": "1 John 2",
    "verse": "1 John 2:9",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-jn/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-jn/2.9",
    "verseText": "He that saith he is in the light, and hateth his brother, is in darkness even UNTIL now."
  },
  {
    "word": "there",
    "hint": "1 John 4",
    "verse": "1 John 4:18",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-jn/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-jn/4.18",
    "verseText": "THERE is no fear in love; but perfect love casteth out fear: because fear hath torment. He that feareth is not made perfect in love."
  },
  {
    "word": "agree",
    "hint": "1 John 5",
    "verse": "1 John 5:8",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-jn/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/1-jn/5.8",
    "verseText": "And there are three that bear witness in earth, the Spirit, and the water, and the blood: and these three AGREE in one."
  },
  {
    "word": "paper",
    "hint": "2 John 1",
    "verse": "2 John 1:12",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-jn/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/2-jn/1.12",
    "verseText": "Having many things to write unto you, I would not write with PAPER and ink: but I trust to come unto you, and speak face to face, that our joy may be full."
  },
  {
    "word": "crept",
    "hint": "Jude 1",
    "verse": "Jude 1:4",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/jude/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/jude/1.4",
    "verseText": "For there are certain men CREPT in unawares, who were before of old ordained to this condemnation, ungodly men, turning the grace of our God into lasciviousness, and denying the only Lord God, and our Lord Jesus Christ."
  },
  {
    "word": "truth",
    "hint": "3 John 1",
    "verse": "3 John 1:4",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/3-jn/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/3-jn/1.4",
    "verseText": "I have no greater joy than to hear that my children walk in TRUTH."
  },
  {
    "word": "flame",
    "hint": "Revelation 1",
    "verse": "Revelation 1:14",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/1.14",
    "verseText": "His head and his hairs were white like wool, as white as snow; and his eyes were as a FLAME of fire;"
  },
  {
    "word": "crown",
    "hint": "Revelation 2",
    "verse": "Revelation 2:10",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/2.10",
    "verseText": "Fear none of those things which thou shalt suffer: behold, the devil shall cast some of you into prison, that ye may be tried; and ye shall have tribulation ten days: be thou faithful unto death, and I will give thee a CROWN of life."
  },
  {
    "word": "knock",
    "hint": "Revelation 3",
    "verse": "Revelation 3:20",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/3.20",
    "verseText": "Behold, I stand at the door, and KNOCK: if any man hear my voice, and open the door, I will come in to him, and will sup with him, and he with me."
  },
  {
    "word": "eagle",
    "hint": "Revelation 4",
    "verse": "Revelation 4:7",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/4",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/4.7",
    "verseText": "And the first beast was like a lion, and the second beast like a calf, and the third beast had a face as a man, and the fourth beast was like a flying EAGLE."
  },
  {
    "word": "seals",
    "hint": "Revelation 5",
    "verse": "Revelation 5:9",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/5.9",
    "verseText": "And they sung a new song, saying, Thou art worthy to take the book, and to open the SEALS thereof: for thou wast slain, and hast redeemed us to God by thy blood out of every kindred, and tongue, and people, and nation;"
  },
  {
    "word": "works",
    "hint": "Revelation 3",
    "verse": "Revelation 3:15",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/3",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/3.15",
    "verseText": "I know thy WORKS, that thou art neither cold nor hot: I would thou wert cold or hot."
  },
  {
    "word": "slain",
    "hint": "Revelation 5",
    "verse": "Revelation 5:12",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/5.12",
    "verseText": "Saying with a loud voice, Worthy is the Lamb that was SLAIN to receive power, and riches, and wisdom, and strength, and honour, and glory, and blessing."
  },
  {
    "word": "robes",
    "hint": "Revelation 7",
    "verse": "Revelation 7:14",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/7",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/7.14",
    "verseText": "And I said unto him, Sir, thou knowest. And he said to me, These are they which came out of great tribulation, and have washed their ROBES, and made them white in the blood of the Lamb."
  },
  {
    "word": "space",
    "hint": "Revelation 8",
    "verse": "Revelation 8:1",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/8",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/8.1",
    "verseText": "And when he had opened the seventh seal, there was silence in heaven about the SPACE of half an hour."
  },
  {
    "word": "honey",
    "hint": "Revelation 10",
    "verse": "Revelation 10:10",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/10",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/10.10",
    "verseText": "And I took the little book out of the angel’s hand, and ate it up; and it was in my mouth sweet as HONEY: and as soon as I had eaten it, my belly was bitter."
  },
  {
    "word": "lives",
    "hint": "Revelation 12",
    "verse": "Revelation 12:11",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/12",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/12.11",
    "verseText": "And they overcame him by the blood of the Lamb, and by the word of their testimony; and they loved not their LIVES unto the death."
  },
  {
    "word": "beast",
    "hint": "Revelation 13",
    "verse": "Revelation 13:12",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/13",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/13.12",
    "verseText": "And he exerciseth all the power of the first BEAST before him, and causeth the earth and them which dwell therein to worship the first BEAST, whose deadly wound was healed"
  },
  {
    "word": "dwell",
    "hint": "Revelation 14",
    "verse": "Revelation 14:6",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/14",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/14.6",
    "verseText": "And I saw another angel fly in the midst of heaven, having the everlasting gospel to preach unto them that DWELL on the earth, and to every nation, and kindred, and tongue, and people,"
  },
  {
    "word": "tears",
    "hint": "Revelation 7",
    "verse": "Revelation 7:17",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/7",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/7.17",
    "verseText": "or the Lamb which is in the midst of the throne shall feed them, and shall lead them unto living fountains of waters: and God shall wipe away all TEARS from their eyes."
  },
  { //
    "word": "shall",
    "hint": "Isaiah 7",
    "verse": "Isaiah 7:14",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/ot/isa/7",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/ot/isa/7.14",
    "verseText": "Therefore the Lord himself SHALL give you a sign; Behold, a virgin SHALL conceive, and bear a son, and SHALL call his name Immanuel.",
    "submissionText": ""
  },
  { //
    "word": "ruler",
    "hint": "Micah 5",
    "verse": "Micah 5:2",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/ot/micah/5",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/ot/micah/5.2",
    "verseText": "But thou, Beth-lehem Ephratah, though thou be little among the thousands of Judah, yet out of thee shall he come forth unto me that is to be RULER in Israel; whose goings forth have been from of old, from everlasting.",
    "submissionText": ""
  },
  { //
    "word": "child",
    "hint": "Isaiah 9",
    "verse": "Isaiah 9:6",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/ot/isa/9",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/ot/isa/9.6",
    "verseText": "For unto us a CHILD is born, unto us a son is given: and the government shall be upon his shoulder: and his name shall be called Wonderful, Counsellor, The mighty God, The everlasting Father, The Prince of Peace.",
    "submissionText": ""
  },
  { //
    "word": "ghost",
    "hint": "Matthew 1",
    "verse": "Matthew 1:18",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/1",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/1.18",
    "verseText": "Now the birth of Jesus Christ was on this wise: When as his mother Mary was espoused to Joseph, before they came together, she was found with child of the Holy GHOST.",
    "submissionText": ""
  },
  { //
    "word": "taxed",
    "hint": "Luke 2",
    "verse": "Luke 2:5",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/2.5",
    "verseText": "To be TAXED with Mary his aespoused wife, being great with child.",
    "submissionText": ""
  },
  { //
    "word": "where",
    "hint": "Matthew 2",
    "verse": "Matthew 2:2",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/2.2",
    "verseText": "Saying, WHERE is he that is born King of the Jews? for we have seen his star in the east, and are come to worship him.",
    "submissionText": ""
  },
  { //
    "word": "lying",
    "hint": "Luke 2",
    "verse": "Luke 2:12",
    "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/2",
    "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/2.12",
    "verseText": "And this shall be a sign unto you; Ye shall find the babe wrapped in swaddling clothes, LYING in a manger.",
    "submissionText": ""
  },
  { //
    "word": "rocks",
      "hint": "this hymn",
      "verse": "Joy to the World",
      "hintUrl": "https://www.churchofjesuschrist.org/study/manual/hymns/joy-to-the-world",
      "verseUrl": "https://www.churchofjesuschrist.org/study/manual/hymns/joy-to-the-world",
      "verseText": "Rejoice! Rejoice when Jesus reigns, And Saints their songs employ, While fields and floods, ROCKS, hills, and plains, Repeat the sounding joy."
    },
    { //
      "word": "frogs",
      "hint": "Revelation 16",
      "verse": "Revelation 16:13",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/16",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/16.13",
      "verseText": "And I saw three unclean spirits like FROGS come out of the mouth of the dragon, and out of the mouth of the beast, and out of the mouth of the false prophet."
    },
    { //
      "word": "lords",
      "hint": "Revelation 17",
      "verse": "Revelation 17:14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/17",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/17.14",
      "verseText": "These shall make war with the Lamb, and the Lamb shall overcome them: for he is Lord of LORDS, and King of kings: and they that are with him are called, and chosen, and faithful."
    },
    { //
      "word": "bride",
      "hint": "Revelation 18",
      "verse": "Revelation 18:23",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/18",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/18.23",
      "verseText": "And the light of a candle shall shine no more at all in thee; and the aoice of the bridegroom and of the BRIDE shall be heard no more at all in thee: for thy merchants were the great men of the earth; for by thy sorceries were all nations deceived."
    },
    { //
      "word": "linen",
      "hint": "Revelation 19",
      "verse": "Revelation 19:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/19",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/19.8",
      "verseText": "And to her was granted that she should be arrayed in fine LINEN, clean and white: for the fine LINEN is the righteousness of saints."
    },
    { //
      "word": "bound",
      "hint": "Revelation 20",
      "verse": "Revelation 20:2",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/20",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/20.2",
      "verseText": "And he laid hold on the dragon, that old serpent, which is the Devil, and Satan, and BOUND him a thousand years,"
    },
    { //
      "word": "omega",
      "hint": "Revelation 22",
      "verse": "Revelation 22:13",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/22",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/rev/22.13",
      "verseText": "I am Alpha and OMEGA, the beginning and the end, the first and the last."
    },
    { //
      "word": "tower",
      "hint": "the Title Page",
      "verse": "Title Page",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/bofm-title",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/bofm-title",
      "verseText": "... who were scattered at the time the Lord confounded the language of the people, when they were building a TOWER to get to heaven."
    },
    { //
      "word": "among",
      "hint": "the Introduction of the Book of Mormon",
      "verse": "Introduction of the Book of Mormon",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/introduction",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/introduction",
      "verseText": "After thousands of years, all were destroyed except the Lamanites, and they are AMONG the ancestors of the American Indians."
    },
    { //
      "word": "shown",
      "hint": "the Testimony of the Three Witnesses",
      "verse": "Testimony of the Three Witnesses",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/three",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/three",
      "verseText": "And we also testify that we have seen the engravings which are upon the plates; and they have been SHOWN unto us by the power of God, and not of man."
    },
    { //
      "word": "names",
      "hint": "the Testimony of the Eight Witnesses",
      "verse": "Testimony of the Eight Witnesses",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/eight",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/eight",
      "verseText": "And we give our NAMES unto the world, to witness unto the world that which we have seen. And we lie not, God bearing witness of it."
    },
    { //
      "word": "loose",
      "hint": "the Testimony of the Prophet Joseph Smith",
      "verse": "Testimony of the Prophet Joseph Smith",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/js",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/js",
      "verseText": "He had on a LOOSE robe of most exquisite whiteness. It was a whiteness beyond anything earthly I had ever seen; nor do I believe that any earthly thing could be made to appear so exceedingly white and brilliant."
    },
    { //
      "word": "visit",
      "hint": "the Testimony of the Prophet Joseph Smith",
      "verse": "Testimony of the Prophet Joseph Smith",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/js",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/js",
      "verseText": "After this third VISIT, he again ascended into heaven as before, and I was again left to ponder on the strangeness of what I had just experienced;"
    },
    { //
      "word": "other",
      "hint": "the Introduction of the Book of Mormon",
      "verse": "Introduction of the Book of Mormon",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/introduction",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/introduction",
      "verseText": "I told the brethren that the Book of Mormon was the most correct of any book on earth, and the keystone of our religion, and a man would get nearer to God by abiding by its precepts, than by any OTHER book."
    },
    { //
      "word": "quake",
      "hint": "1 Nephi 1",
      "verse": "1 Nephi 1:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/1.6",
      "verseText": "And it came to pass as he prayed unto the Lord, there came a pillar of fire and dwelt upon a rock before him; and he saw and heard much; and because of the things which he saw and heard he did QUAKE and tremble exceedingly."
    },
    { //
      "word": "dwelt",
      "hint": "1 Nephi 2",
      "verse": "1 Nephi 2:15",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/2.15",
      "verseText": "And my father DWELT in a tent."
    },
    { //
      "word": "rebel",
      "hint": "1 Nephi 2",
      "verse": "1 Nephi 2:23",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/2.23",
      "verseText": "For behold, in that day that they shall REBEL against me, I will curse them even with a sore curse, and they shall have no power over thy seed except they shall REBEL against me also."
    },
    { //
      "word": "fifty",
      "hint": "1 Nephi 3",
      "verse": "1 Nephi 3:31",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/3.31",
      "verseText": "And after the angel had departed, Laman and Lemuel again began to murmur, saying: How is it possible that the Lord will deliver Laban into our hands? Behold, he is a mighty man, and he can command FIFTY, yea, even he can slay FIFTY; then why not us?"
    },
    { //
      "word": "smote",
      "hint": "1 Nephi 4",
      "verse": "1 Nephi 4:18",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/4",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/4.18",
      "verseText": "Therefore I did obey the voice of the Spirit, and took Laban by the hair of the head, and I SMOTE off his head with his own sword."
    },
    { //
      "word": "books",
      "hint": "1 Nephi 5",
      "verse": "1 Nephi 5:11",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/5.11",
      "verseText": "And he beheld that they did contain the five BOOKS of Moses, which gave an account of the creation of the world, and also of Adam and Eve, who were our first parents;"
    },
    { //
      "word": "brass",
      "hint": "1 Nephi 5",
      "verse": "1 Nephi 5:18",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/5.18",
      "verseText": "That these plates of BRASS should go forth unto all nations, kindreds, tongues, and people who were of his seed."
    },
    { //
      "word": "write",
      "hint": "1 Nephi 6",
      "verse": "1 Nephi 6:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/6",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/6.5",
      "verseText": "Wherefore, the things which are pleasing unto the world I do not WRITE, but the things which are pleasing unto God and unto those who are not of the world."
    },
    { //
      "word": "cords",
      "hint": "1 Nephi 7",
      "verse": "1 Nephi 7:16",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/7.16",
      "verseText": "And it came to pass that when I, Nephi, had spoken these words unto my brethren, they were angry with me. And it came to pass that they did lay their hands upon me, for behold, they were exceedingly wroth, and they did bind me with CORDS, for they sought to take away my life, that they might leave me in the wilderness to be devoured by wild beasts."
    },
    { //
      "word": "fruit",
      "hint": "1 Nephi 8",
      "verse": "1 Nephi 8:12",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/8.12",
      "verseText": "And as I partook of the FRUIT thereof it filled my soul with exceedingly great joy; wherefore, I began to be desirous that my family should partake of it also; for I knew that it was desirable above all other FRUIT."
    },
    { //
      "word": "scorn",
      "hint": "1 Nephi 8",
      "verse": "1 Nephi 8:33",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/8.33",
      "verseText": "And great was the multitude that did enter into that strange building. And after they did enter into that building they did point the finger of SCORN at me and those that were partaking of the fruit also; but we heeded them not."
    },
    { //
      "word": "words",
      "hint": "1 Nephi 9",
      "verse": "1 Nephi 9:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/9",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/9.6",
      "verseText": "But the Lord knoweth all things from the beginning; wherefore, he prepareth a way to accomplish all his works among the children of men; for behold, he hath all power unto the fulfilling of all his WORDS. And thus it is. Amen."
    },
    { //
      "word": "burst",
      "hint": "1 Nephi 7",
      "verse": "1 Nephi 7:17",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/7.17",
      "verseText": "But it came to pass that I prayed unto the Lord, saying: O Lord, according to my faith which is in thee, wilt thou deliver me from the hands of my brethren; yea, even give me strength that I may BURST these bands with which I am bound."
    },
    { //
      "word": "times",
      "hint": "1 Nephi 10",
      "verse": "1 Nephi 10:19",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/10.19",
      "verseText": "For he that diligently seeketh shall find; and the mysteries of God shall be unfolded unto them, by the power of the Holy Ghost, as well in these TIMES as in TIMES of old, and as well in TIMES of old as in TIMES to come; wherefore, the course of the Lord is one eternal round."
    },
    { //
      "word": "pride",
      "hint": "1 Nephi 11",
      "verse": "1 Nephi 11:36",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/11",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/11.36",
      "verseText": "And it came to pass that I saw and bear record, that the great and spacious building was the PRIDE of the world; and it fell, and the fall thereof was exceedingly great. And the angel of the Lord spake unto me again, saying: Thus shall be the destruction of all nations, kindreds, tongues, and people, that shall fight against the twelve apostles of the Lamb."
    },
    { //
      "word": "above",
      "hint": "1 Nephi 11",
      "verse": "1 Nephi 11:22",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/11",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/11.22",
      "verseText": "And I answered him, saying: Yea, it is the love of God, which sheddeth itself abroad in the hearts of the children of men; wherefore, it is the most desirable ABOVE all things."
    },
    { //
      "word": "vapor",
      "hint": "1 Nephi 12",
      "verse": "1 Nephi 12:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/12",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/12.5",
      "verseText": "And it came to pass after I saw these things, I saw the VAPOR of darkness, that it passed from off the face of the earth; and behold, I saw multitudes who had not fallen because of the great and terrible judgments of the Lord.",
      "submissionText": "Thanks to Dallin Reece and Charlie Dennis for submitting this word!"
    },
    { //
      "word": "mists",
      "hint": "1 Nephi 12",
      "verse": "1 Nephi 12:17",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/12",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/12.17",
      "verseText": "And the MISTS of darkness are the temptations of the devil, which blindeth the eyes, and hardeneth the hearts of the children of men, and leadeth them away into broad roads, that they perish and are lost."
    },
    { //
      "word": "plain",
      "hint": "1 Nephi 13",
      "verse": "1 Nephi 13:40",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/13",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/13.40",
      "verseText": "...These last records, which thou hast seen among the Gentiles, shall establish the truth of the first, which are of the twelve apostles of the Lamb, and shall make known the PLAIN and precious things which have been taken away from them..."
    },
    { //
      "word": "armed",
      "hint": "1 Nephi 14",
      "verse": "1 Nephi 14:14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/14",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/14.14",
      "verseText": "And it came to pass that I, Nephi, beheld the power of the Lamb of God, that it descended upon the saints of the church of the Lamb, and upon the covenant people of the Lord, who were scattered upon all the face of the earth; and they were ARMED with righteousness and with the power of God in great glory."
    },
    { //
      "word": "fiery",
      "hint": "1 Nephi 15",
      "verse": "1 Nephi 15:24",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/15",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/15.24",
      "verseText": "And I said unto them that it was the word of God; and whoso would hearken unto the word of God, and would hold fast unto it, they would never perish; neither could the temptations and the FIERY darts of the adversary overpower them unto blindness, to lead them away to destruction."
    },
    { //
      "word": "round",
      "hint": "1 Nephi 16",
      "verse": "1 Nephi 16:10",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/16",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/16.10",
      "verseText": "And it came to pass that as my father arose in the morning, and went forth to the tent door, to his great astonishment he beheld upon the ground a ROUND ball of curious workmanship; and it was of fine brass. And within the ball were two spindles; and the one pointed the way whither we should go into the wilderness."
    },
    { //
      "word": "shock",
      "hint": "1 Nephi 17",
      "verse": "1 Nephi 17:53",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/17",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/17.53",
      "verseText": "And it came to pass that the Lord said unto me: Stretch forth thine hand again unto thy brethren, and they shall not wither before thee, but I will SHOCK them, saith the Lord, and this will I do, that they may know that I am the Lord their God."
    },
    { //
      "word": "storm",
      "hint": "1 Nephi 18",
      "verse": "1 Nephi 18:21",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/18",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/18.21",
      "verseText": "And it came to pass after they had loosed me, behold, I took the compass, and it did work whither I desired it. And it came to pass that I prayed unto the Lord; and after I had prayed the winds did cease, and the STORM did cease, and there was a great calm."
    },
    { //
      "word": "liken",
      "hint": "1 Nephi 19",
      "verse": "1 Nephi 19:23",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/19",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/19.23",
      "verseText": "And I did read many things unto them which were written in the books of Moses; but that I might more fully persuade them to believe in the Lord their Redeemer I did read unto them that which was written by the prophet Isaiah; for I did LIKEN all scriptures unto us, that it might be for our profit and learning."
    },
    { //
      "word": "defer",
      "hint": "1 Nephi 20",
      "verse": "1 Nephi 20:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/20",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/20.9",
      "verseText": "Nevertheless, for my name’s sake will I DEFER mine anger, and for my praise will I refrain from thee, that I cut thee not off."
    },
    { //
      "word": "palms",
      "hint": "1 Nephi 21",
      "verse": "1 Nephi 21:16",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/21",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/21.16",
      "verseText": "Behold, I have graven thee upon the PALMS of my hands; thy walls are continually before me."
    },
    { //
      "word": "sheep",
      "hint": "1 Nephi 22",
      "verse": "1 Nephi 22:25",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/22",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/22.25",
      "verseText": "And he gathereth his children from the four quarters of the earth; and he numbereth his SHEEP, and they know him; and there shall be one fold and one shepherd; and he shall feed his SHEEP, and in him they shall find pasture."
    },
    { //
      "word": "sakes",
      "hint": "2 Nephi 1",
      "verse": "2 Nephi 1:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/1.7",
      "verseText": "Wherefore, this land is consecrated unto him whom he shall bring. And if it so be that they shall serve him according to the commandments which he hath given, it shall be a land of liberty unto them; wherefore, they shall never be brought down into captivity; if so, it shall be because of iniquity; for if iniquity shall abound cursed shall be the land for their SAKES, but unto the righteous it shall be blessed forever."
    },
    { //
      "word": "awake",
      "hint": "2 Nephi 1",
      "verse": "2 Nephi 1:13",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/1.13",
      "verseText": "O that ye would AWAKE; AWAKE from a deep sleep, yea, even from the sleep of hell, and shake off the awful chains by which ye are bound, which are the chains which bind the children of men, that they are carried away captive down to the eternal gulf of misery and woe.",
      "submissionText": "Thanks to Bridger May for submitting this word!"
    },
    { //
      "word": "armor",
      "hint": "2 Nephi 1",
      "verse": "2 Nephi 1:23",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/1.23",
      "verseText": "Awake, my sons; put on the ARMOR of righteousness. Shake off the chains with which ye are bound, and come forth out of obscurity, and arise from the dust."
    },
    { //
      "word": "spent",
      "hint": "2 Nephi 2",
      "verse": "2 Nephi 2:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/2.3",
      "verseText": "Wherefore, thy soul shall be blessed, and thou shalt dwell safely with thy brother, Nephi; and thy days shall be SPENT in the service of thy God. Wherefore, I know that thou art redeemed, because of the righteousness of thy Redeemer; for thou hast beheld that in the fulness of time he cometh to bring salvation unto men."
    },
    { //
      "word": "acted",
      "hint": "2 Nephi 2",
      "verse": "2 Nephi 2:14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/2.14",
      "verseText": "And now, my sons, I speak unto you these things for your profit and learning; for there is a God, and he hath created all things, both the heavens and the earth, and all things that in them are, both things to act and things to be ACTED upon."
    },
    { //
      "word": "might",
      "hint": "2 Nephi 2",
      "verse": "2 Nephi 2:25",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/2.25",
      "verseText": "Adam fell that men MIGHT be; and men care, that they MIGHT have joy."
    },
    { //
      "word": "given",
      "hint": "2 Nephi 2",
      "verse": "2 Nephi 2:27",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/2.27",
      "verseText": "Wherefore, men are free according to the flesh; and all things are given them which are expedient unto man. And they are free to choose liberty and eternal life, through the great Mediator of all men, or to choose captivity and death, according to the captivity and power of the devil; for he seeketh that all men might be miserable like unto himself."
    },
    { //
      "word": "raise",
      "hint": "2 Nephi 3",
      "verse": "2 Nephi 3:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/3.6",
      "verseText": "For Joseph truly testified, saying: A seer shall the Lord my God RAISE up, who shall be a choice seer unto the fruit of my loins.",
      "submissionText": "Thanks to Scott Foster for submitting this word!"
    },
    { //
      "word": "loins",
      "hint": "2 Nephi 3",
      "verse": "2 Nephi 3:21",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/3.21",
      "verseText": "Because of their faith their words shall proceed forth out of my mouth unto their brethren who are the fruit of thy LOINS; and the weakness of their words will I make strong in their faith, unto the remembering of my covenant which I made unto thy fathers.",
      "submissionText": "Thanks to Hudson Coll for submitting this word!"
    },
    { //
      "word": "quake",
      "hint": "2 Nephi 4",
      "verse": "2 Nephi 4:22",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/4",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/4.22",
      "verseText": "He hath confounded mine enemies, unto the causing of them to QUAKE before me."
    },
    { //
      "word": "waste",
      "hint": "2 Nephi 4",
      "verse": "2 Nephi 4:26",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/4",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/4.26",
      "verseText": "O then, if I have seen so great things, if the Lord in his condescension unto the children of men hath visited men in so much mercy, why should my heart weep and my soul linger in the valley of sorrow, and my flesh WASTE away, and my strength slacken, because of mine afflictions?"
    },
    { //
      "word": "droop",
      "hint": "2 Nephi 4",
      "verse": "2 Nephi 4:28",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/4",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/4.28",
      "verseText": "Awake, my soul! No longer DROOP in sin. Rejoice, O my heart, and give place no more for the enemy of my soul."
    },
    { //
      "word": "labor",
      "hint": "2 Nephi 5",
      "verse": "2 Nephi 5:17",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/5.17",
      "verseText": "And it came to pass that I, Nephi, did cause my people to be industrious, and to LABOR with their hands."
    },
    { //
      "word": "lived",
      "hint": "2 Nephi 5",
      "verse": "2 Nephi 5:27",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/5.27",
      "verseText": "And it came to pass that we LIVED after the manner of happiness."
    },
    { //
      "word": "faces",
      "hint": "2 Nephi 6",
      "verse": "2 Nephi 6:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/6",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/6",
      "verseText": "And kings shall be thy nursing fathers, and their queens thy nursing mothers; they shall bow down to thee with their faces towards the earth, and lick up the dust of thy feet; and thou shalt know that I am the Lord; for they shall not be ashamed that wait for me."
    },
    { //
      "word": "stand",
      "hint": "2 Nephi 7",
      "verse": "2 Nephi 7:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/7.8",
      "verseText": "And the Lord is near, and he justifieth me. Who will contend with me? Let us STAND together. Who is mine adversary? Let him come near me, and I will smite him with the strength of my mouth."
    },
    { //
      "word": "grass",
      "hint": "2 Nephi 8",
      "verse": "2 Nephi 8:12",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/8.12",
      "verseText": "I am he; yea, I am he that comforteth you. Behold, who art thou, that thou shouldst be afraid of man, who shall die, and of the son of man, who shall be made like unto GRASS?"
    },
    { //
      "word": "needs",
      "hint": "2 Nephi 9",
      "verse": "2 Nephi 9:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/9",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/9.7",
      "verseText": "Wherefore, it must NEEDS be an infinite atonement—save it should be an infinite atonement this corruption could not put on incorruption. Wherefore, the first judgment which came upon man must NEEDS have remained to an endless duration. And if so, this flesh must have laid down to rot and to crumble to its mother earth, to rise no more."
    },
    { //
      "word": "money",
      "hint": "2 Nephi 9",
      "verse": "2 Nephi 9:50",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/9",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/9.50",
      "verseText": "Come, my brethren, every one that thirsteth, come ye to the waters; and he that hath no MONEY, come buy and eat; yea, come buy wine and milk without MONEY and without price."
    },
    { //
      "word": "isles",
      "hint": "2 Nephi 10",
      "verse": "2 Nephi 10:21",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/10.21",
      "verseText": "But great are the promises of the Lord unto them who are upon the ISLES of the sea; wherefore as it says ISLES, there must needs be more than this, and they are inhabited also by our brethren."
    },
    { //
      "word": "awful",
      "hint": "2 Nephi 9",
      "verse": "2 Nephi 9:26",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/9",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/9.26",
      "verseText": "For the atonement satisfieth the demands of his justice upon all those who have not the law given to them, that they are delivered from that AWFUL monster, death and hell, and the devil, and the lake of fire and brimstone, which is endless torment; and they are restored to that God who gave them breath, which is the Holy One of Israel."
    },
    { //
      "word": "their",
      "hint": "2 Nephi 11",
      "verse": "2 Nephi 11:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/11",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/11.8",
      "verseText": "And now I write some of the words of Isaiah, that whoso of my people shall see these words may lift up THEIR hearts and rejoice for all men. Now these are the words, and ye may liken them unto you and unto all men.",
      "submissionText": "Thanks to Ruth Moore for submitting this word!"
    },
    { //
      "word": "lofty",
      "hint": "2 Nephi 12",
      "verse": "2 Nephi 12:11",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/12",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/12.11",
      "verseText": "And it shall come to pass that the LOFTY looks of man shall be humbled, and the haughtiness of men shall be bowed down, and the Lord alone shall be exalted in that day."
    },
    { //
      "word": "plead",
      "hint": "2 Nephi 13",
      "verse": "2 Nephi 13:13",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/13",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/13.13",
      "verseText": "The Lord standeth up to PLEAD, and standeth to judge the people."
    },
    { //
      "word": "smoke",
      "hint": "2 Nephi 14",
      "verse": "2 Nephi 14:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/14",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/14.5",
      "verseText": "And the Lord will create upon every dwelling-place of mount Zion, and upon her assemblies, a cloud and SMOKE by day and the shining of a flaming fire by night; for upon all the glory of Zion shall be a defence."
    },
    { //
      "word": "speed",
      "hint": "2 Nephi 15",
      "verse": "2 Nephi 15:26",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/15",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/15.26",
      "verseText": "And he will lift up an ensign to the nations from far, and will hiss unto them from the end of the earth; and behold, they shall come with SPEED swiftly; none shall be weary nor stumble among them."
    },
    { //
      "word": "signs",
      "hint": "2 Nephi 18",
      "verse": "2 Nephi 18:18",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/18",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/18.18",
      "verseText": "Behold, I and the children whom the Lord hath given me are for SIGNS and for wonders in Israel from the Lord of Hosts, which dwelleth in Mount Zion."
    },
    { //
      "word": "still",
      "hint": "2 Nephi 19",
      "verse": "2 Nephi 19:21",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/19",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/19.21",
      "verseText": "Manasseh, Ephraim; and Ephraim, Manasseh; they together shall be against Judah. For all this his anger is not turned away, but his hand is stretched out STILL.",
      "submissionText": "Thanks to Mishell Higgins for submitting this word!"
    },
    { //
      "word": "boast",
      "hint": "2 Nephi 20",
      "verse": "2 Nephi 20:15",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/20",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/20.15",
      "verseText": "Shall the ax BOAST itself against him that heweth therewith? Shall the saw magnify itself against him that shaketh it? As if the rod should shake itself against them that lift it up, or as if the staff should lift up itself as if it were no wood!"
    },
    { //
      "word": "roots",
      "hint": "2 Nephi 21",
      "verse": "2 Nephi 21:1",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/21",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/21.1",
      "verseText": "And there shall come forth a rod out of the stem of Jesse, and a branch shall grow out of his ROOTS."
    },
    { //
      "word": "wells",
      "hint": "2 Nephi 22",
      "verse": "2 Nephi 22:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/22",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/22.3",
      "verseText": "Therefore, with joy shall ye draw water out of the WELLS of salvation."
    },
    { //
      "word": "stars",
      "hint": "2 Nephi 23",
      "verse": "2 Nephi 23:10",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/23",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/23.10",
      "verseText": "For the STARS of heaven and the constellations thereof shall not give their light; the sun shall be darkened in his going forth, and the moon shall not cause her light to shine."
    },
    { //
      "word": "quiet",
      "hint": "2 Nephi 24",
      "verse": "2 Nephi 24:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/24",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/24.7",
      "verseText": "The whole earth is at rest, and is QUIET: they break forth into singing."
    },
    { //
      "word": "worth",
      "hint": "2 Nephi 25",
      "verse": "2 Nephi 25:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/25",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/25.8",
      "verseText": "Wherefore, they are of WORTH unto the children of men, and he that supposeth that they are not, unto them will I speak particularly, and confine the words unto mine own people; for I know that they shall be of great WORTH unto them in the last days; for in that day shall they understand them; wherefore, for their good have I written them."
    },
    { //
      "word": "write",
      "hint": "2 Nephi 25",
      "verse": "2 Nephi 25:26",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/25",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/25.26",
      "verseText": "And we talk of Christ, we rejoice in Christ, we preach of Christ, we prophesy of Christ, and we WRITE according to our prophecies, that our children may know to what source they may look for a remission of their sins."
    },
    { //
      "word": "three",
      "hint": "2 Nephi 26",
      "verse": "2 Nephi 26:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/26",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/26.9",
      "verseText": "But the Son of Righteousness shall appear unto them; and he shall heal them, and they shall have peace with him, until THREE generations shall have passed away, and many of the fourth generation shall have passed away in righteousness.",
      "submissionText": "Thanks to Wayne Carroll for submitting this word!"
    },
    { //
      "word": "among",
      "hint": "2 Nephi 27",
      "verse": "2 Nephi 27:26",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/27",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/27.26",
      "verseText": "Therefore, I will proceed to do a marvelous work AMONG this people, yea, a marvelous work and a wonder, for the wisdom of their wise and learned shall perish, and the understanding of their prudent shall be hid."
    },
    { //
      "word": "sandy",
      "hint": "2 Nephi 28",
      "verse": "2 Nephi 28:28",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/28",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/28.28",
      "verseText": "And in fine, wo unto all those who tremble, and are angry because of the truth of God! For behold, he that is built upon the rock receiveth it with gladness; and he that is built upon a SANDY foundation trembleth lest he shall fall."
    },
    { //
      "word": "bible",
      "hint": "2 Nephi 29",
      "verse": "2 Nephi 29:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/29",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/29.3",
      "verseText": "And because my words shall hiss forth—many of the Gentiles shall say: A BIBLE! A BIBLE! We have got a BIBLE, and there cannot be any more BIBLE."
    },
    { //
      "word": "known",
      "hint": "2 Nephi 30",
      "verse": "2 Nephi 30:16",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/30",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/30.16",
      "verseText": "Wherefore, the things of all nations shall be made KNOWN; yea, all things shall be made KNOWN unto the children of men."
    },
    { //
      "word": "south",
      "hint": "2 Nephi 29",
      "verse": "2 Nephi 29:11",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/29",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/29.11",
      "verseText": "For I command all men, both in the east and in the west, and in the north, and in the SOUTH, and in the islands of the sea, that they shall write the words which I speak unto them; for out of the books which shall be written I will judge the world, every man according to their works, according to that which is written."
    },
    { //
      "word": "world",
      "hint": "2 Nephi 26",
      "verse": "2 Nephi 26:24",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/26",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/26.24",
      "verseText": "For he doeth that which is good among the children of men; and he doeth nothing save it be for the benefit of the WORLD; for he loveth the WORLD, even that he layeth down his own life that he may draw all men unto him."
    },
    { //
      "word": "water",
      "hint": "2 Nephi 31",
      "verse": "2 Nephi 31.5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/31",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/31.5",
      "verseText": "And now, if the Lamb of God, he being holy, should have need to be baptized by WATER, to fulfil all righteousness, O then, how much more need have we, being unholy, to be baptized, yea, even by WATER!"
    },
    { //
      "word": "feast",
      "hint": "2 Nephi 32",
      "verse": "2 Nephi 32.3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/32",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/32.3",
      "verseText": "Angels speak by the power of the Holy Ghost; wherefore, they speak the words of Christ. Wherefore, I said unto you, FEAST upon the words of Christ; for behold, the words of Christ will tell you all things what ye should do."
    },
    {
      "word": "after",
      "hint": "2 Nephi 31",
      "verse": "2 Nephi 31.19",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/31",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/31.19",
      "verseText": "And now, my beloved brethren, AFTER ye have gotten into this strait and narrow path, I would ask if all is done? Behold, I say unto you, Nay; for ye have not come thus far save it were by the word of Christ with unshaken faith in him, relying wholly upon the merits of him who is mighty to save."
    },
    {
      "word": "faint",
      "hint": "2 Nephi 32",
      "verse": "2 Nephi 32:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/32",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/32.9",
      "verseText": "But behold, I say unto you that ye must pray always, and not FAINT; that ye must not perform any thing unto the Lord save in the first place ye shall pray unto the Father in the name of Christ, that he will consecrate thy performance unto thee, that thy performance may be for the welfare of thy soul."
    },
    {
      "word": "glory",
      "hint": "2 Nephi 33",
      "verse": "2 Nephi 33:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/33",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/33.6",
      "verseText": "I GLORY in plainness; I GLORY in truth; I GLORY in my Jesus, for he hath redeemed my soul from hell."
    },
    {
      "word": "teach",
      "hint": "2 Nephi 33",
      "verse": "2 Nephi 33:10",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/33",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/33.10",
      "verseText": "And now, my beloved brethren... if ye believe not in these words believe in Christ. And if ye shall believe in Christ ye will believe in these words, for they are they words of Christ, and he hath given them unto me; and they TEACH all men that they should do good."
    },
    {
      "word": "press",
      "hint": "2 Nephi 31",
      "verse": "2 Nephi 31:20",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/31",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/31:20",
      "verseText": "Wherefore, ye must PRESS forward with a steadfastness in Christ, having a perfect brightness of hope, and a love of God and of all men. Wherefore, if ye shall PRESS forward, feasting upon the word of Christ, and endure to the end, behold, thus saith the Father: Ye shall have eternal life."
    },
    {
      "word": "slain",
      "hint": "Mosiah 15",
      "verse": "Mosiah 15:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/15",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/15.7",
      "verseText": "Yea, even so he shall be led, crucified, and SLAIN, the flesh becoming subject even unto death, the will of the Son being swallowed up in the will of the Father."
    },
    {
      "word": "bands",
      "hint": "Alma 11",
      "verse": "Alma 11:42",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/11",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/11.42",
      "verseText": "Now, there is a death which is called a temporal death; and the death of Christ shall loose the BANDS of this temporal death, that all shall be raised from this temporal death."
    },
    {
      "word": "joint",
      "hint": "Alma 40",
      "verse": "Alma 40:23",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/40",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/40.23",
      "verseText": "The soul shall be restored to the body, and the body to the soul; yea, and every limb and JOINT shall be restored to its body; yea, even a hair of the head shall not be lost; but all things shall be restored to their proper and perfect frame."
    },
    {
      "word": "agony",
      "hint": "Luke 22",
      "verse": "Luke 22:44",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/22",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/22.44",
      "verseText": "And being in an AGONY he prayed more earnestly: and his sweat was as it were great drops of blood falling down to the ground."
    },
    {
      "word": "nails",
      "hint": "3 Nephi 11",
      "verse": "3 Nephi 11:14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/11",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/11.14",
      "verseText": "Arise and come forth unto me, that ye may thrust your hands into my side, and also that ye may feel the prints of the NAILS in my hands and in my feet, that ye may know that I am the God of Israel, and the God of the whole earth, and have been  for the sins of the world."
    },
    {
      "word": "crown",
      "hint": "Matthew 27",
      "verse": "Matthew 27:29",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/27",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/27.29",
      "verseText": "And when they had plaited a CROWN of thorns, they put it upon his head, and a reed in his right hand: and they bowed the knee before him, and mocked him, saying, Hail, King of the Jews!"
    },
    {
      "word": "risen",
      "hint": "Luke 24",
      "verse": "Luke 24:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/24",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/24.6",
      "verseText": "He is not here, but is RISEN: remember how he spake unto you when he was yet in Galilee"
    },
    {
      "word": "heads",
      "hint": "Jacob 1",
      "verse": "Jacob 1:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/1.4",
      "verseText": "And if there were preaching which was sacred, or revelation which was great, or prophesying, that I should engraven the HEADS of them upon these plates, and touch upon them as much as it were possible, for Christ’s sake, and for the sake of our people."
    },
    {
      "word": "shame",
      "hint": "Jacob 1",
      "verse": "Jacob 1:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/1.8",
      "verseText": "Wherefore, we would to God that we could persuade all men not to rebel against God, to provoke him to anger, but that all men would believe in Christ, and view his death, and suffer his cross and bear the SHAME of the world; wherefore, I, Jacob, take it upon me to fulfil the commandment of my brother Nephi."
    },
    {
      "word": "necks",
      "hint": "Jacob 2",
      "verse": "Jacob 2:13",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/2.13",
      "verseText": "And the hand of providence hath smiled upon you most pleasingly, that you have obtained many riches; and because some of you have obtained more abundantly than that of your brethren ye are lifted up in the pride of your hearts, and wear stiff NECKS and high heads because of the costliness of your apparel, and persecute your brethren because ye suppose that ye are better than they."
    },
    {
      "word": "hosts",
      "hint": "Jacob 2",
      "verse": "Jacob 2:30",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/2.30",
      "verseText": "For if I will, saith the Lord of HOSTS, raise up seed unto me, I will command my people; otherwise they shall hearken unto these things."
    },
    {
      "word": "minds",
      "hint": "Jacob 3",
      "verse": "Jacob 3:2",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/3.2",
      "verseText": "O all ye that are pure in heart, lift up your heads and receive the pleasing word of God, and feast upon his love; for ye may, if your MINDS are firm, forever."
    },
    {
      "word": "wives",
      "hint": "Jacob 3",
      "verse": "Jacob 3:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/3.7",
      "verseText": "Behold, their husbands love their WIVES, and their WIVES love their husbands; and their husbands and their WIVES love their children; and their unbelief and their hatred towards you is because of the iniquity of their fathers; wherefore, how much better are you than they, in the sight of your great Creator?"
    },
    {
      "word": "works",
      "hint": "Jacob 4",
      "verse": "Jacob 4:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/4",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/4.8",
      "verseText": "Behold, great and marvelous are the WORKS of the Lord. How unsearchable are the depths of the mysteries of him; and it is impossible that man should find out all his ways. And no man knoweth of his ways save it be revealed unto him; wherefore, brethren, despise not the revelations of God."
    },
    {
      "word": "decay",
      "hint": "Jacob 5",
      "verse": "Jacob 5:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/5.3",
      "verseText": "For behold, thus saith the Lord, I will liken thee, O house of Israel, like unto a tame olive tree, which a man took and nourished in his vineyard; and it grew, and waxed old, and began to DECAY."
    },
    {
      "word": "graft",
      "hint": "Jacob 5",
      "verse": "Jacob 5:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/5.9",
      "verseText": "Take thou the branches of the wild olive tree, and GRAFT them in, in the stead thereof; and these which I have plucked off I will cast into the fire and burn them, that they may not cumber the ground of my vineyard."
    },
    {
      "word": "spare",
      "hint": "Jacob 5",
      "verse": "Jacob 5:50",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/5.50",
      "verseText": "And the Lord said: Yea, I will SPARE it a little longer, for it grieveth me that I should lose the trees of my vineyard."
    },
    {
      "word": "prune",
      "hint": "Jacob 5",
      "verse": "Jacob 5:69",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/5.69",
      "verseText": "And the bad shall be cast away, yea, even out of all the land of my vineyard; for behold, only this once will I PRUNE my vineyard."
    },
    {
      "word": "hands",
      "hint": "Jacob 6",
      "verse": "Jacob 6:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/6",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/6.4",
      "verseText": "And how merciful is our God unto us, for he remembereth the house of Israel, both roots and branches; and he stretches forth his HANDS unto them all the day long; and they are a stiffnecked and a gainsaying people; but as many as will not harden their hearts shall be saved in the kingdom of God."
    },
    {
      "word": "truly",
      "hint": "Jacob 7",
      "verse": "Jacob 7:11",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/7.11",
      "verseText": "And I said unto him: Then ye do not understand them; for they TRULY testify of Christ. Behold, I say unto you that none of the prophets have written, nor prophesied, save they have spoken concerning this Christ."
    },
    {
      "word": "adieu",
      "hint": "Jacob 7",
      "verse": "Jacob 7:27",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/7.27",
      "verseText": "And I, Jacob, saw that I must soon go down to my grave; wherefore, I said unto my son Enos: Take these plates. And I told him the things which my brother Nephi had commanded me, and he promised obedience unto the commands. And I make an end of my writing upon these plates, which writing has been small; and to the reader I bid farewell, hoping that many of my brethren may read my words. Brethren, ADIEU."
    },
    {
      "word": "cried",
      "hint": "Enos 1",
      "verse": "Enos 1:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/enos/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/enos/1.4",
      "verseText": "And my soul hungered; and I kneeled down before my Maker, and I CRIED unto him in mighty prayer and supplication for mine own soul; and all the day long did I cry unto him; yea, and when the night came I did still raise my voice high that it reached the heavens.",
      "submissionText": "Thanks to Jennifer Johnson for submitting this word!"
    },
    {
      "word": "bring",
      "hint": "Enos 1",
      "verse": "Enos 1:16",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/enos/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/enos/1.16",
      "verseText": "And I had faith, and I did cry unto God that he would preserve the records; and he covenanted with me that he would BRING them forth unto the Lamanites in his own due time."
    },
    {
      "word": "prick",
      "hint": "Jarom 1",
      "verse": "Jarom 1:12",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jarom/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/jarom/1.12",
      "verseText": "And it came to pass that by so doing they kept them from being destroyed upon the face of the land; for they did PRICK their hearts with the word, continually stirring them up unto repentance.",
      "submissionText": "Thanks to Sarah Prusmack for submitting this word!"
    },
    {
      "word": "unite",
      "hint": "Omni 1",
      "verse": "Omni 1:17",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/omni/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/omni/1.17",
      "verseText": "And it came to pass that the people of Zarahemla, and of Mosiah, did UNITE together; and Mosiah was appointed to be their king."
    },
    {
      "word": "grant",
      "hint": "Words of Mormon 1",
      "verse": "Words of Mormon 1:2",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/w-of-m/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/w-of-m/1.2",
      "verseText": "And it is many hundred years after the coming of Christ that I deliver these records into the hands of my son; and it supposeth me that he will witness the entire destruction of my people. But may God GRANT that he may survive them, that he may write somewhat concerning them, and somewhat concerning Christ, that perhaps some day it may profit them."
    },
    {
      "word": "false",
      "hint": "Words of Mormon 1",
      "verse": "Words of Mormon 1:15",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/w-of-m/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/w-of-m/1.15",
      "verseText": "And it came to pass that after there had been FALSE Christs, and their mouths had been shut, and they punished according to their crimes;"
    },
    {
      "word": "offer",
      "hint": "Omni 1",
      "verse": "Omni 1:26",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/omni/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/omni/1.26",
      "verseText": "And now, my beloved brethren, I would that ye should come unto Christ, who is the Holy One of Israel, and partake of his salvation, and the power of his redemption. Yea, come unto him, and offer your whole souls as an offering unto him, and continue in fasting and praying, and endure to the end; and as the Lord liveth ye will be saved.",
      "submissionText": "Thanks to Xyleene Mac Donald for submitting this word!"
    },
    {
      "word": "would",
      "hint": "Mosiah 1",
      "verse": "Mosiah 1:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/1.7",
      "verseText": "And now, my sons, I would that ye should remember to search them diligently, that ye may profit thereby; and I WOULD that ye should keep the commandments of God, that ye may prosper in the land according to the promises which the Lord made unto our fathers."
    },
    {
      "word": "speak",
      "hint": "Mosiah 1",
      "verse": "Mosiah 1:18",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/1.18",
      "verseText": "And now, it came to pass that Mosiah went and did as his father had commanded him, and proclaimed unto all the people who were in the land of Zarahemla that thereby they might gather themselves together, to go up to the temple to hear the words which his father should SPEAK unto them."
    },
    {
      "word": "learn",
      "hint": "Mosiah 2",
      "verse": "Mosiah 2:17",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/2.17",
      "verseText": "And behold, I tell you these things that ye may LEARN wisdom; that ye may LEARN that when ye are in the service of your fellow beings ye are only in the service of your God."
    },
    {
      "word": "happy",
      "hint": "Mosiah 2",
      "verse": "Mosiah 2:41",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/2:41",
      "verseText": "And moreover, I would desire that ye should consider on the blessed and HAPPY state of those that keep the commandments of God. For behold, they are blessed in all things, both temporal and spiritual; and if they hold out faithful to the end they are received into heaven, that thereby they may dwell with God in a state of never-ending happiness. O remember, remember that these things are true; for the Lord God hath spoken it."
    },
    {
      "word": "third",
      "hint": "Mosiah 3",
      "verse": "Mosiah 3:10",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/3.10",
      "verseText": "And he shall rise the THIRD day from the dead; and behold, he standeth to judge the world; and behold, all these things are done that a righteous judgment might come upon the children of men."
    },
    {
      "word": "types",
      "hint": "Mosiah 3",
      "verse": "Mosiah 3:15",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/3.15",
      "verseText": "And many signs, and wonders, and TYPES, and shadows showed he unto them, concerning his coming; and also holy prophets spake unto them concerning his coming; and yet they hardened their hearts, and understood not that the law of Moses availeth nothing except it were through the atonement of his blood."
    },
    {
      "word": "saint",
      "hint": "Mosiah 3",
      "verse": "Mosiah 3:19",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/3.19",
      "verseText": "For the natural man is an enemy to God, and has been from the fall of Adam, and will be, forever and ever, unless he yields to the enticings of the Holy Spirit, and putteth off the natural man and becometh a SAINT through the atonement of Christ the Lord, and becometh as a child, submissive, meek, humble, patient, full of love, willing to submit to all things which the Lord seeth fit to inflict upon him, even as a child doth submit to his father."
    },
    {
      "word": "means",
      "hint": "Mosiah 4",
      "verse": "Mosiah 4:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/4",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/4.8",
      "verseText": "And this is the MEANS whereby salvation cometh. And there is none other salvation save this which hath been spoken of; neither are there any conditions whereby man can be saved except the conditions which I have told you."
    },
    {
      "word": "lives",
      "hint": "Mosiah 4",
      "verse": "Mosiah 4:21",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/4",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/4.21",
      "verseText": "And now, if God, who has created you, on whom you are dependent for your LIVES and for all that ye have and are, doth grant unto you whatsoever ye ask that is right, in faith, believing that ye shall receive, O then, how ye ought to impart of the substance that ye have one to another."
    },
    {
      "word": "found",
      "hint": "Mosiah 5",
      "verse": "Mosiah 5:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/5.9",
      "verseText": "And it shall come to pass that whosoever doeth this shall be FOUND at the right hand of God, for he shall know the name by which he is called; for he shall be called by the name of Christ."
    },
    {
      "word": "earth",
      "hint": "Mosiah 5",
      "verse": "Mosiah 5:15",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/5.15",
      "verseText": "Therefore, I would that ye should be steadfast and immovable, always abounding in good works, that Christ, the Lord God Omnipotent, may seal you his, that you may be brought to heaven, that ye may have everlasting salvation and eternal life, through the wisdom, and power, and justice, and mercy of him who created all things, in heaven and in earth, who is God above all. Amen."
    },
    {
      "word": "stead",
      "hint": "Mosiah 6",
      "verse": "Mosiah 6:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/6",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/6.4",
      "verseText": "And Mosiah began to reign in his father’s STEAD. And he began to reign in the thirtieth year of his age, making in the whole, about four hundred and seventy-six years from the time that Lehi left Jerusalem."
    },
    {
      "word": "years",
      "hint": "Mosiah 6",
      "verse": "Mosiah 6:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/6",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/6.7",
      "verseText": "And king Mosiah did cause his people that they should till the earth. And he also, himself, did till the earth, that thereby he might anot become burdensome to his people, that he might do according to that which his father had done in all things. And there was no contention among all his people for the space of three YEARS."
    },
    {
      "word": "order",
      "hint": "Mosiah 4",
      "verse": "Mosiah 4:27",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/4",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/4.27",
      "verseText": "And see that all these things are done in wisdom and ORDER; for it is not requisite that a man should run faster than he has strength. And again, it is expedient that he should be diligent, that thereby he might win the prize; therefore, all things must be done in ORDER."
    },
    {
      "word": "taxed",
      "hint": "Mosiah 7",
      "verse": "Mosiah 7:15",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/7.15",
      "verseText": "For behold, we are in bondage to the Lamanites, and are TAXED with a tax which is grievous to be borne. And now, behold, our brethren will deliver us out of our bondage, or out of the hands of the Lamanites, and we will be their slaves; for it is better that we be slaves to the Nephites than to pay tribute to the king of the Lamanites."
    },
    {
      "word": "block",
      "hint": "Mosiah 7",
      "verse": "Mosiah 7:29",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/7.29",
      "verseText": "For behold, the Lord hath said: I will not succor my people in the day of their transgression; but I will hedge up their ways that they prosper not; and their doings shall be as a stumbling BLOCK before them."
    },
    {
      "word": "bones",
      "hint": "Mosiah 8",
      "verse": "Mosiah 8:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/8.8",
      "verseText": "And they were lost in the wilderness for the space of many days, yet they were diligent, and found not the land of Zarahemla but returned to this land, having traveled in a land among many waters, having discovered a land which was covered with BONES of men, and of beasts, and was also covered with ruins of buildings of every kind, having discovered a land which had been peopled with a people who were as numerous as the hosts of Israel."
    },
    {
      "word": "great",
      "hint": "Mosiah 8",
      "verse": "Mosiah 8:18",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/8.18",
      "verseText": "Thus God has provided a means that man, through faith, might work mighty miracles; therefore he becometh a GREAT benefit to his fellow beings."
    },
    {
      "word": "seeds",
      "hint": "Mosiah 9",
      "verse": "Mosiah 9:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/9",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/9.9",
      "verseText": "And we began to till the ground, yea, even with all manner of SEEDS, with SEEDS of corn, and of wheat, and of barley, and with neas, and with sheum, and with SEEDS of all manner of fruits; and we did begin to multiply and prosper in the land."
    },
    {
      "word": "cloth",
      "hint": "Mosiah 10",
      "verse": "Mosiah 10:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/10.5",
      "verseText": "And I did cause that the women should spin, and toil, and work, and work all manner of fine linen, yea, and CLOTH of every kind, that we might clothe our nakedness; and thus we did prosper in the land—thus we did have continual peace in the land for the space of twenty and two years."
    },
    {
      "word": "bless",
      "hint": "Mosiah 10",
      "verse": "Mosiah 10:22",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/10.22",
      "verseText": "And now I, being old, did confer the kingdom upon one of my sons; therefore, I say no more. And may the Lord BLESS my people. Amen."
    },
    {
      "word": "fifth",
      "hint": "Mosiah 11",
      "verse": "Mosiah 11:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/11",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/11.3",
      "verseText": "And he laid a tax of one FIFTH part of all they possessed, a FIFTH part of their gold and of their silver, and a FIFTH part of their ziff, and of their copper, and of their brass and their iron; and a FIFTH part of their fatlings; and also a FIFTH part of all their grain."
    },
    {
      "word": "stalk",
      "hint": "Mosiah 12",
      "verse": "Mosiah 12:11",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/12",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/12.11",
      "verseText": "And again, he saith that thou shalt be as a STALK, even as a dry STALK of the field, which is run over by the beasts and trodden under foot."
    },
    {
      "word": "shone",
      "hint": "Mosiah 13",
      "verse": "Mosiah 13:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/13",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/13.5",
      "verseText": "Now it came to pass after Abinadi had spoken these words that the people of king Noah durst not lay their hands on him, for the Spirit of the Lord was upon him; and his face SHONE with exceeding luster, even as Moses’ did while in the mount of Sinai, while speaking with the Lord."
    },
    {
      "word": "borne",
      "hint": "Mosiah 14",
      "verse": "Mosiah 14:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/14",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/14.4",
      "verseText": "Surely he has BORNE our griefs, and carried our sorrows; yet we did esteem him stricken, smitten of God, and afflicted."
    },
    {
      "word": "bands",
      "hint": "Mosiah 15",
      "verse": "Mosiah 15:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/15",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/15.8",
      "verseText": "And thus God breaketh the BANDS of death, having gained the victory over death; giving the Son power to make intercession for the children of men."
    },
    {
      "word": "light",
      "hint": "Mosiah 16",
      "verse": "Mosiah 16:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/16",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/16.9",
      "verseText": "He is the LIGHT and the life of the world; yea, a LIGHT that is endless, that can never be darkened; yea, and also a life which is endless, that there can be no more death."
    },
    {
      "word": "death",
      "hint": "Mosiah 17",
      "verse": "Mosiah 17:20",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/17",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/17.20",
      "verseText": "And now, when Abinadi had said these words, he fell, having suffered DEATH by fire; yea, having been put to DEATH because he would not deny the commandments of God, having sealed the truth of his words by his DEATH."
    },
    {
      "word": "mourn",
      "hint": "Mosiah 18",
      "verse": "Mosiah 18:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/18",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/18.9",
      "verseText": "Yea, and are willing to MOURN with those that MOURN; yea, and comfort those that stand in need of comfort, and to stand as witnesses of God at all times and in all things, and in all places that ye may be in, even until death, that ye may be redeemed of God, and be numbered with those of the first resurrection, that ye may have eternal life—"
    },
    {
      "word": "leave",
      "hint": "Mosiah 19",
      "verse": "Mosiah 19:12",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/19",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/19.12",
      "verseText": "Now there were many that would not LEAVE them, but had rather stay and perish with them. And the rest left their wives and their children and fled."
    },
    {
      "word": "drive",
      "hint": "Mosiah 20",
      "verse": "Mosiah 20:11",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/20",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/20.11",
      "verseText": "And it came to pass that the people of Limhi began to DRIVE the Lamanites before them; yet they were not half so numerous as the Lamanites. But they fought for their lives, and for their wives, and for their children; therefore they exerted themselves and like dragons did they fight."
    },
    {
      "word": "study",
      "hint": "Mosiah 21",
      "verse": "Mosiah 21:36",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/21",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/21.36",
      "verseText": "And now all the STUDY of Ammon and his people, and king Limhi and his people, was to deliver themselves out of the hands of the Lamanites and from bondage."
    },
    {
      "word": "night",
      "hint": "Mosiah 22",
      "verse": "Mosiah 22:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/22",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/22.6",
      "verseText": "Behold the back pass, through the back wall, on the back side of the city. The Lamanites, or the guards of the Lamanites, by NIGHT are drunken; therefore let us send a proclamation among all this people that they gather together their flocks and herds, that they may drive them into the wilderness by NIGHT."
    },
    {
      "word": "flesh",
      "hint": "Mosiah 23",
      "verse": "Mosiah 23:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/23",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/23.7",
      "verseText": "But he said unto them: Behold, it is not expedient that we should have a king; for thus saith the Lord: Ye shall not esteem one FLESH above another, or one man shall not think himself above another; therefore I say unto you it is not expedient that ye should have a king."
    },
    {
      "word": "sleep",
      "hint": "Mosiah 24",
      "verse": "Mosiah 24:19",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/24",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/24.19",
      "verseText": "And in the morning the Lord caused a deep SLEEP to come upon the Lamanites, yea, and all their task-masters were in a profound SLEEP."
    },
    {
      "word": "again",
      "hint": "Mosiah 25",
      "verse": "Mosiah 25:10",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/25",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/25.10",
      "verseText": "And AGAIN, when they thought of the immediate goodness of God, and his power in delivering Alma and his brethren out of the hands of the Lamanites and of bondage, they did raise their voices and give thanks to God."
    },
    {
      "word": "alone",
      "hint": "Mosiah 26",
      "verse": "Mosiah 26:16",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/26",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/26.16",
      "verseText": "And blessed are they because of their exceeding faith in the words ALONE which thou hast spoken unto them."
    },
    {
      "word": "place",
      "hint": "Mosiah 26",
      "verse": "Mosiah 26:24",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/26",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/26.24",
      "verseText": "For behold, in my name are they called; and if they know me they shall come forth, and shall have a PLACE eternally at my right hand."
    },
    {
      "word": "north",
      "hint": "Mosiah 27",
      "verse": "Mosiah 27:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/27",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/27.6",
      "verseText": "And there began to be much peace again in the land; and the people began to be very numerous, and began to scatter abroad upon the face of the earth, yea, on the NORTH and on the south, on the east and on the west, building large cities and villages in all quarters of the land."
    },
    {
      "word": "abyss",
      "hint": "Mosiah 27",
      "verse": "Mosiah 27:29",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/27",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/27.29",
      "verseText": "My soul hath been redeemed from the gall of bitterness and bonds of iniquity. I was in the darkest ABYSS; but now I behold the marvelous light of God. My soul was racked with eternal torment; but I am snatched, and my soul is pained no more."
    },
    {
      "word": "human",
      "hint": "Mosiah 28",
      "verse": "Mosiah 28:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/28",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/28.3",
      "verseText": "Now they were desirous that salvation should be declared to every creature, for they could not bear that any HUMAN soul should perish; yea, even the very thoughts that any soul should endure endless torment did cause them to quake and tremble."
    },
    {
      "word": "tower",
      "hint": "Mosiah 28",
      "verse": "Mosiah 28:17",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/28",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/28.17",
      "verseText": "Now after Mosiah had finished translating these records, behold, it gave an account of the people who were destroyed, from the time that they were destroyed back to the building of the great TOWER, at the time the Lord confounded the language of the people and they were scattered abroad upon the face of all the earth, yea, and even from that time back until the creation of Adam."
    },
    {
      "word": "crime",
      "hint": "Mosiah 29",
      "verse": "Mosiah 29:15",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/29",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/29.15",
      "verseText": "And whosoever has committed iniquity, him have I punished according to the CRIME which he has committed, according to the law which has been given to us by our fathers."
    },
    {
      "word": "chief",
      "hint": "Mosiah 29",
      "verse": "Mosiah 29:42",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/29",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/mosiah/29.42",
      "verseText": "And it came to pass that Alma was appointed to be the first CHIEF judge, he being also the high priest, his father having conferred the office upon him, and having given him the charge concerning all the affairs of the church."
    },
    {
      "word": "wroth",
      "hint": "Alma 1",
      "verse": "Alma 1:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/1.9",
      "verseText": "Now, because Gideon withstood him with the words of God he was WROTH with Gideon, and drew his sword and began to smite him. Now Gideon being stricken with many years, therefore he was not able to withstand his blows, therefore he was slain by the sword."
    },
    {
      "word": "favor",
      "hint": "Alma 2",
      "verse": "Alma 2:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/2.8",
      "verseText": "Now this did cause much joy in the hearts of those who were against him; but Amlici did stir up those who were in his FAVOR to anger against those who were not in his favor."
    },
    {
      "word": "cries",
      "hint": "Alma 2",
      "verse": "Alma 2:28",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/2.28",
      "verseText": "Nevertheless, the Nephites being strengthened by the hand of the Lord, having prayed mightily to him that he would deliver them out of the hands of their enemies, therefore the Lord did hear their CRIES, and did strengthen them, and the Lamanites and the Amlicites did fall before them."
    },
    {
      "word": "wages",
      "hint": "Alma 3",
      "verse": "Alma 3:27",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/3.27",
      "verseText": "For every man receiveth WAGES of him whom he listeth to obey, and this according to the words of the spirit of prophecy; therefore let it be according to the truth. And thus endeth the fifth year of the reign of the judges."
    },
    {
      "word": "fully",
      "hint": "Alma 4",
      "verse": "Alma 4:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/4",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/4.4",
      "verseText": "And they began to establish the church more FULLY; yea, and many were baptized in the waters of Sidon and were joined to the church of God; yea, they were baptized by the hand of Alma, who had been consecrated the high priest over the people of the church, by the hand of his father Alma."
    },
    {
      "word": "image",
      "hint": "Alma 5",
      "verse": "Alma 5:14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/5.14",
      "verseText": "And now behold, I ask of you, my brethren of the church, have ye spiritually been born of God? Have ye received his IMAGE in your countenances? Have ye experienced this mighty change in your hearts?"
    },
    {
      "word": "known",
      "hint": "Alma 5",
      "verse": "Alma 5:46",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/5.46",
      "verseText": "Behold, I say unto you they are made KNOWN unto me by the Holy Spirit of God. Behold, I have fasted and prayed many days that I might know these things of myself. And now I do know of myself that they are true; for the Lord God hath made them manifest unto me by his Holy Spirit; and this is the spirit of revelation which is in me."
    },
    {
      "word": "watch",
      "hint": "Alma 6",
      "verse": "Alma 6:1",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/6",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/6.1",
      "verseText": "And now it came to pass that after Alma had made an end of speaking unto the people of the church, which was established in the city of Zarahemla, he ordained priests and elders, by laying on his hands according to the order of God, to preside and WATCH over the church."
    },
    {
      "word": "river",
      "hint": "Alma 6",
      "verse": "Alma 6:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/6",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/6.7",
      "verseText": "And now it came to pass that when Alma had made these regulations he departed from them, yea, from the church which was in the city of Zarahemla, and went over upon the east of the RIVER Sidon, into the valley of Gideon, there having been a city built, which was called the city of Gideon, which was in the valley that was called Gideon, being called after the man who was slain by the hand of Nehor with the sword."
    },
    {
      "word": "pains",
      "hint": "Alma 7",
      "verse": "Alma 7:11",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/7.11",
      "verseText": "And he shall go forth, suffering PAINS and afflictions and temptations of every kind; and this that the word might be fulfilled which saith he will take upon him the PAINS and the sicknesses of his people."
    },
    {
      "word": "paths",
      "hint": "Alma 7",
      "verse": "Alma 7:19",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/7.19",
      "verseText": "For I perceive that ye are in the PATHS of righteousness; I perceive that ye are in the path which leads to the kingdom of God; yea, I perceive that ye are making his PATHS straight.",
      "submissionText": "Thanks to Zack Stewart for submitting this word!"
    },
    {
      "word": "lands",
      "hint": "Alma 7",
      "verse": "Alma 7:27",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/7.27",
      "verseText": "And now, may the peace of God rest upon you, and upon your houses and LANDS, and upon your flocks and herds, and all that you possess, your women and your children, according to your faith and good works, from this time forth and forever. And thus I have spoken. Amen."
    },
    {
      "word": "grant",
      "hint": "Alma 8",
      "verse": "Alma 8:10",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/8.10",
      "verseText": "Nevertheless Alma labored much in the spirit, wrestling with God in mighty prayer, that he would pour out his Spirit upon the people who were in the city; that he would also GRANT that he might baptize them unto repentance."
    },
    {
      "word": "gross",
      "hint": "Alma 8",
      "verse": "Alma 8:28",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/8.28",
      "verseText": "And it came to pass that the people did wax more GROSS in their iniquities."
    },
    {
      "word": "utter",
      "hint": "Alma 9",
      "verse": "Alma 9:18",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/9",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/9.18",
      "verseText": "But behold, I say unto you that if ye persist in your wickedness that your days shall not be prolonged in the land, for the Lamanites shall be sent upon you; and if ye repent not they shall come in a time when you know not, and ye shall be visited with UTTER destruction; and it shall be according to the fierce anger of the Lord."
    },
    {
      "word": "hired",
      "hint": "Alma 10",
      "verse": "Alma 10:14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/10.14",
      "verseText": "Now it was those men who sought to destroy them, who were lawyers, who were HIRED or appointed by the people to administer the law at their times of trials, or at the trials of the crimes of the people before the judges."
    },
    {
      "word": "senum",
      "hint": "Alma 11",
      "verse": "Alma 11:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/11",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/11.3",
      "verseText": "And the judge received for his wages according to his time—a senine of gold for a day, or a SENUM of silver, which is equal to a senine of gold; and this is according to the law which was given."
    },
    {
      "word": "lucre",
      "hint": "Alma 11",
      "verse": "Alma 11:24",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/11",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/11.24",
      "verseText": "Believest thou that there is no God? I say unto you, Nay, thou knowest that there is a God, but thou lovest that LUCRE more than him."
    },
    {
      "word": "space",
      "hint": "Alma 12",
      "verse": "Alma 12:24",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/12",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/12.24",
      "verseText": "And we see that death comes upon mankind, yea, the death which has been spoken of by Amulek, which is the temporal death; nevertheless there was a SPACE granted unto man in which he might repent; therefore this life became a probationary state; a time to prepare to meet God; a time to prepare for that endless state which has been spoken of by us, which is after the resurrection of the dead."
    },
    {
      "word": "waxed",
      "hint": "Alma 13",
      "verse": "Alma 13:17",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/13",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/13.17",
      "verseText": "Now this Melchizedek was a king over the land of Salem; and his people had WAXED strong in iniquity and abomination; yea, they had all gone astray; they were full of all manner of wickedness;"
    },
    {
      "word": "above",
      "hint": "Alma 13",
      "verse": "Alma 13:28",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/13",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/13.28",
      "verseText": "But that ye would humble yourselves before the Lord, and call on his holy name, and watch and pray continually, that ye may not be tempted ABOVE that which ye can bear, and thus be led by the Holy Spirit, becoming humble, meek, submissive, patient, full of love and all long-suffering;"
    },
    {
      "word": "scene",
      "hint": "Alma 14",
      "verse": "Alma 14:10",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/14",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/14.10",
      "verseText": "And when Amulek saw the pains of the women and children who were consuming in the fire, he also was pained; and he said unto Alma: How can we witness this awful SCENE? Therefore let us stretch forth our hands, and exercise the power of God which is in us, and save them from the flames."
    },
    {
      "word": "twain",
      "hint": "Alma 14",
      "verse": "Alma 14:27",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/14",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/14.27",
      "verseText": "And it came to pass that so great was their fear that they fell to the earth, and did not obtain the outer door of the prison; and the earth shook mightily, and the walls of the prison were rent in TWAIN, so that they fell to the earth; and the chief judge, and the lawyers, and priests, and teachers, who smote upon Alma and Amulek, were slain by the fall thereof."
    },
    {
      "word": "fever",
      "hint": "Alma 15",
      "verse": "Alma 15:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/15",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/15.5",
      "verseText": "And it came to pass that they went immediately, obeying the message which he had sent unto them; and they went in unto the house unto Zeezrom; and they found him upon his bed, sick, being very low with a burning FEVER; and his mind also was exceedingly sore because of his iniquities; and when he saw them he stretched forth his hand, and besought them that they would heal him."
    },
    {
      "word": "check",
      "hint": "Alma 15",
      "verse": "Alma 15:17",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/15",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/15.17",
      "verseText": "Therefore, after Alma having established the church at Sidom, seeing a great CHECK, yea, seeing that the people were checked as to the pride of their hearts, and began to humble themselves before God, and began to assemble themselves together at their sanctuaries to worship God before the altar, watching and praying continually, that they might be delivered from Satan, and from death, and from destruction"
    },
    {
      "word": "after",
      "hint": "Alma 16",
      "verse": "Alma 16:20",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/16",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/16.20",
      "verseText": "And many of the people did inquire concerning the place where the Son of God should come; and they were taught that he would appear unto them AFTER his resurrection; and this the people did hear with great joy and gladness."
    },
    {
      "word": "clubs",
      "hint": "Alma 17",
      "verse": "Alma 17:36",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/17",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/17.36",
      "verseText": "But Ammon stood forth and began to cast stones at them with his sling; yea, with mighty power he did sling stones amongst them; and thus he slew a certain number of them insomuch that they began to be astonished at his power; nevertheless they were angry because of the slain of their brethren, and they were determined that he should fall; therefore, seeing that they could not hit him with their stones, they came forth with CLUBS to slay him."
    },
    {
      "word": "sling",
      "hint": "Alma 18",
      "verse": "Alma 18:16",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/18",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/18.16",
      "verseText": "And it came to pass that Ammon, being filled with the Spirit of God, therefore he perceived the thoughts of the king. And he said unto him: Is it because thou hast heard that I defended thy servants and thy flocks, and slew seven of their brethren with the SLING and with the sword, and smote off the arms of others, in order to defend thy flocks and thy servants; behold, is it this that causeth thy marvelings?"
    },
    {
      "word": "knows",
      "hint": "Alma 18",
      "verse": "Alma 18:32",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/18",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/18.32",
      "verseText": "And Ammon said: Yea, and he looketh down upon all the children of men; and he KNOWS all the thoughts and intents of the heart; for by his hand were they all created from the beginning."
    },
    {
      "word": "stink",
      "hint": "Alma 19",
      "verse": "Alma 19:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/19",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/19.5",
      "verseText": "Therefore, if this is the case, I would that ye should go in and see my husband, for he has been laid upon his bed for the space of two days and two nights; and some say that he is not dead, but others say that he is dead and that he stinketh, and that he ought to be placed in the sepulchre; but as for myself, to me he doth not STINK."
    },
    {
      "word": "blows",
      "hint": "Alma 20",
      "verse": "Alma 20:20",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/20",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/20.20",
      "verseText": "And he stretched forth his hand to slay Ammon. But Ammon withstood his BLOWS, and also smote his arm that he could not use it."
    },
    {
      "word": "spake",
      "hint": "Alma 21",
      "verse": "Alma 21:10",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/21",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/21.10",
      "verseText": "And it came to pass as he began to expound these things unto them they were angry with him, and began to mock him; and they would not hear the words which he SPAKE."
    },
    {
      "word": "merit",
      "hint": "Alma 22",
      "verse": "Alma 22:14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/22",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/22.14",
      "verseText": "And since man had fallen he could not MERIT anything of himself; but the sufferings and death of Christ atone for their sins, through faith and repentance, and so forth; and that he breaketh the bands of death, that the grave shall have no victory, and that the sting of death should be swallowed up in the hopes of glory; and Aaron did expound all these things unto the king."
    },
    {
      "word": "never",
      "hint": "Alma 23",
      "verse": "Alma 23:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/23",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/23.6",
      "verseText": "And as sure as the Lord liveth, so sure as many as believed, or as many as were brought to the knowledge of the truth, through the preaching of Ammon and his brethren, according to the spirit of revelation and of prophecy, and the power of God working miracles in them—yea, I say unto you, as the Lord liveth, as many of the Lamanites as believed in their preaching, and were converted unto the Lord, NEVER did fall away."
    },
    {
      "word": "blood",
      "hint": "Alma 24",
      "verse": "Alma 24:17",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/24",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/24.17",
      "verseText": "And now it came to pass that when the king had made an end of these sayings, and all the people were assembled together, they took their swords, and all the weapons which were used for the shedding of man’s BLOOD, and they did bury them up deep in the earth."
    },
    {
      "word": "serve",
      "hint": "Alma 25",
      "verse": "Alma 25:16",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/25",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/25.16",
      "verseText": "Now they did not suppose that salvation came by the law of Moses; but the law of Moses did SERVE to strengthen their faith in Christ; and thus they did retain a hope through faith, unto eternal salvation, relying upon the spirit of prophecy, which spake of those things to come."
    },
    {
      "word": "boast",
      "hint": "Alma 26",
      "verse": "Alma 26:12",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/26",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/26.12",
      "verseText": "Yea, I know that I am nothing; as to my strength I am weak; therefore I will not BOAST of myself, but I will BOAST of my God, for in his strength I can do all things; yea, behold, many mighty miracles we have wrought in this land, for which we will praise his name forever."
    },
    {
      "word": "sword",
      "hint": "Alma 27",
      "verse": "Alma 27:29",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/27",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/27.29",
      "verseText": "Therefore, they would suffer death in the most aggravating and distressing manner which could be inflicted by their brethren, before they would take the SWORD or cimeter to smite them."
    },
    {
      "word": "exult",
      "hint": "Alma 28",
      "verse": "Alma 28:12",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/28",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/28.12",
      "verseText": "While many thousands of others truly mourn for the loss of their kindred, yet they rejoice and EXULT in the hope, and even know, according to the promises of the Lord, that they are raised to dwell at the right hand of God, in a state of never-ending happiness."
    },
    {
      "word": "seeth",
      "hint": "Alma 29",
      "verse": "Alma 29:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/29",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/29.8",
      "verseText": "For behold, the Lord doth grant unto all nations, of their own nation and tongue, to teach his word, yea, in wisdom, all that he SEETH fit that they should have; therefore we see that the Lord doth counsel in wisdom, according to that which is just and true."
    },
    {
      "word": "equal",
      "hint": "Alma 30",
      "verse": "Alma 30:11",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/30",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/30.11",
      "verseText": "For there was a law that men should be judged according to their crimes. Nevertheless, there was no law against a man’s belief; therefore, a man was punished only for the crimes which he had done; therefore all men were on EQUAL grounds."
    },
    {
      "word": "fared",
      "hint": "Alma 30",
      "verse": "Alma 30:17",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/30",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/30.17",
      "verseText": "And many more such things did he say unto them, telling them that there could be no atonement made for the sins of men, but every man FARED in this life according to the management of the creature; therefore every man prospered according to his genius, and that every man conquered according to his strength; and whatsoever a man did was no crime."
    },
    {
      "word": "lying",
      "hint": "Alma 30",
      "verse": "Alma 30:42",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/30",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/30.42",
      "verseText": "Behold, I know that thou believest, but thou art possessed with a LYING spirit, and ye have put off the Spirit of God that it may have no place in you; but the devil has power over you, and he doth carry you about, working devices that he may destroy the children of God."
    },
    {
      "word": "house",
      "hint": "Alma 30",
      "verse": "Alma 30:56",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/30",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/30.56",
      "verseText": "And it came to pass that the curse was not taken off of Korihor; but he was cast out, and went about from HOUSE to HOUSE begging for his food."
    },
    {
      "word": "admit",
      "hint": "Alma 31",
      "verse": "Alma 31:13",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/31",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/31.13",
      "verseText": "For they had a place built up in the center of their synagogue, a place for standing, which was high above the head; and the top thereof would only ADMIT one person."
    },
    {
      "word": "stand",
      "hint": "Alma 31",
      "verse": "Alma 31:21",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/31",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/31.21",
      "verseText": "Now the place was called by them Rameumptom, which, being interpreted, is the holy STAND."
    },
    {
      "word": "souls",
      "hint": "Alma 31",
      "verse": "Alma 31:35",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/31",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/31.35",
      "verseText": "Behold, O Lord, their SOULS are precious, and many of them are our brethren; therefore, give unto us, O Lord, power and wisdom that we may bring these, our brethren, again unto thee."
    },
    {
      "word": "dross",
      "hint": "Alma 32",
      "verse": "Alma 32:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/32",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/32.3",
      "verseText": "Therefore they were not permitted to enter into their synagogues to worship God, being esteemed as filthiness; therefore they were poor; yea, they were esteemed by their brethren as DROSS; therefore they were poor as to things of the world; and also they were poor in heart."
    },
    {
      "word": "pluck",
      "hint": "Alma 32",
      "verse": "Alma 32:40",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/32",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/32.40",
      "verseText": "And thus, if ye will not nourish the word, looking forward with an eye of faith to the fruit thereof, ye can never PLUCK of the fruit of the tree of life."
    },
    {
      "word": "swell",
      "hint": "Alma 33",
      "verse": "Alma 33:23",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/33",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/33.23",
      "verseText": "And now, my brethren, I desire that ye shall plant this word in your hearts, and as it beginneth to SWELL even so nourish it by your faith. And behold, it will become a tree, springing up in you unto everlasting life. And then may God grant unto you that your burdens may be light, through the joy of his Son. And even all this can ye do if ye will. Amen."
    },
    {
      "word": "plant",
      "hint": "Alma 34",
      "verse": "Alma 34:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/34",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/34.4",
      "verseText": "Yea, even that ye would have so much faith as even to PLANT the word in your hearts, that ye may try the experiment of its goodness."
    },
    {
      "word": "beast",
      "hint": "Alma 34",
      "verse": "Alma 34:10",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/34",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/34.10",
      "verseText": "For it is expedient that there should be a great and last sacrifice; yea, not a sacrifice of man, neither of BEAST, neither of any manner of fowl; for it shall not be a human sacrifice; but it must be an infinite and eternal sacrifice."
    },
    {
      "word": "craft",
      "hint": "Alma 35",
      "verse": "Alma 35:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/35",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/35.3",
      "verseText": "And it came to pass that after the more popular part of the Zoramites had consulted together concerning the words which had been preached unto them, they were angry because of the word, for it did destroy their CRAFT; therefore they would not hearken unto the words."
    },
    {
      "word": "sweet",
      "hint": "Alma 32",
      "verse": "Alma 32:42",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/32",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/32.42",
      "verseText": "And because of your diligence and your faith and your patience with the word in nourishing it, that it may take root in you, behold, by and by ye shall pluck the fruit thereof, which is most precious, which is SWEET above all that is SWEET, and which is white above all that is white, yea, and pure above all that is pure; and ye shall feast upon this fruit even until ye are filled, that ye hunger not, neither shall ye thirst."
    },
    {
      "word": "limbs",
      "hint": "Alma 36",
      "verse": "Alma 36:10",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/36",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/36.10",
      "verseText": "And it came to pass that I fell to the earth; and it was for the space of three days and three nights that I could not open my mouth, neither had I the use of my LIMBS."
    },
    {
      "word": "taste",
      "hint": "Alma 36",
      "verse": "Alma 36:24",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/36",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/36.24",
      "verseText": "Yea, and from that time even until now, I have labored without ceasing, that I might bring souls unto repentance; that I might bring them to TASTE of the exceeding joy of which I did TASTE; that they might also be born of God, and be filled with the Holy Ghost."
    },
    {
      "word": "small",
      "hint": "Alma 37",
      "verse": "Alma 37:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/37",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/37.6",
      "verseText": "Now ye may suppose that this is foolishness in me; but behold I say unto you, that by SMALL and simple things are great things brought to pass; and SMALL means in many instances doth confound the wise."
    },
    {
      "word": "chaff",
      "hint": "Alma 37",
      "verse": "Alma 37:15",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/37",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/37.15",
      "verseText": "And now behold, I tell you by the spirit of prophecy, that if ye transgress the commandments of God, behold, these things which are sacred shall be taken away from you by the power of God, and ye shall be delivered up unto Satan, that he may sift you as CHAFF before the wind."
    },
    {
      "word": "youth",
      "hint": "Alma 37",
      "verse": "Alma 37:35",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/37",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/37.35",
      "verseText": "O, remember, my son, and learn wisdom in thy YOUTH; yea, learn in thy YOUTH to keep the commandments of God."
    },
    {
      "word": "mercy",
      "hint": "Alma 38",
      "verse": "Alma 38:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/38",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/38.8",
      "verseText": "And it came to pass that I was three days and three nights in the most bitter pain and anguish of soul; and never, until I did cry out unto the Lord Jesus Christ for MERCY, did I receive a remission of my sins. But behold, I did cry unto him and I did find peace to my soul."
    },
    {
      "word": "sober",
      "hint": "Alma 38",
      "verse": "Alma 38:15",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/38",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/38.15",
      "verseText": "And may the Lord bless your soul, and receive you at the last day into his kingdom, to sit down in peace. Now go, my son, and teach the word unto this people. Be SOBER. My son, farewell."
    },
    {
      "word": "cross",
      "hint": "Alma 39",
      "verse": "Alma 39:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/39",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/39.9",
      "verseText": "Now my son, I would that ye should repent and forsake your sins, and go no more after the lusts of your eyes, but CROSS yourself in all these things; for except ye do this ye can in nowise inherit the kingdom of God. Oh, remember, and take it upon you, and CROSS yourself in these things."
    },
    {
      "word": "carry",
      "hint": "Alma 39",
      "verse": "Alma 39:14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/39",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/39.14",
      "verseText": "Seek not after riches nor the vain things of this world; for behold, you cannot CARRY them with you."
    },
    {
      "word": "state",
      "hint": "Alma 40",
      "verse": "Alma 40:12",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/40",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/40.12",
      "verseText": "And then shall it come to pass, that the spirits of those who are righteous are received into a STATE of happiness, which is called paradise, a STATE of rest, a STATE of peace, where they shall rest from all their troubles and from all care, and sorrow."
    },
    {
      "word": "joint",
      "hint": "Alma 40",
      "verse": "Alma 40:23",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/40",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/40.23",
      "verseText": "The soul shall be restored to the body, and the body to the soul; yea, and every limb and JOINT shall be restored to its body; yea, even a hair of the head shall not be lost; but all things shall be restored to their proper and perfect frame."
    },
    {
      "word": "shall",
      "hint": "Alma 41",
      "verse": "Alma 41:10",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/41",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/41.10",
      "verseText": "Do not suppose, because it has been spoken concerning restoration, that ye SHALL be restored from sin to happiness. Behold, I say unto you, wickedness never was happiness."
    },
    {
      "word": "cease",
      "hint": "Alma 42",
      "verse": "Alma 42:13",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/42",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/42.13",
      "verseText": "Therefore, according to justice, the plan of redemption could not be brought about, only on conditions of repentance of men in this probationary state, yea, this preparatory state; for except it were for these conditions, mercy could not take effect except it should destroy the work of justice. Now the work of justice could not be destroyed; if so, God would CEASE to be God."
    },
    {
      "word": "deeds",
      "hint": "Alma 42",
      "verse": "Alma 42:27",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/42",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/42.27",
      "verseText": "Therefore, O my son, whosoever will come may come and partake of the waters of life freely; and whosoever will not come the same is not compelled to come; but in the last day it shall be restored unto him according to his DEEDS."
    },
    {
      "word": "usurp",
      "hint": "Alma 43",
      "verse": "Alma 43:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/43",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/43.8",
      "verseText": "For behold, his designs were to stir up the Lamanites to anger against the Nephites; this he did that he might USURP great power over them, and also that he might gain power over the Nephites by bringing them into bondage."
    },
    {
      "word": "scalp",
      "hint": "Alma 44",
      "verse": "Alma 44:15",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/44",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/44.15",
      "verseText": "And it came to pass that the soldier who stood by, who smote off the SCALP of Zerahemnah, took up the SCALP from off the ground by the hair, and laid it upon the point of his sword, and stretched it forth unto them, saying unto them with a loud voice:"
    },
    {
      "word": "quick",
      "hint": "Alma 46",
      "verse": "Alma 46:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/46",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/46.8",
      "verseText": "Thus we see how QUICK the children of men do forget the Lord their God, yea, how QUICK to do iniquity, and to be led away by the evil one."
    },
    {
      "word": "fraud",
      "hint": "Alma 48",
      "verse": "Alma 48:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/48",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/48.7",
      "verseText": "Now it came to pass that while Amalickiah had thus been obtaining power by FRAUD and deceit, Moroni, on the other hand, had been preparing the minds of the people to be faithful unto the Lord their God."
    },
    {
      "word": "ridge",
      "hint": "Alma 49",
      "verse": "Alma 49:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/49",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/49.4",
      "verseText": "But behold, how great was their disappointment; for behold, the Nephites had dug up a RIDGE of earth round about them, which was so high that the Lamanites could not cast their stones and their arrows at them that they might take effect, neither could they come upon them save it was by their place of entrance."
    },
    {
      "word": "large",
      "hint": "Alma 50",
      "verse": "Alma 50:29",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/50",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/50.29",
      "verseText": "Therefore, Morianton put it into their hearts that they should flee to the land which was northward, which was covered with LARGE bodies of water, and take possession of the land which was northward."
    },
    {
      "word": "stole",
      "hint": "Alma 51",
      "verse": "Alma 51:34",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/51",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/51.34",
      "verseText": "And it came to pass that Teancum STOLE privily into the tent of the king, and put a javelin to his heart; and he did cause the death of the king immediately that he did not awake his servants."
    },
    {
      "word": "march",
      "hint": "Alma 53",
      "verse": "Alma 53:22",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/53",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/53.22",
      "verseText": "And now it came to pass that Helaman did MARCH at the head of his two thousand stripling soldiers, to the support of the people in the borders of the land on the south by the west sea."
    },
    {
      "word": "merry",
      "hint": "Alma 55",
      "verse": "Alma 55:14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/55",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/55.14",
      "verseText": "And it came to pass they did drink and were MERRY, and by and by they were all drunken."
    },
    {
      "word": "doubt",
      "hint": "Alma 56",
      "verse": "Alma 56:48",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/56",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/56.48",
      "verseText": "And they rehearsed unto me the words of their mothers, saying: We do not DOUBT our mothers knew it."
    },
    {
      "word": "whole",
      "hint": "Alma 57",
      "verse": "Alma 57:25",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/57",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/57.25",
      "verseText": "And it came to pass that there were two hundred, out of my two thousand and sixty, who had fainted because of the loss of blood; nevertheless, according to the goodness of God, and to our great astonishment, and also the joy of our WHOLE army, there was not one soul of them who did perish; yea, and neither was there one soul among them who had not received many wounds."
    },
    {
      "word": "outer",
      "hint": "Alma 60",
      "verse": "Alma 60:23",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/60",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/60.23",
      "verseText": "Do ye suppose that God will look upon you as guiltless while ye sit still and behold these things? Behold I say unto you, Nay. Now I would that ye should remember that God has said that the inward vessel shall be cleansed first, and then shall the OUTER vessel be cleansed also."
    },
    {
      "word": "pride",
      "hint": "Alma 62",
      "verse": "Alma 62:49",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/62",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/62.49",
      "verseText": "But notwithstanding their riches, or their strength, or their prosperity, they were not lifted up in the PRIDE of their eyes; neither were they slow to remember the Lord their God; but they did humble themselves exceedingly before him."
    },
    {
      "word": "ended",
      "hint": "Alma 63",
      "verse": "Alma 63:17",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/63",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/63.17",
      "verseText": "And thus ENDED the account of Alma, and Helaman his son, and also Shiblon, who was his son."
    },
    {
      "word": "yield",
      "hint": "Helaman 1",
      "verse": "Helaman 1:32",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/1.32",
      "verseText": "And thus had Coriantumr plunged the Lamanites into the midst of the Nephites, insomuch that they were in the power of the Nephites, and he himself was slain, and the Lamanites did YIELD themselves into the hands of the Nephites."
    },
    {
      "word": "prove",
      "hint": "Helaman 2",
      "verse": "Helaman 2:13",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/2.13",
      "verseText": "And behold, in the end of this book ye shall see that this Gadianton did PROVE the overthrow, yea, almost the entire destruction of the people of Nephi."
    },
    {
      "word": "faith",
      "hint": "Helaman 3",
      "verse": "Helaman 3:35",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/3.35",
      "verseText": "Nevertheless they did fast and pray oft, and did wax stronger and stronger in their humility, and firmer and firmer in the FAITH of Christ, unto the filling their souls with joy and consolation, yea, even to the purifying and the sanctification of their hearts, which sanctification cometh because of their yielding their hearts unto God."
    },
    {
      "word": "dwell",
      "hint": "Helaman 4",
      "verse": "Helaman 4:24",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/4",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/4.24",
      "verseText": "And they saw that they had become weak, like unto their brethren, the Lamanites, and that the Spirit of the Lord did no more preserve them; yea, it had withdrawn from them because the Spirit of the Lord doth not DWELL in unholy temples—"
    },
    {
      "word": "voice",
      "hint": "Helaman 5",
      "verse": "Helaman 5:30",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/5.30",
      "verseText": "And it came to pass when they heard this VOICE, and beheld that it was not a VOICE of thunder, neither was it a VOICE of a great tumultuous noise, but behold, it was a still VOICE of perfect mildness, as if it had been a whisper, and it did pierce even to the very soul—"
    },
    {
      "word": "awful",
      "hint": "Helaman 6",
      "verse": "Helaman 6:30",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/6",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/6.30",
      "verseText": "And behold, it is he who is the author of all sin. And behold, he doth carry on his works of darkness and secret murder, and doth hand down their plots, and their oaths, and their covenants, and their plans of AWFUL wickedness, from generation to generation according as he can get hold upon the hearts of the children of men."
    },
    {
      "word": "build",
      "hint": "Helaman 5",
      "verse": "Helaman 5:12",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/5.12",
      "verseText": "And now, my sons, remember, remember that it is upon the rock of our Redeemer, who is Christ, the Son of God, that ye must BUILD your foundation; that when the devil shall send forth his mighty winds, yea, his shafts in the whirlwind, yea, when all his hail and his mighty storm shall beat upon you, it shall have no power over you to drag you down to the gulf of misery and endless wo, because of the rock upon which ye are built, which is a sure foundation, a foundation whereon if men BUILD they cannot fall."
    },
    {
      "word": "joyed",
      "hint": "Helaman 7",
      "verse": "Helaman 7:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/7.7",
      "verseText": "Oh, that I could have had my days in the days when my father Nephi first came out of the land of Jerusalem, that I could have JOYED with him in the promised land; then were his people easy to be entreated, firm to keep the commandments of God, and slow to be led to do iniquity; and they were quick to hearken unto the words of the Lord—"
    },
    {
      "word": "doors",
      "hint": "Helaman 8",
      "verse": "Helaman 8:27",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/8.27",
      "verseText": "Yea, behold it is now even at your DOORS; yea, go ye in unto the judgment-seat, and search; and behold, your judge is murdered, and he lieth in his blood; and he hath been murdered by his brother, who seeketh to sit in the judgment-seat."
    },
    {
      "word": "cloak",
      "hint": "Helaman 9",
      "verse": "Helaman 9:31",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/9",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/9.31",
      "verseText": "But behold, ye shall examine him, and ye shall find blood upon the skirts of his CLOAK."
    },
    {
      "word": "loose",
      "hint": "Helaman 10",
      "verse": "Helaman 10:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/10.7",
      "verseText": "Behold, I give unto you power, that whatsoever ye shall seal on earth shall be sealed in heaven; and whatsoever ye shall LOOSE on earth shall be loosed in heaven; and thus shall ye have power among this people."
    },
    {
      "word": "cover",
      "hint": "Helaman 11",
      "verse": "Helaman 11:20",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/11",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/11.20",
      "verseText": "And thus it did come to pass that the people of Nephi began to prosper again in the land, and began to build up their waste places, and began to multiply and spread, even until they did COVER the whole face of the land, both on the northward and on the southward, from the sea west to the sea east."
    },
    {
      "word": "guide",
      "hint": "Helaman 12",
      "verse": "Helaman 12:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/12",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/12.6",
      "verseText": "Behold, they do not desire that the Lord their God, who hath created them, should rule and reign over them; notwithstanding his great goodness and his mercy towards them, they do set at naught his counsels, and they will not that he should be their GUIDE."
    },
    {
      "word": "grace",
      "hint": "Helaman 12",
      "verse": "Helaman 12:24",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/12",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/12.24",
      "verseText": "And may God grant, in his great fulness, that men might be brought unto repentance and good works, that they might be restored unto GRACE for GRACE, according to their works."
    },
    {
      "word": "heavy",
      "hint": "Helaman 13",
      "verse": "Helaman 13:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/13",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/13.6",
      "verseText": "Yea, HEAVY destruction awaiteth this people, and it surely cometh unto this people, and nothing can save this people save it be repentance and faith on the Lord Jesus Christ, who surely shall come into the world, and shall suffer many things and shall be slain for his people."
    },
    {
      "word": "blind",
      "hint": "Helaman 13",
      "verse": "Helaman 13:29",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/13",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/13.29",
      "verseText": "Yea, how long will ye suffer yourselves to be led by foolish and BLIND guides? Yea, how long will ye choose darkness rather than light?"
    },
    {
      "word": "arise",
      "hint": "Helaman 14",
      "verse": "Helaman 14:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/14",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/14.5",
      "verseText": "And behold, there shall a new star ARISE, such an one as ye never have beheld; and this also shall be a sign unto you."
    },
    {
      "word": "solid",
      "hint": "Helaman 14",
      "verse": "Helaman 14:21",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/14",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/14.21",
      "verseText": "Yea, at the time that he shall yield up the ghost there shall be thunderings and lightnings for the space of many hours, and the earth shall shake and tremble; and the rocks which are upon the face of this earth, which are both above the earth and beneath, which ye know at this time are SOLID, or the more part of it is one solid mass, shall be broken up;"
    },
    {
      "word": "daily",
      "hint": "Helaman 15",
      "verse": "Helaman 15:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/15",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/15.6",
      "verseText": "Yea, I say unto you, that the more part of them are doing this, and they are striving with unwearied diligence that they may bring the remainder of their brethren to the knowledge of the truth; therefore there are many who do add to their numbers DAILY."
    },
    {
      "word": "angry",
      "hint": "Helaman 16",
      "verse": "Helaman 16:2",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/16",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/16.2",
      "verseText": "But as many as there were who did not believe in the words of Samuel were ANGRY with him; and they cast stones at him upon the wall, and also many shot arrows at him as he stood upon the wall; but the Spirit of the Lord was with him, insomuch that they could not hit him with their stones neither with their arrows."
    },
    {
      "word": "began",
      "hint": "Helaman 16",
      "verse": "Helaman 16:14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/16",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/16.14",
      "verseText": "And angels did appear unto men, wise men, and did declare unto them glad tidings of great joy; thus in this year the scriptures BEGAN to be fulfilled."
    },
    {
      "word": "cheer",
      "hint": "3 Nephi 1",
      "verse": "3 Nephi 1:13",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/1.13",
      "verseText": "Lift up your head and be of good CHEER; for behold, the time is at hand, and on this night shall the sign be given, and on the morrow come I into the world, to show unto the world that I will fulfil all that which I have caused to be spoken by the mouth of my holy prophets."
    },
    {
      "word": "about",
      "hint": "3 Nephi 2",
      "verse": "3 Nephi 2:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/2.3",
      "verseText": "And it came to pass that the people began to wax strong in wickedness and abominations; and they did not believe that there should be any more signs or wonders given; and Satan did go ABOUT, leading away the hearts of the people, tempting them and causing them that they should do great wickedness in the land."
    },
    {
      "word": "guard",
      "hint": "3 Nephi 3",
      "verse": "3 Nephi 3:14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/3.14",
      "verseText": "And he caused that fortifications should be built round about them, and the strength thereof should be exceedingly great. And he caused that armies, both of the Nephites and of the Lamanites, or of all them who were numbered among the Nephites, should be placed as guards round about to watch them, and to GUARD them from the robbers day and night."
    },
    {
      "word": "siege",
      "hint": "3 Nephi 4",
      "verse": "3 Nephi 4:18",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/4",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/4.18",
      "verseText": "But behold, this was an advantage to the Nephites; for it was impossible for the robbers to lay SIEGE sufficiently long to have any effect upon the Nephites, because of their much provision which they had laid up in store,"
    },
    {
      "word": "among",
      "hint": "3 Nephi 5",
      "verse": "3 Nephi 5:13",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/5.13",
      "verseText": "Behold, I am a disciple of Jesus Christ, the Son of God. I have been called of him to declare his word AMONG his people, that they might have everlasting life."
    },
    {
      "word": "taken",
      "hint": "3 Nephi 6",
      "verse": "3 Nephi 6:23",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/6",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/6.23",
      "verseText": "Now there were many of those who testified of the things pertaining to Christ who testified boldly, who were TAKEN and put to death secretly by the judges, that the knowledge of their death came not unto the governor of the land until after their death."
    },
    {
      "word": "vomit",
      "hint": "3 Nephi 7",
      "verse": "3 Nephi 7:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/7.8",
      "verseText": "And thus six years had not passed away since the more part of the people had turned from their righteousness, like the dog to his VOMIT, or like the sow to her wallowing in the mire."
    },
    {
      "word": "sharp",
      "hint": "3 Nephi 8",
      "verse": "3 Nephi 8:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/8.7",
      "verseText": "And there were exceedingly SHARP lightnings, such as never had been known in all the land."
    },
    {
      "word": "vapor",
      "hint": "3 Nephi 8",
      "verse": "3 Nephi 8:20",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/8.20",
      "verseText": "And it came to pass that there was thick darkness upon all the face of the land, insomuch that the inhabitants thereof who had not fallen could feel the VAPOR of darkness;",
      "submissionText": "Thanks to Kiwi Reid for submitting this word!"
    },
    {
      "word": "alpha",
      "hint": "3 Nephi 9",
      "verse": "3 Nephi 9:18",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/9",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/9.18",
      "verseText": "I am the light and the life of the world. I am ALPHA and Omega, the beginning and the end."
    },
    {
      "word": "wings",
      "hint": "3 Nephi 10",
      "verse": "3 Nephi 10:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/10.6",
      "verseText": "O ye house of Israel whom I have spared, how oft will I gather you as a hen gathereth her chickens under her WINGS, if ye will repent and return unto me with full purpose of heart."
    },
    {
      "word": "smoke",
      "hint": "3 Nephi 10",
      "verse": "3 Nephi 10:13",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/10.13",
      "verseText": "And they were spared and were not sunk and buried up in the earth; and they were not drowned in the depths of the sea; and they were not burned by fire, neither were they fallen upon and crushed to death; and they were not carried away in the whirlwind; neither were they overpowered by the vapor of SMOKE and of darkness."
    },
    {
      "word": "sound",
      "hint": "3 Nephi 11",
      "verse": "3 Nephi 11:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/11",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/11.5",
      "verseText": "And again the third time they did hear the voice, and did open their ears to hear it; and their eyes were towards the SOUND thereof; and they did look steadfastly towards heaven, from whence the SOUND came."
    },
    {
      "word": "nails",
      "hint": "3 Nephi 11",
      "verse": "3 Nephi 11:14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/11",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/11.14",
      "verseText": "Arise and come forth unto me, that ye may thrust your hands into my side, and also that ye may feel the prints of the NAILS in my hands and in my feet, that ye may know that I am the God of Israel, and the God of the whole earth, and have been slain for the sins of the world."
    },
    {
      "word": "under",
      "hint": "3 Nephi 12",
      "verse": "3 Nephi 12:15",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/12",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/12.15",
      "verseText": "Behold, do men light a candle and put it UNDER a bushel? Nay, but on a candlestick, and it giveth light to all that are in the house;"
    },
    {
      "word": "debts",
      "hint": "3 Nephi 13",
      "verse": "3 Nephi 13:11",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/13",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/13.11",
      "verseText": "And forgive us our DEBTS, as we forgive our debtors."
    },
    {
      "word": "barns",
      "hint": "3 Nephi 13",
      "verse": "3 Nephi 13:26",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/13",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/13.26",
      "verseText": "Behold the fowls of the air, for they sow not, neither do they reap nor gather into BARNS; yet your heavenly Father feedeth them. Are ye not much better than they?"
    },
    {
      "word": "swine",
      "hint": "3 Nephi 14",
      "verse": "3 Nephi 14:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/14",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/14.6",
      "verseText": "Give not that which is holy unto the dogs, neither cast ye your pearls before SWINE, lest they trample them under their feet, and turn again and rend you."
    },
    {
      "word": "other",
      "hint": "3 Nephi 15",
      "verse": "3 Nephi 15:17",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/15",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/15.17",
      "verseText": "That OTHER sheep I have which are not of this fold; them also I must bring, and they shall hear my voice; and there shall be one fold, and one shepherd."
    },
    {
      "word": "tread",
      "hint": "3 Nephi 16",
      "verse": "3 Nephi 16:14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/16",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/16.14",
      "verseText": "And I will not suffer my people, who are of the house of Israel, to go through among them, and TREAD them down, saith the Father."
    },
    {
      "word": "winds",
      "hint": "3 Nephi 14",
      "verse": "3 Nephi 14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/14",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/14.25",
      "verseText": "And the rain descended, and the floods came, and the WINDS blew, and beat upon that house; and it fell not, for it was founded upon a rock."
    },
    {
      "word": "tarry",
      "hint": "3 Nephi 17",
      "verse": "3 Nephi 17:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/17",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/17.5",
      "verseText": "And it came to pass that when Jesus had thus spoken, he cast his eyes round about again on the multitude, and beheld they were in tears, and did look steadfastly upon him as if they would ask him to TARRY a little longer with them."
    },
    {
      "word": "arose",
      "hint": "3 Nephi 17",
      "verse": "3 Nephi 17:20",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/17",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/17.20",
      "verseText": "And they AROSE from the earth, and he said unto them: Blessed are ye because of your faith. And now behold, my joy is full."
    },
    {
      "word": "brake",
      "hint": "3 Nephi 18",
      "verse": "3 Nephi 18:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/18",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/18.3",
      "verseText": "And when the disciples had come with bread and wine, he took of the bread and BRAKE and blessed it; and he gave unto the disciples and commanded that they should eat."
    },
    {
      "word": "wheat",
      "hint": "3 Nephi 18",
      "verse": "3 Nephi 18",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/18",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/18.18",
      "verseText": "Behold, verily, verily, I say unto you, ye must watch and pray always lest ye enter into temptation; for Satan desireth to have you, that he may sift you as WHEAT."
    },
    {
      "word": "sakes",
      "hint": "3 Nephi 18",
      "verse": "3 Nephi 18:35",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/18",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/18.35",
      "verseText": "And now I go unto the Father, because it is expedient that I should go unto the Father for your SAKES."
    },
    {
      "word": "kneel",
      "hint": "3 Nephi 19",
      "verse": "3 Nephi 19.6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/19",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/19.6",
      "verseText": "And the twelve did teach the multitude; and behold, they did cause that the multitude should KNEEL down upon the face of the earth, and should pray unto the Father in the name of Jesus."
    },
    {
      "word": "smile",
      "hint": "3 Nephi 19",
      "verse": "3 Nephi 19.25",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/19",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/19.25",
      "verseText": "And it came to pass that Jesus blessed them as they did pray unto him; and his countenance did SMILE upon them, and the light of his countenance did shine upon them, and behold they were as white as the countenance and also the garments of Jesus; and behold the whiteness thereof did exceed all the whiteness, yea, even there could be nothing upon earth so white as the whiteness thereof."
    },
    {
      "word": "haste",
      "hint": "3 Nephi 20",
      "verse": "3 Nephi 20:42",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/20",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/20.42",
      "verseText": "For ye shall not go out with HASTE nor go by flight; for the Lord will go before you, and the God of Israel shall be your rearward."
    },
    {
      "word": "power",
      "hint": "3 Nephi 21",
      "verse": "3 Nephi 21:25",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/21",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/21.25",
      "verseText": "And then shall the POWER of heaven come down among them; and I also will be in the midst."
    },
    {
      "word": "coals",
      "hint": "3 Nephi 22",
      "verse": "3 Nephi 22:16",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/22",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/22.16",
      "verseText": "Behold, I have created the smith that bloweth the COALS in the fire, and that bringeth forth an instrument for his work; and I have created the waster to destroy."
    },
    {
      "word": "ought",
      "hint": "3 Nephi 23",
      "verse": "3 Nephi 23:1",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/23",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/23.1",
      "verseText": "And now, behold, I say unto you, that ye OUGHT to search these things. Yea, a commandment I give unto you that ye search these things diligently; for great are the words of Isaiah."
    },
    {
      "word": "stout",
      "hint": "3 Nephi 24",
      "verse": "3 Nephi 24:13",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/24",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/24.13",
      "verseText": "Your words have been STOUT against me, saith the Lord. Yet ye say: What have we spoken against thee?"
    },
    {
      "word": "smite",
      "hint": "3 Nephi 25",
      "verse": "3 Nephi 25:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/25",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/25.6",
      "verseText": "And he shall turn the heart of the fathers to the children, and the heart of the children to their fathers, lest I come and SMITE the earth with a curse."
    },
    {
      "word": "truly",
      "hint": "3 Nephi 26",
      "verse": "3 Nephi 26:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/26",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/26.6",
      "verseText": "And now there cannot be written in this book even a hundredth part of the things which Jesus did TRULY teach unto the people;"
    },
    {
      "word": "judge",
      "hint": "3 Nephi 27",
      "verse": "3 Nephi 27:16",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/27",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/27.16",
      "verseText": "And it shall come to pass, that whoso repenteth and is baptized in my name shall be filled; and if he endureth to the end, behold, him will I hold guiltless before my Father at that day when I shall stand to JUDGE the world."
    },
    {
      "word": "knock",
      "hint": "3 Nephi 27",
      "verse": "3 Nephi 27:29",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/27",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/27.29",
      "verseText": "Therefore, ask, and ye shall receive; KNOCK, and it shall be opened unto you; for he that asketh, receiveth; and unto him that knocketh, it shall be opened."
    },
    {
      "word": "taste",
      "hint": "3 Nephi 28",
      "verse": "3 Nephi 28:25",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/28",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/28.25",
      "verseText": "Behold, I was about to write the names of those who were never to TASTE of death, but the Lord forbade; therefore I write them not, for they are hid from the world."
    },
    {
      "word": "durst",
      "hint": "3 Nephi 28",
      "verse": "3 Nephi 28:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/28",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/28.5",
      "verseText": "And they sorrowed in their hearts, for they DURST not speak unto him the thing which they desired."
    },
    {
      "word": "spurn",
      "hint": "3 Nephi 29",
      "verse": "3 Nephi 29:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/29",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/3-ne/29.4",
      "verseText": "And when ye shall see these sayings coming forth among you, then ye need not any longer SPURN at the doings of the Lord, for the sword of his justice is in his right hand; and behold, at that day, if ye shall SPURN at his doings he will cause that it shall soon overtake you."
    },
    {
      "word": "cause",
      "hint": "4 Nephi 1",
      "verse": "4 Nephi 1:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/4-ne/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/4-ne/1.5",
      "verseText": "And there were great and marvelous works wrought by the disciples of Jesus, insomuch that they did heal the sick, and raise the dead, and CAUSE the lame to walk, and the blind to receive their sight, and the deaf to hear; and all manner of miracles did they work among the children of men; and in nothing did they work miracles save it were in the name of Jesus."
    },
    {
      "word": "could",
      "hint": "4 Nephi 1",
      "verse": "4 Nephi 1:16",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/4-ne/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/4-ne/1.16",
      "verseText": "And there were no envyings, nor strifes, nor tumults, nor whoredoms, nor lyings, nor murders, nor any manner of lasciviousness; and surely there COULD not be a happier people among all the people who had been created by the hand of God."
    },
    {
      "word": "gifts",
      "hint": "Mormon 1",
      "verse": "Mormon 1:14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/1.14",
      "verseText": "And there were no GIFTS from the Lord, and the Holy Ghost did not come upon any, because of their wickedness and unbelief."
    },
    {
      "word": "magic",
      "hint": "Mormon 2",
      "verse": "Mormon 2:10",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/2.10",
      "verseText": "And it came to pass that the Nephites began to repent of their iniquity, and began to cry even as had been prophesied by Samuel the prophet; for behold no man could keep that which was his own, for the thieves, and the robbers, and the murderers, and the MAGIC art, and the witchcraft which was in the land."
    },
    {
      "word": "abide",
      "hint": "Mormon 2",
      "verse": "Mormon 2:26",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/2.26",
      "verseText": "And it came to pass that when they had fled we did pursue them with our armies, and did meet them again, and did beat them; nevertheless the strength of the Lord was not with us; yea, we were left to ourselves, that the Spirit of the Lord did not ABIDE in us; therefore we had become weak like unto our brethren."
    },
    {
      "word": "repay",
      "hint": "Mormon 3",
      "verse": "Mormon 3:15",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/3.15",
      "verseText": "Vengeance is mine, and I will REPAY; and because this people repented not after I had delivered them, behold, they shall be cut off from the face of the earth."
    },
    {
      "word": "swept",
      "hint": "Mormon 4",
      "verse": "Mormon 4:18",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/4",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/4.18",
      "verseText": "And from this time forth did the Nephites gain no power over the Lamanites, but began to be SWEPT off by them even as a dew before the sun."
    },
    {
      "word": "hands",
      "hint": "Mormon 5",
      "verse": "Mormon 5:23",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/5.23",
      "verseText": "Know ye not that ye are in the HANDS of God? Know ye not that he hath all power, and at his great command the earth shall be rolled together as a scroll?"
    },
    {
      "word": "arrow",
      "hint": "Mormon 6",
      "verse": "Mormon 6:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/6",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/6.9",
      "verseText": "And it came to pass that they did fall upon my people with the sword, and with the bow, and with the ARROW, and with the ax, and with all manner of weapons of war."
    },
    {
      "word": "sting",
      "hint": "Mormon 7",
      "verse": "Mormon 7:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/7.5",
      "verseText": "Know ye that ye must come to the knowledge of your fathers, and repent of all your sins and iniquities, and believe in Jesus Christ, that he is the Son of God, and that he was slain by the Jews, and by the power of the Father he hath risen again, whereby he hath gained the victory over the grave; and also in him is the STING of death swallowed up."
    },
    {
      "word": "given",
      "hint": "Mormon 7",
      "verse": "Mormon 7:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/7.7",
      "verseText": "And he hath brought to pass the redemption of the world, whereby he that is found guiltless before him at the judgment day hath it GIVEN unto him to dwell in the presence of God in his kingdom, to sing ceaseless praises with the choirs above, unto the Father, and unto the Son, and unto the Holy Ghost, which are one God, in a state of happiness which hath no end."
    },
    {
      "word": "exist",
      "hint": "Mormon 8",
      "verse": "Mormon 8:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/8.9",
      "verseText": "And now, behold, I say no more concerning them, for there are none save it be the Lamanites and robbers that do EXIST upon the face of the land."
    },    
    {
      "word": "whoso",
      "hint": "Mormon 8",
      "verse": "Mormon 8:12",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/8.12",
      "verseText": "And WHOSO receiveth this record, and shall not condemn it because of the imperfections which are in it, the same shall know of greater things than these. Behold, I am Moroni; and were it possible, I would make all things known unto you."
    },
    {
      "word": "flame",
      "hint": "Mormon 9",
      "verse": "Mormon 9:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/9",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/9.5",
      "verseText": "For behold, when ye shall be brought to see your nakedness before God, and also the glory of God, and the holiness of Jesus Christ, it will kindle a FLAME of unquenchable fire upon you."
    },    
    {
      "word": "lusts",
      "hint": "Mormon 9",
      "verse": "Mormon 9:28",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/9",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/9.28",
      "verseText": "Be wise in the days of your probation; strip yourselves of all uncleanness; ask not, that ye may consume it on your LUSTS, but ask with a firmness unshaken, that ye will yield to no temptation, but that ye will serve the true and living God."
    },
    {
      "word": "until",
      "hint": "Mormon 8",
      "verse": "Mormon 8:22",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/morm/8.22",
      "verseText": "For the eternal purposes of the Lord shall roll on, UNTIL all his promises shall be fulfilled."
    },    
    {
      "word": "tower",
      "hint": "Ether 1",
      "verse": "Ether 1:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/1.5",
      "verseText": "But behold, I give not the full account, but a part of the account I give, from the TOWER down until they were destroyed."
    },
    {
      "word": "honey",
      "hint": "Ether 2",
      "verse": "Ether 2:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/2.3",
      "verseText": "And they did also carry with them deseret, which, by interpretation, is a HONEY bee; and thus they did carry with them swarms of bees, and all manner of that which was upon the face of the land, seeds of every kind."
    },
    {
      "word": "whale",
      "hint": "Ether 2",
      "verse": "Ether 2:24",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/2.24",
      "verseText": "For behold, ye shall be as a WHALE in the midst of the sea; for the mountain waves shall dash upon you. Nevertheless, I will bring you up again out of the depths of the sea; for the winds have gone forth out of my mouth, and also the rains and the floods have I sent forth."
    },
    {
      "word": "looks",
      "hint": "Ether 3",
      "verse": "Ether 3:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/3.5",
      "verseText": "Behold, O Lord, thou canst do this. We know that thou art able to show forth great power, which LOOKS small unto the understanding of men."
    },
    {
      "word": "image",
      "hint": "Ether 3",
      "verse": "Ether 3:15",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/3.15",
      "verseText": "And never have I showed myself unto man whom I have created, for never has man believed in me as thou hast. Seest thou that ye are created after mine own image? Yea, even all men were created in the beginning after mine own IMAGE."
    },
    {
      "word": "found",
      "hint": "Ether 4",
      "verse": "Ether 4:19",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/4",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/4.19",
      "verseText": "And blessed is he that is FOUND faithful unto my name at the last day, for he shall be lifted up to dwell in the kingdom prepared for him from the foundation of the world. And behold it is I that hath spoken it. Amen."
    },
    {
      "word": "touch",
      "hint": "Ether 5",
      "verse": "Ether 5:1",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/5.1",
      "verseText": "And now I, Moroni, have written the words which were commanded me, according to my memory; and I have told you the things which I have sealed up; therefore TOUCH them not in order that ye may translate; for that thing is forbidden you, except by and by it shall be wisdom in God."
    },
    {
      "word": "waves",
      "hint": "Ether 6",
      "verse": "Ether 6:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/6",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/6.5",
      "verseText": "And it came to pass that the Lord God caused that there should be a furious wind blow upon the face of the waters, towards the promised land; and thus they were tossed upon the WAVES of the sea before the wind."
    },
    {
      "word": "crept",
      "hint": "Ether 7",
      "verse": "Ether 7:18",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/7.18",
      "verseText": "And it came to pass as he was about to put him to death, the sons of Shule CREPT into the house of Noah by night and slew him, and broke down the door of the prison and brought out their father, and placed him upon his throne in his own kingdom."
    },
    {
      "word": "oaths",
      "hint": "Ether 8",
      "verse": "Ether 8:20",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/8.20",
      "verseText": "And now I, Moroni, do not write the manner of their OATHS and combinations, for it hath been made known unto me that they are had among all people, and they are had among the Lamanites."
    },
    {
      "word": "hedge",
      "hint": "Ether 9",
      "verse": "Ether 9:33",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/9",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/9.33",
      "verseText": "And it came to pass that the Lord did cause the serpents that they should pursue them no more, but that they should HEDGE up the way that the people could not pass, that whoso should attempt to pass might fall by the poisonous serpents."
    },
    {
      "word": "taxes",
      "hint": "Ether 10",
      "verse": "Ether 10:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/10.6",
      "verseText": "And he did erect him an exceedingly beautiful throne; and he did build many prisons, and whoso would not be subject unto TAXES he did cast into prison; and whoso was not able to pay TAXES he did cast into prison; and he did cause that they should labor continually for their support; and whoso refused to labor he did cause to be put to death."
    },
    {
      "word": "heaps",
      "hint": "Ether 11",
      "verse": "Ether 11:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/11",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/11.6",
      "verseText": "And there was great calamity in all the land, for they had testified that a great curse should come upon the land, and also upon the people, and that there should be a great destruction among them, such an one as never had been upon the face of the earth, and their bones should become as HEAPS of earth upon the face of the land except they should repent of their wickedness."
    },
    {
      "word": "begat",
      "hint": "Ether 11",
      "verse": "Ether 11:23",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/11",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/11.23",
      "verseText": "And it came to pass that Coriantor begat Ether, and he died, having dwelt in captivity all his days."
    },
    {
      "word": "trial",
      "hint": "Ether 12",
      "verse": "Ether 12:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/12",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/12.6",
      "verseText": "And now, I, Moroni, would speak somewhat concerning these things; I would show unto the world that faith is things which are hoped for and not seen; wherefore, dispute not because ye see not, for ye receive no witness until after the TRIAL of your faith."
    },
    {
      "word": "wrote",
      "hint": "Ether 12",
      "verse": "Ether 12:24",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/12",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/12.24",
      "verseText": "And thou hast made us that we could write but little, because of the awkwardness of our hands. Behold, thou hast not made us mighty in writing like unto the brother of Jared, for thou madest him that the things which he WROTE were mighty even as thou art, unto the overpowering of man to read them."
    },
    {
      "word": "built",
      "hint": "Ether 13",
      "verse": "Ether 13:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/13",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/13.6",
      "verseText": "And that a New Jerusalem should be BUILT up upon this land, unto the remnant of the seed of Joseph, for which things there has been a type."
    },
    {
      "word": "thigh",
      "hint": "Ether 13",
      "verse": "Ether 13:31",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/13",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/13.31",
      "verseText": "And Shared wounded Coriantumr in his THIGH, that he did not go to battle again for the space of two years, in which time all the people upon the face of the land were shedding blood, and there was none to restrain them."
    },
    {
      "word": "shelf",
      "hint": "Ether 14",
      "verse": "Ether 14:1",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/14",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/14.1",
      "verseText": "And now there began to be a great curse upon all the land because of the iniquity of the people, in which, if a man should lay his tool or his sword upon his SHELF, or upon the place whither he would keep it, behold, upon the morrow, he could not find it, so great was the curse upon the land."
    },
    {
      "word": "moron",
      "hint": "Ether 14",
      "verse": "Ether 14:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/14",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/14.6",
      "verseText": "And he came forth to the land of MORON, and placed himself upon the throne of Coriantumr."
    },
    {
      "word": "saved",
      "hint": "Ether 15",
      "verse": "Ether 15:34",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/15",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/ether/15.34",
      "verseText": " Now the last words which are written by Ether are these: Whether the Lord will that I be translated, or that I suffer the will of the Lord in the flesh, it mattereth not, if it so be that I am SAVED in the kingdom of God. Amen."
    },
    {
      "word": "worth",
      "hint": "Moroni 1",
      "verse": "Moroni 1:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/1.4",
      "verseText": "Wherefore, I write a few more things, contrary to that which I had supposed; for I had supposed not to have written any more; but I write a few more things, that perhaps they may be of WORTH unto my brethren, the Lamanites, in some future day, according to the will of the Lord."
    },
    {
      "word": "ghost",
      "hint": "Moroni 2",
      "verse": "Moroni 2:2",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/2.2",
      "verseText": "And he called them by name, saying: Ye shall call on the Father in my name, in mighty prayer; and after ye have done this ye shall have power that to him upon whom ye shall lay your hands, ye shall give the Holy GHOST; and in my name shall ye give it, for thus do mine apostles. "
    },
    {
      "word": "gifts",
      "hint": "Moroni 3",
      "verse": "Moroni 3:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/3.4",
      "verseText": "And after this manner did they ordain priests and teachers, according to the GIFTS and callings of God unto men; and they ordained them by the power of the Holy Ghost, which was in them."
    },
    {
      "word": "kneel",
      "hint": "Moroni 4",
      "verse": "Moroni 4:2",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/4",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/4.2",
      "verseText": "And they did KNEEL down with the church, and pray to the Father in the name of Christ, saying:"
    },
    {
      "word": "drink",
      "hint": "Moroni 5",
      "verse": "Moroni 5:2",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/5.2",
      "verseText": "O God, the Eternal Father, we ask thee, in the name of thy Son, Jesus Christ, to bless and sanctify this wine to the souls of all those who DRINK of it, that they may do it in remembrance of the blood of thy Son, which was shed for them; that they may witness unto thee, O God, the Eternal Father, that they do always remember him, that they may have his Spirit to be with them. Amen."
    },
    {
      "word": "heart",
      "hint": "Moroni 6",
      "verse": "Moroni 6:2",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/6",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/6.2",
      "verseText": "Neither did they receive any unto baptism save they came forth with a broken HEART and a contrite spirit, and witnessed unto the church that they truly repented of all their sins."
    },
    {
      "word": "bread",
      "hint": "Moroni 6",
      "verse": "Moroni 6:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/6",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/6.6",
      "verseText": "And they did meet together oft to partake of BREAD and wine, in remembrance of the Lord Jesus."
    },
    {
      "word": "works",
      "hint": "Moroni 7",
      "verse": "Moroni 7:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/7.5",
      "verseText": "For I remember the word of God which saith by their WORKS ye shall know them; for if their WORKS be good, then they are good also."
    },
    {
      "word": "lowly",
      "hint": "Moroni 7",
      "verse": "Moroni 7:43",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/7.43",
      "verseText": "And again, behold I say unto you that he cannot have faith and hope, save he shall be meek, and LOWLY of heart."
    },
    {
      "word": "error",
      "hint": "Moroni 8",
      "verse": "Moroni 8:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/8.6",
      "verseText": "And now, my son, I desire that ye should labor diligently, that this gross ERROR should be removed from among you; for, for this intent I have written this epistle."
    },
    {
      "word": "alike",
      "hint": "Moroni 8",
      "verse": "Moroni 8:17",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/8.17",
      "verseText": "And I am filled with charity, which is everlasting love; wherefore, all children are alike unto me; wherefore, I love little children with a perfect love; and they are all ALIKE and partakers of salvation."
    },
    {
      "word": "enemy",
      "hint": "Moroni 9",
      "verse": "Moroni 9:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/9",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/9.6",
      "verseText": "And now, my beloved son, notwithstanding their hardness, let us labor diligently; for if we should cease to labor, we should be brought under condemnation; for we have a labor to perform whilst in this tabernacle of clay, that we may conquer the ENEMY of all righteousness, and rest our souls in the kingdom of God."
    },
    {
      "word": "weigh",
      "hint": "Moroni 9",
      "verse": "Moroni 9:25",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/9",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/9.25",
      "verseText": "My son, be faithful in Christ; and may not the things which I have written grieve thee, to WEIGH thee down unto death; but may Christ lift thee up, and may his sufferings and death, and the showing his body unto our fathers, and his mercy and long-suffering, and the hope of his glory and of eternal life, rest in your mind forever."
    },
    {
      "word": "every",
      "hint": "Moroni 7",
      "verse": "Moroni 7:13",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/7.13",
      "verseText": "But behold, that which is of God inviteth and enticeth to do good continually; wherefore, EVERY thing which inviteth and enticeth to do good, and to love God, and to serve him, is inspired of God."
    },
    {
      "word": "write",
      "hint": "Moroni 10",
      "verse": "Moroni 10:1",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/10.1",
      "verseText": "Now I, Moroni, WRITE somewhat as seemeth me good; and I WRITE unto my brethren, the Lamanites; and I would that they should know that more than four hundred and twenty years have passed away since the sign was given of the coming of Christ."
    },
    {
      "word": "teach",
      "hint": "Moroni 10",
      "verse": "Moroni 10:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/10.9",
      "verseText": "For behold, to one is given by the Spirit of God, that he may TEACH the word of wisdom;"
    },
    {
      "word": "kinds",
      "hint": "Moroni 10",
      "verse": "Moroni 10:15",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/10.15",
      "verseText": "And again, to another, all KINDS of tongues;"
    },
    {
      "word": "today",
      "hint": "Moroni 10",
      "verse": "Moroni 10:19",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/10.19",
      "verseText": "And I would exhort you, my beloved brethren, that ye remember that he is the same yesterday, TODAY, and forever, and that all these gifts of which I have spoken, which are spiritual, never will be done away, even as long as the world shall stand, only according to the unbelief of the children of men."
    },
    {
      "word": "needs",
      "hint": "Moroni 10",
      "verse": "Moroni 10:22",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/10.22",
      "verseText": "And if ye have no hope ye must NEEDS be in despair; and despair cometh because of iniquity."
    },
    {
      "word": "awake",
      "hint": "Moroni 10",
      "verse": "Moroni 10:31",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/10.31",
      "verseText": "And AWAKE, and arise from the dust, O Jerusalem; yea, and put on thy beautiful garments, O daughter of Zion; and strengthen thy stakes and enlarge thy borders forever, that thou mayest no more be confounded, that the covenants of the Eternal Father which he hath made unto thee, O house of Israel, may be fulfilled."
    },
    {
      "word": "quick",
      "hint": "Moroni 10",
      "verse": "Moroni 10:34",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/moro/10.34",
      "verseText": "And now I bid unto all, farewell. I soon go to rest in the paradise of God, until my spirit and body shall again reunite, and I am brought forth triumphant through the air, to meet you before the pleasing bar of the great Jehovah, the Eternal Judge of both QUICK and dead. Amen."
    },
    { 
      "word": "white",
      "hint": "1 Nephi 11",
      "verse": "1 Nephi 11:13",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/11",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/11.13",
      "verseText": "And it came to pass that I looked and beheld the great city of Jerusalem, and also other cities. And I beheld the city of Nazareth; and in the city of Nazareth I beheld a virgin, and she was exceedingly fair and WHITE.",
    },
    {
      "word": "arise",
      "hint": "Helaman 14",
      "verse": "Helaman 14:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/14",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/bofm/hel/14.5",
      "verseText": "And behold, there shall a new star ARISE, such an one as ye never have beheld; and this also shall be a sign unto you."
    },
    { 
      "word": "birth",
      "hint": "Matthew 1",
      "verse": "Matthew 1:18",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/1.18",
      "verseText": "Now the BIRTH of Jesus Christ was on this wise: When as his mother Mary was espoused to Joseph, before they came together, she was found with child of the Holy GHOST.",
      "submissionText": ""
    },
    {
      "word": "taxed",
      "hint": "Luke 2",
      "verse": "Luke 2:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/2.5",
      "verseText": "To be TAXED with Mary his aespoused wife, being great with child.",
      "submissionText": ""
    },
    {
      "word": "where",
      "hint": "Matthew 2",
      "verse": "Matthew 2:2",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/matt/2.2",
      "verseText": "Saying, WHERE is he that is born King of the Jews? for we have seen his star in the east, and are come to worship him.",
      "submissionText": ""
    },
    {
      "word": "lying",
      "hint": "Luke 2",
      "verse": "Luke 2:12",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/2.12",
      "verseText": "And this shall be a sign unto you; Ye shall find the babe wrapped in swaddling clothes, LYING in a manger.",
      "submissionText": ""
    },
    { 
      "word": "child",
      "hint": "Isaiah 9",
      "verse": "Isaiah 9:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/ot/isa/9",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/ot/isa/9.6",
      "verseText": "For unto us a CHILD is born, unto us a son is given: and the government shall be upon his shoulder: and his name shall be called Wonderful, Counsellor, The mighty God, The everlasting Father, The Prince of Peace.",
      "submissionText": ""
    },
    {
      "word": "woods",
      "hint": "The Restoration proclamation",
      "verse": "The Restoration proclamation",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/the-restoration-of-the-fulness-of-the-gospel-of-jesus-christ/a-bicentennial-proclamation-to-the-world",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/the-restoration-of-the-fulness-of-the-gospel-of-jesus-christ/a-bicentennial-proclamation-to-the-world",
      "verseText": "Two hundred years ago, on a beautiful spring morning in 1820, young Joseph Smith, seeking to know which church to join, went into the WOODS to pray near his home in upstate New York, USA. He had questions regarding the salvation of his soul and trusted that God would direct him."
    },
    {
      "word": "times",
      "hint": "Acts 3",
      "verse": "Acts 3:21",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/3.21",
      "verseText": "Whom the heaven must receive until the TIMES of restitution of all things, which God hath spoken by the mouth of all his holy prophets since the world began."
    },
    {
      "word": "might",
      "hint": "Ephisians 1",
      "verse": "Ephisians 1:10",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/eph/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/nt/eph/1.10",
      "verseText": "That in the dispensation of the fulness of times he MIGHT gather together in one all things in Christ, both which are in heaven, and which are on earth; even in him:"
    },
    {
      "word": "rises",
      "hint": "this hymn",
      "verse": "The Morning Breaks",
      "hintUrl": "https://www.churchofjesuschrist.org/study/manual/hymns/the-morning-breaks",
      "verseUrl": "https://www.churchofjesuschrist.org/study/manual/hymns/the-morning-breaks",
      "verseText": "The morning breaks, the shadows flee; Lo, Zion’s standard is unfurled! The dawning of a brighter day, The dawning of a brighter day. Majestic RISES on the world."
    },
    {
      "word": "guard",
      "hint": "Topics and Questions: Apostasy",
      "verse": "Topics and Questions: Apostasy",
      "hintUrl": "https://www.churchofjesuschrist.org/study/manual/gospel-topics/apostasy",
      "verseUrl": "https://www.churchofjesuschrist.org/study/manual/gospel-topics/apostasy",
      "verseText": "Although there will not be another general apostasy from the truth, we must each GUARD against personal apostasy by keeping covenants, obeying the commandments, following Church leaders, partaking of the sacrament, and constantly strengthening our testimonies through daily scripture study, prayer, and service."
    },
    {
      "word": "forth",
      "hint": "Articles of Faith",
      "verse": "Article of Faith 1:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/a-of-f/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/a-of-f/1.6",
      "verseText": "We believe in the same organization that existed in the Primitive Church, namely, apostles, prophets, pastors, teachers, evangelists, and so FORTH."
    },
    {
      "word": "chief",
      "hint": "The Restoration proclamation",
      "verse": "The Restoration proclamation",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/the-restoration-of-the-fulness-of-the-gospel-of-jesus-christ/a-bicentennial-proclamation-to-the-world",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/the-restoration-of-the-fulness-of-the-gospel-of-jesus-christ/a-bicentennial-proclamation-to-the-world",
      "verseText": "This Church is anchored in the perfect life of its CHIEF cornerstone, Jesus Christ, and in His infinite Atonement and literal Resurrection. Jesus Christ has once again called Apostles and has given them priesthood authority."
    },
    {
      "word": "wrath",
      "hint": "Doctrine and Covenants 1",
      "verse": "Doctrine and Covenants 1:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/1.9",
      "verseText": "Yea, verily, to seal them up unto the day when the WRATH of God shall be poured out upon the wicked without measure"
    },
    {
      "word": "trust",
      "hint": "Doctrine and Covenants 1",
      "verse": "Doctrine and Covenants 1:19",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/1.19",
      "verseText": "The weak things of the world shall come forth and break down the mighty and strong ones, that man should not counsel his fellow man, neither TRUST in the arm of flesh—"
    },
    {
      "word": "moved",
      "hint": "Introduction to the D&C",
      "verse": "Introduction to the D&C",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/introduction",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/introduction",
      "verseText": "Joseph Smith Jr. was born December 23, 1805, in Sharon, Windsor County, Vermont. During his early life, he MOVED with his family to present-day Manchester, in western New York."
    },    
    {
      "word": "kings",
      "hint": "Doctrine and Covenants 1",
      "verse": "Doctrine and Covenants 1:23",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/1.23",
      "verseText": "That the fulness of my gospel might be proclaimed by the weak and the simple unto the ends of the world, and before KINGS and rulers."
    },
    {
      "word": "later",
      "hint": "Introduction to the D&C",
      "verse": "Introduction to the D&C",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/introduction",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/introduction",
      "verseText": "Some LATER sections reflect the work of the Prophet Joseph Smith in making an inspired translation of the Bible, during which many of the great doctrinal sections were received..."
    },    
    {
      "word": "press",
      "hint": "Topics: Book of Commandments",
      "verse": "Topics: Book of Commandments",
      "hintUrl": "https://www.churchofjesuschrist.org/study/history/topics/book-of-commandments",
      "verseUrl": "https://www.churchofjesuschrist.org/study/history/topics/book-of-commandments",
      "verseText": "Printing began in 1832 at a print shop in Independence, Missouri, owned by William W. Phelps. The printing was nearly complete in July 1833 when a mob ransacked the PRESS shop."
    },
    {
      "word": "these",
      "hint": "Doctrine and Covenants 1",
      "verse": "Doctrine and Covenants 1:37",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/1.37",
      "verseText": "Search THESE commandments, for they are true and faithful, and the prophecies and promises which are in them shall all be fulfilled."
    },
    {
      "word": "facts",
      "hint": "Joseph Smith—History 1",
      "verse": "Joseph Smith—History 1:1",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1.1",
      "verseText": "...I have been induced to write this history, to disabuse the public mind, and put all inquirers after truth in possession of the FACTS, as they have transpired, in relation both to myself and the Church, so far as I have such FACTS in my possession."
    },
    {
      "word": "aloof",
      "hint": "Joseph Smith—History 1",
      "verse": "Joseph Smith—History 1:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1.8",
      "verseText": "During this time of great excitement my mind was called up to serious reflection and great uneasiness; but though my feelings were deep and often poignant, still I kept myself ALOOF from all these parties, though I attended their several meetings as often as occasion would permit."
    },
    {
      "word": "grove",
      "hint": "First Vision Accounts",
      "verse": "First Vision Accounts",
      "hintUrl": "https://www.churchofjesuschrist.org/study/history/topics/joseph-smiths-first-vision-accounts?lang=eng",
      "verseUrl": "https://www.churchofjesuschrist.org/study/history/topics/joseph-smiths-first-vision-accounts?lang=eng",
      "verseText": "Joseph Smith recorded that God the Father and Jesus Christ appeared to him in a GROVE of trees near his parents’ home in western New York State when he was about 14 years old."
    },
    {
      "word": "midst",
      "hint": "Joseph Smith—History 1",
      "verse": "Joseph Smith—History 1:10",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1.10",
      "verseText": "In the MIDST of this war of words and tumult of opinions, I often said to myself: What is to be done? Who of all these parties are right; or, are they all wrong together? If any one of them be right, which is it, and how shall I know it?"
    },
    {
      "word": "clear",
      "hint": "Joseph Smith—History 1",
      "verse": "Joseph Smith—History 1:14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1.14",
      "verseText": "It was on the morning of a beautiful, CLEAR day, early in the spring of eighteen hundred and twenty. It was the first time in my life that I had made such an attempt, for amidst all my anxieties I had never as yet made the attempt to pray vocally."
    },
    {
      "word": "pious",
      "hint": "Voices of the Restoration",
      "verse": "Lucy Mack Smith",
      "hintUrl": "https://www.churchofjesuschrist.org/study/manual/come-follow-me-for-home-and-church-doctrine-and-covenants-2025/03a-voices-of-the-restoration-joseph-smiths-family?lang=eng",
      "verseUrl": "https://www.churchofjesuschrist.org/study/manual/come-follow-me-for-home-and-church-doctrine-and-covenants-2025/03a-voices-of-the-restoration-joseph-smiths-family?lang=eng",
      "verseText": "From this time forward I gained strength continually. I said but little upon the subject of religion although it occupied my mind entirely, and I thought that I would make all diligence as soon as I was able to seek some pious person who knew the ways of God to instruct me in things of Heaven."
    },
    {
      "word": "alarm",
      "hint": "Joseph Smith—History 1",
      "verse": "Joseph Smith—History 1:16",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1.16",
      "verseText": "...just at this moment of great ALARM, I saw a pillar of light exactly over my head, above the brightness of the sun, which descended gradually until it fell upon me."
    },
    {
      "word": "would",
      "hint": "Doctrine and Covenants 2",
      "verse": "Doctrine and Covenants 2:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/2.3",
      "verseText": "If it were not so, the whole earth WOULD be utterly wasted at his coming."
    },
    {
      "word": "sects",
      "hint": "Joseph Smith—History 1",
      "verse": "Joseph Smith—History 1:28",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1.28",
      "verseText": "During the space of time which intervened between the time I had the vision and the year eighteen hundred and twenty-three—having been forbidden to join any of the religious SECTS of the day, and being of very tender years, and persecuted by those who ought to have been my friends and to have treated me kindly, and if they supposed me to be deluded to have endeavored in a proper and affectionate manner to have reclaimed me"
    },
    {
      "word": "floor",
      "hint": "Joseph Smith—History 1",
      "verse": "Joseph Smith—History 1:30",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1.30",
      "verseText": "While I was thus in the act of calling upon God, I discovered a light appearing in my room, which continued to increase until the room was lighter than at noonday, when immediately a personage appeared at my bedside, standing in the air, for his feet did not touch the FLOOR."
    },
    {
      "word": "bosom",
      "hint": "Joseph Smith—History 1",
      "verse": "Joseph Smith—History 1:31",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1.31",
      "verseText": "His hands were naked, and his arms also, a little above the wrist; so, also, were his feet naked, as were his legs, a little above the ankles. His head and neck were also bare. I could discover that he had no other clothing on but this robe, as it was open, so that I could see into his BOSOM."
    },
    {
      "word": "tempt",
      "hint": "Joseph Smith—History 1",
      "verse": "Joseph Smith—History 1:46",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1.46",
      "verseText": "But what was my surprise when again I beheld the same messenger at my bedside, and heard him rehearse or repeat over again to me the same things as before; and added a caution to me, telling me that Satan would try to TEMPT me (in consequence of the indigent circumstances of my father’s family), to get the plates for the purpose of getting rich."
    },
    {
      "word": "usual",
      "hint": "Joseph Smith—History 1",
      "verse": "Joseph Smith—History 1:48",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1.48",
      "verseText": "I shortly after arose from my bed, and, as USUAL, went to the necessary labors of the day; but, in attempting to work as at other times, I found my strength so exhausted as to render me entirely unable."
    },
    {
      "word": "lever",
      "hint": "Joseph Smith—History 1",
      "verse": "Joseph Smith—History 1:52",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1.52",
      "verseText": "Having removed the earth, I obtained a LEVER, which I got fixed under the edge of the stone, and with a little exertion raised it up. I looked in, and there indeed did I behold the plates, the Urim and Thummim, and the breastplate, as stated by the messenger. The box in which they lay was formed by laying stones together in some kind of cement. In the bottom of the box were laid two stones crossways of the box, and on these stones lay the plates and the other things with them."
    },
    {
      "word": "plant",
      "hint": "Doctrine and Covenants 2",
      "verse": "Doctrine and Covenants 2:2",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/2",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/2.2",
      "verseText": "And he shall PLANT in the hearts of the children the promises made to the fathers, and the hearts of the children shall turn to their fathers."
    },
    {
      "word": "incur",
      "hint": "Doctrine and Covenants 3",
      "verse": "Doctrine and Covenants 3:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/3.4",
      "verseText": "For although a man may have many revelations, and have power to do many mighty works, yet if he boasts in his own strength, and sets at naught the counsels of God, and follows after the dictates of his own will and carnal desires, he must fall and INCUR the vengeance of a just God upon him."
    },
    {
      "word": "aware",
      "hint": "Doctrine and Covenants 3",
      "verse": "Doctrine and Covenants 3:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/3",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/3.9",
      "verseText": "Behold, thou art Joseph, and thou wast chosen to do the work of the Lord, but because of transgression, if thou art not AWARE thou wilt fall."
    },
    {
      "word": "store",
      "hint": "Doctrine and Covenants 4",
      "verse": "Doctrine and Covenants 4:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/4",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/4.4",
      "verseText": "For behold the field is white already to harvest; and lo, he that thrusteth in his sickle with his might, the same layeth up in STORE that he perisheth not, but bringeth salvation to his soul;"
    },
    {
      "word": "glory",
      "hint": "Doctrine and Covenants 4",
      "verse": "Doctrine and Covenants 4:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/4",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/4.5",
      "verseText": "And faith, hope, charity and love, with an eye single to the GLORY of God, qualify him for the work."
    },
    {
      "word": "three",
      "hint": "Doctrine and Covenants 5",
      "verse": "Doctrine and Covenants 5:11",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/5.11",
      "verseText": "And in addition to your testimony, the testimony of THREE of my servants, whom I shall call and ordain, unto whom I will show these things, and they shall go forth with my words that are given through you."
    },
    {
      "word": "pages",
      "hint": "Doctrine and Covenants 5",
      "verse": "Doctrine and Covenants 5:30",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/5",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/5.30",
      "verseText": "And if this be the case, behold, I say unto thee Joseph, when thou hast translated a few more PAGES thou shalt stop for a season, even until I command thee again; then thou mayest translate again."
    },
    {
      "word": "speak",
      "hint": "Doctrine and Covenants 6",
      "verse": "Doctrine and Covenants 6:23",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/6",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/6.23",
      "verseText": "Did I not SPEAK peace to your mind concerning the matter? What greater witness can you have than from God?"
    },
    {
      "word": "thing",
      "hint": "Doctrine and Covenants 6",
      "verse": "Doctrine and Covenants 6:32",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/6",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/6.32",
      "verseText": "Verily, verily, I say unto you, as I said unto my disciples, where two or three are gathered together in my name, as touching one THING, behold, there will I be in the midst of them—even so am I in the midst of you."
    },
    {
      "word": "heirs",
      "hint": "Doctrine and Covenants 7",
      "verse": "Doctrine and Covenants 7:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/7",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/7.7",
      "verseText": "Yea, he has undertaken a greater work; therefore I will make him as flaming fire and a ministering angel; he shall minister for those who shall be HEIRS of salvation who dwell on the earth."
    },
    {
      "word": "parts",
      "hint": "Doctrine and Covenants 8",
      "verse": "Doctrine and Covenants 8:1",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/8.1",
      "verseText": "Oliver Cowdery, verily, verily, I say unto you, that assuredly as the Lord liveth, who is your God and your Redeemer, even so surely shall you receive a knowledge of whatsoever things you shall ask in faith, with an honest heart, believing that you shall receive a knowledge concerning the engravings of old records, which are ancient, which contain those PARTS of my scripture of which has been spoken by the manifestation of my Spirit."
    },
    {
      "word": "apply",
      "hint": "Doctrine and Covenants 8",
      "verse": "Doctrine and Covenants 8:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/8",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/8.4",
      "verseText": "Therefore this is thy gift; APPLY unto it, and blessed art thou, for it shall deliver you out of the hands of your enemies, when, if it were not so, they would slay you and bring your soul to destruction."
    },
    {
      "word": "dealt",
      "hint": "Doctrine and Covenants 9",
      "verse": "Doctrine and Covenants 9:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/9",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/9.6",
      "verseText": "Do not murmur, my son, for it is wisdom in me that I have DEALT with you after this manner."
    },
    {
      "word": "study",
      "hint": "Doctrine and Covenants 9",
      "verse": "Doctrine and Covenants 9:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/9",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/9.8",
      "verseText": "But, behold, I say unto you, that you must STUDY it out in your mind; then you must ask me if it be right, and if it is right I will cause that your bosom shall burn within you; therefore, you shall feel that it is right."
    },
    {
      "word": "linen",
      "hint": "Voices of the Restoration",
      "verse": "Emma Smith",
      "hintUrl": "https://www.churchofjesuschrist.org/study/manual/come-follow-me-for-home-and-church-doctrine-and-covenants-2025/06a-voices-of-the-restoration-translation?lang=eng",
      "verseUrl": "https://www.churchofjesuschrist.org/study/manual/come-follow-me-for-home-and-church-doctrine-and-covenants-2025/06a-voices-of-the-restoration-translation?lang=eng",
      "verseText": "The plates often lay on the table without any attempt at concealment, wrapped in a small LINEN tablecloth, which I had given him to fold them in. I once felt of the plates, as they thus lay on the table, tracing their outline and shape. They seemed to be pliable like thick paper, and would rustle with a metallic sound when the edges were moved by the thumb, as one does sometimes thumb the edges of a book."
    },
    {
      "word": "labor",
      "hint": "Doctrine and Covenants 10",
      "verse": "Doctrine and Covenants 10:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/10.4",
      "verseText": "Do not run faster or LABOR more than you have strength and means provided to enable you to translate; but be diligent unto the end."
    },
    {
      "word": "catch",
      "hint": "Doctrine and Covenants 10",
      "verse": "Doctrine and Covenants 10:26",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/10.26",
      "verseText": "And thus he flattereth them, and leadeth them along until he draggeth their souls down to hell; and thus he causeth them to CATCH themselves in their own snare."
    },
    {
      "word": "sheep",
      "hint": "Doctrine and Covenants 10",
      "verse": "Doctrine and Covenants 10:59",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/10.59",
      "verseText": "I am he who said — Other SHEEP have I which are not of this fold — unto my disciples, and many there were that understood me not."
    },
    {
      "word": "judge",
      "hint": "Doctrine and Covenants 11",
      "verse": "Doctrine and Covenants 11:12",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/11",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/11.12",
      "verseText": "And now, verily, verily, I say unto thee, put your trust in that Spirit which leadeth to do good—yea, to do justly, to walk humbly, to JUDGE righteously; and this is my Spirit."
    },
    {
      "word": "added",
      "hint": "Doctrine and Covenants 11",
      "verse": "Doctrine and Covenants 11:22",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/11",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/11.22",
      "verseText": "But now hold your peace; study my word which hath gone forth among the children of men, and also study my word which shall come forth among the children of men, or that which is now translating, yea, until you have obtained all which I shall grant unto the children of men in this generation, and then shall all things be ADDED thereto."
    },
    {
      "word": "hands",
      "hint": "Doctrine and Covenants 10",
      "verse": "Doctrine and Covenants 10:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/10",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/10:5",
      "verseText": "Pray always, that you may come off conqueror; yea, that you may conquer Satan, and that you may escape the HANDS of the servants of Satan that do uphold his work."
    },
    {
      "word": "cause",
      "hint": "Doctrine and Covenants 12",
      "verse": "Doctrine and Covenants 12:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/12",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/12.6",
      "verseText": "Now, as you have asked, behold, I say unto you, keep my commandments, and seek to bring forth and establish the CAUSE of Zion."
    },
    {
      "word": "offer",
      "hint": "Doctrine and Covenants 13",
      "verse": "Doctrine and Covenants 13:1",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/13",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/13.1",
      "verseText": "Upon you my fellow servants, in the name of Messiah I confer the Priesthood of Aaron, which holds the keys of the ministering of angels, and of the gospel of repentance, and of baptism by immersion for the remission of sins; and this shall never be taken again from the earth, until the sons of Levi do OFFER again an offering unto the Lord in righteousness."
    },
    {
      "word": "alone",
      "hint": "Doctrine and Covenants 15",
      "verse": "Doctrine and Covenants 15:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/15",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/15.3",
      "verseText": "And I will tell you that which no man knoweth save me and thee ALONE"
    },
    {
      "word": "times",
      "hint": "Doctrine and Covenants 16",
      "verse": "Doctrine and Covenants 16:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/16",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/16.4",
      "verseText": "For many TIMES you have desired of me to know that which would be of the most worth unto you."
    },
    {
      "word": "gates",
      "hint": "Doctrine and Covenants 17",
      "verse": "Doctrine and Covenants 17:8",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/17",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/17.8",
      "verseText": "And if you do these last commandments of mine, which I have given you, the GATES of hell shall not prevail against you; for my grace is sufficient for you, and you shall be lifted up at the last day."
    },
    {
      "word": "mount",
      "hint": "Doctrine and Covenants 17",
      "verse": "Doctrine and Covenants 17:1",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/17",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/17.1",
      "verseText": "Behold, I say unto you, that you must rely upon my word, which if you do with full purpose of heart, you shall have a view of the plates, and also of the breastplate, the sword of Laban, the Urim and Thummim, which were given to the brother of Jared upon the MOUNT, when he talked with the Lord face to face, and the miraculous directors which were given to Lehi while in the wilderness, on the borders of the Red Sea."
    },
    {
      "word": "board",
      "hint": "Joseph Smith History 1:66-75",
      "verse": "Joseph Smith History 1:66",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1?lang=eng&id=p66-p75#p66",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/pgp/js-h/1.66",
      "verseText": "On the 5th day of April, 1829, Oliver Cowdery came to my house, until which time I had never seen him. He stated to me that having been teaching school in the neighborhood where my father resided, and my father being one of those who sent to the school, he went to BOARD for a season at his house, and while there the family related to him the circumstances of my having received the plates, and accordingly he had come to make inquiries of me."
    },
    {
      "word": "souls",
      "hint": "Doctrine and Covenants 18",
      "verse": "Doctrine and Covenants 18:10",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/18",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/18.10",
      "verseText": "Remember the worth of SOULS is great in the sight of God;"
    },
    {
      "word": "value",
      "hint": "this conference talk",
      "verse": "Value Beyond Measure",
      "hintUrl": "https://www.churchofjesuschrist.org/study/general-conference/2017/10/value-beyond-measure?lang=eng",
      "verseUrl": "https://www.churchofjesuschrist.org/study/general-conference/2017/10/value-beyond-measure?lang=eng#p10",
      "verseText": "God is your Father. He loves you. He and your Mother in heaven VALUE you beyond any measure. … You are unique. One of a kind, made of the eternal intelligence which gives you claim upon eternal life."
    },
    {
      "word": "place",
      "hint": "Doctrine and Covenants 18",
      "verse": "Doctrine and Covenants 18:25",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/18",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/18.25",
      "verseText": "Wherefore, if they know not the name by which they are called, they cannot have PLACE in the kingdom of my Father."
    },
    {
      "word": "power",
      "hint": "Doctrine and Covenants 18",
      "verse": "Doctrine and Covenants 18:35",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/18",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/18.35",
      "verseText": "For it is my voice which speaketh them unto you; for they are given by my Spirit unto you, and by my POWER you can read them one to another; and save it were by my POWER you could not have them;"
    },
    {
      "word": "women",
      "hint": "Doctrine and Covenants 18",
      "verse": "Doctrine and Covenants 18:42",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/18",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/18.42",
      "verseText": "For all men must repent and be baptized, and not only men, but WOMEN, and children who have arrived at the years of accountability."
    },
    {
      "word": "sweet",
      "hint": "Alma 36",
      "verse": "Alma 36:21",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/18",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/18.21",
      "verseText": "Yea, I say unto you, my son, that there could be nothing so exquisite and so bitter as were my pains. Yea, and again I say unto you, my son, that on the other hand, there can be nothing so exquisite and SWEET as was my joy."
    },
    {
      "word": "above",
      "hint": "Doctrine and Covenants 18",
      "verse": "Doctrine and Covenants 18:45",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/18",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/18.45",
      "verseText": "Wherefore, the blessings which I give unto you are ABOVE all things."
    },
    {
      "word": "alpha",
      "hint": "Doctrine and Covenants 19",
      "verse": "Doctrine and Covenants 19:1",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/19",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/19.1",
      "verseText": "I am ALPHA and Omega, Christ the Lord; yea, even I am he, the beginning and the end, the Redeemer of the world."
    },
    {
      "word": "teeth",
      "hint": "Doctrine and Covenants 19",
      "verse": "Doctrine and Covenants 19:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/19",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/19.5",
      "verseText": "Wherefore, I revoke not the judgments which I shall pass, but woes shall go forth, weeping, wailing and gnashing of TEETH, yea, to those who are found on my left hand."
    },
    {
      "word": "bleed",
      "hint": "Doctrine and Covenants 19",
      "verse": "Doctrine and Covenants 19:18",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/19",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/19.18",
      "verseText": "Which suffering caused myself, even God, the greatest of all, to tremble because of pain, and to BLEED at every pore, and to suffer both body and spirit—and would that I might not drink the bitter cup, and shrink—"
    },
    {
      "word": "covet",
      "hint": "Doctrine and Covenants 19",
      "verse": "Doctrine and Covenants 19:26",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/19",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/19.26",
      "verseText": "And again, I command thee that thou shalt not COVET thine own property, but impart it freely to the printing of the Book of Mormon, which contains the truth and the word of God"
    },
    {
      "word": "leave",
      "hint": "Doctrine and Covenants 19",
      "verse": "Doctrine and Covenants 19:36",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/19",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/19.36",
      "verseText": "LEAVE thy house and home, except when thou shalt desire to see thy family;"
    },    
    {
      "word": "fully",
      "hint": "this hymn",
      "verse": "I Stand All Amazed",
      "hintUrl": "https://www.churchofjesuschrist.org/study/manual/hymns/i-stand-all-amazed",
      "verseUrl": "https://www.churchofjesuschrist.org/study/manual/hymns/i-stand-all-amazed",
      "verseText": "I stand all amazed at the love Jesus offers me, Confused at the grace that so FULLY he proffers me. I tremble to know that for me he was crucified, That for me, a sinner, he suffered, he bled and died."
    },
    {
      "word": "canst",
      "hint": "Doctrine and Covenants 19",
      "verse": "Doctrine and Covenants 19:41",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/19",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/19.41",
      "verseText": "Or CANST thou be humble and meek, and conduct thyself wisely before me? Yea, come unto me thy Savior. Amen."
    },
    {
      "word": "elder",
      "hint": "Doctrine and Covenants 20",
      "verse": "Doctrine and Covenants 20:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/20",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/20.3",
      "verseText": "Which commandments were given to Joseph Smith, Jun., who was called of God, and ordained an apostle of Jesus Christ, to be the first ELDER of this church;"
    },
    {
      "word": "crown",
      "hint": "Doctrine and Covenants 20",
      "verse": "Doctrine and Covenants 20:14",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/20",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/20.14",
      "verseText": "And those who receive it in faith, and work righteousness, shall receive a CROWN of eternal life;"
    },
    {
      "word": "reign",
      "hint": "Doctrine and Covenants 20",
      "verse": "Doctrine and Covenants 20:24",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/20",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/20.24",
      "verseText": "And ascended into heaven, to sit down on the right hand of the Father, to REIGN with almighty power according to the will of the Father;"
    },
    {
      "word": "honor",
      "hint": "Doctrine and Covenants 20",
      "verse": "Doctrine and Covenants 20:36",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/20",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/20.36",
      "verseText": "And the Lord God has spoken it; and HONOR, power and glory be rendered to his holy name, both now and ever. Amen."
    },
    {
      "word": "visit",
      "hint": "Doctrine and Covenants 20",
      "verse": "Doctrine and Covenants 20:51",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/20",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/20.51",
      "verseText": "And VISIT the house of each member, exhorting them to pray vocally and in secret and attend to all family duties."
    },
    {
      "word": "shake",
      "hint": "Doctrine and Covenants 21",
      "verse": "Doctrine and Covenants 21:6",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/21",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/21.6",
      "verseText": "For by doing these things the gates of hell shall not prevail against you; yea, and the Lord God will disperse the powers of darkness from before you, and cause the heavens to SHAKE for your good, and his name’s glory."
    },
    {
      "word": "enter",
      "hint": "Doctrine and Covenants 22",
      "verse": "Doctrine and Covenants 22:4",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/22",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/22.4",
      "verseText": "Wherefore, ENTER ye in at the gate, as I have commanded, and seek not to counsel your God. Amen."
    },
    {
      "word": "pride",
      "hint": "Doctrine and Covenants 23",
      "verse": "Doctrine and Covenants 23:1",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/23",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/23.1",
      "verseText": "Behold, I speak unto you, Oliver, a few words. Behold, thou art blessed, and art under no condemnation. But beware of PRIDE, lest thou shouldst enter into temptation."
    },
    {
      "word": "unite",
      "hint": "Doctrine and Covenants 23",
      "verse": "Doctrine and Covenants 23:7",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/23",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/23.7",
      "verseText": "And, behold, it is your duty to UNITE with the true church, and give your language to exhortation continually, that you may receive the reward of the laborer. Amen."
    },
    {
      "word": "sowed",
      "hint": "Doctrine and Covenants 24",
      "verse": "Doctrine and Covenants 24:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/24",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/24.3",
      "verseText": "Magnify thine office; and after thou hast SOWED thy fields and secured them, go speedily unto the church which is in Colesville, Fayette, and Manchester, and they shall support thee; and I will bless them both spiritually and temporally;"
    },
    {
      "word": "trump",
      "hint": "Doctrine and Covenants 24",
      "verse": "Doctrine and Covenants 24:12",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/24",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/24.12",
      "verseText": "And at all times, and in all places, he shall open his mouth and declare my gospel as with the voice of a TRUMP, both day and night. And I will give unto him strength such as is not known among men."
    },
    {
      "word": "elect",
      "hint": "Doctrine and Covenants 25",
      "verse": "Doctrine and Covenants 25:3",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/25",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/25.3",
      "verseText": "Behold, thy sins are forgiven thee, and thou art an ELECT lady, whom I have called."
    },
    {
      "word": "heads",
      "hint": "Doctrine and Covenants 25",
      "verse": "Doctrine and Covenants 25:12",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/25",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/25.12",
      "verseText": "For my soul delighteth in the song of the heart; yea, the song of the righteous is a prayer unto me, and it shall be answered with a blessing upon their HEADS."
    },
    {
      "word": "purse",
      "hint": "Doctrine and Covenants 24",
      "verse": "Doctrine and Covenants 24:18",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/24",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/24.18",
      "verseText": "And thou shalt take no PURSE nor scrip, neither staves, neither two coats, for the church shall give unto thee in the very hour what thou needest for food and for raiment, and for shoes and for money, and for scrip."
    },
    {
      "word": "youth",
      "hint": "Voices of the Restoration - Emma",
      "verse": "Joseph Smith Jr.",
      "hintUrl": "https://www.churchofjesuschrist.org/study/manual/come-follow-me-for-home-and-church-doctrine-and-covenants-2025/12a-voices-of-the-restoration-emma-hale-smith?lang=eng",
      "verseUrl": "https://www.churchofjesuschrist.org/study/manual/come-follow-me-for-home-and-church-doctrine-and-covenants-2025/12a-voices-of-the-restoration-emma-hale-smith?lang=eng",
      "verseText": "With what unspeakable delight, and what transports of joy swelled my bosom, when I took by the hand, on that night, my beloved Emma—she that was my wife, even the wife of my YOUTH; and the choice of my heart."
    },
    {
      "word": "blood",
      "hint": "Doctrine and Covenants 27",
      "verse": "Doctrine and Covenants 27:2",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/27",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/27.2",
      "verseText": "For, behold, I say unto you, that it mattereth not what ye shall eat or what ye shall drink when ye partake of the sacrament, if it so be that ye do it with an eye single to my glory—remembering unto the Father my body which was laid down for you, and my BLOOD which was shed for the remission of your sins."
    },
    {
      "word": "fruit",
      "hint": "Doctrine and Covenants 27",
      "verse": "Doctrine and Covenants 27:5",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/27",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/27.5",
      "verseText": "Behold, this is wisdom in me; wherefore, marvel not, for the hour cometh that I will drink of the FRUIT of the vine with you on the earth, and with Moroni, whom I have sent unto you to reveal the Book of Mormon, containing the fulness of my everlasting gospel, to whom I have committed the keys of the record of the stick of Ephraim;"
    },
    {
      "word": "curse",
      "hint": "Doctrine and Covenants 27",
      "verse": "Doctrine and Covenants 27:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/27",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/27.9",
      "verseText": "And also Elijah, unto whom I have committed the keys of the power of turning the hearts of the fathers to the children, and the hearts of the children to the fathers, that the whole earth may not be smitten with a CURSE;"
    },
    {
      "word": "stone",
      "hint": "Doctrine and Covenants 28",
      "verse": "Doctrine and Covenants 28:11",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/28",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/28.11",
      "verseText": "And again, thou shalt take thy brother, Hiram Page, between him and thee alone, and tell him that those things which he hath written from that STONE are not of me and that Satan deceiveth him;"
    },
    {
      "word": "found",
      "hint": "Voices of the Restoration - Emma",
      "verse": "Lucy Mack Smith",
      "hintUrl": "https://www.churchofjesuschrist.org/study/manual/come-follow-me-for-home-and-church-doctrine-and-covenants-2025/12a-voices-of-the-restoration-emma-hale-smith?lang=eng",
      "verseUrl": "https://www.churchofjesuschrist.org/study/manual/come-follow-me-for-home-and-church-doctrine-and-covenants-2025/12a-voices-of-the-restoration-emma-hale-smith?lang=eng",
      "verseText": "Whatever her hands FOUND to do, she did with her might and did not ask the selfish question, Shall I be benefited any more than anyone else?"
    },
    {
      "word": "order",
      "hint": "Doctrine and Covenants 28",
      "verse": "Doctrine and Covenants 28:13",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/28",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/28.13",
      "verseText": "For all things must be done in ORDER, and by common consent in the church, by the prayer of faith."
    },
    {
      "word": "wings",
      "hint": "Doctrine and Covenants 29",
      "verse": "Doctrine and Covenants 29:2",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/29",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/29.2",
      "verseText": "Who will gather his people even as a hen gathereth her chickens under her WINGS, even as many as will hearken to my voice and humble themselves before me, and call upon me in mighty prayer."
    },
    {
      "word": "proud",
      "hint": "Doctrine and Covenants 29",
      "verse": "Doctrine and Covenants 29:9",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/29",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/29.9",
      "verseText": "For the hour is nigh and the day soon at hand when the earth is ripe; and all the PROUD and they that do wickedly shall be as stubble; and I will burn them up, saith the Lord of Hosts, that wickedness shall not be upon the earth;"
    },
    {
      "word": "quake",
      "hint": "Doctrine and Covenants 29",
      "verse": "Doctrine and Covenants 29:13",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/29",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/29.13",
      "verseText": "For a trump shall sound both long and loud, even as upon Mount Sinai, and all the earth shall QUAKE, and they shall come forth—yea, even the dead which died in me, to receive a crown of righteousness, and to be clothed upon, even as I am, to be with me, that we may be one."
    },
    {
      "word": "crops",
      "hint": "Doctrine and Covenants 29",
      "verse": "Doctrine and Covenants 29:16",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/29",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/29.16",
      "verseText": "And there shall be a great hailstorm sent forth to destroy the CROPS of the earth."
    },
    {
      "word": "flies",
      "hint": "Doctrine and Covenants 29",
      "verse": "Doctrine and Covenants 29:18",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/29",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/29.18",
      "verseText": "Wherefore, I the Lord God will send forth FLIES upon the face of the earth, which shall take hold of the inhabitants thereof, and shall eat their flesh, and shall cause maggots to come in upon them;"
    },
    {
      "word": "third",
      "hint": "Doctrine and Covenants 29",
      "verse": "Doctrine and Covenants 29:36",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/29",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/29.36",
      "verseText": "And it came to pass that Adam, being tempted of the devil—for, behold, the devil was before Adam, for he rebelled against me, saying, Give me thine honor, which is my power; and also a THIRD part of the hosts of heaven turned he away from me because of their agency;"
    },
    {
      "word": "begin",
      "hint": "Doctrine and Covenants 29",
      "verse": "Doctrine and Covenants 29:47",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/29",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/29.47",
      "verseText": "Wherefore, they cannot sin, for power is not given unto Satan to tempt little children, until they BEGIN to become accountable before me;"
    },
    {
      "word": "",
      "hint": "Doctrine and Covenants",
      "verse": "Doctrine and Covenants",
      "hintUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/",
      "verseUrl": "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/",
      "verseText": ""
    },
];